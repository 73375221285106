import React, { Component } from 'react';
import PermissionHelper from "../../../helpers/PermissionHelper";

import ReactToPrint from 'react-to-print';

import {CreditMemoReceiptPrint} from "./CreditMemoReceiptPrint";


import {
  MDBSpinner,
  MDBTable,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBLink,
  MDBBtn,
  MDBIcon,
  MDBCardImage,
  MDBInput,
  MDBTooltip,
  MDBTableHead,
  MDBTableBody
} from 'mdbreact';

import config from '../../../config';

import QRCode from "react-qr-code";


import moment from 'moment';
import 'moment/locale/de';

class Receipt extends Component {

  constructor(props) {
    super(props);
    this.state = {
    }


    this._onStartPrint = this._onStartPrint.bind(this);
  }


  componentDidMount(){
  }


  _onStartPrint() {
  }


  render() {
    return (
      <>
      <ReactToPrint
        ref={ref => this.ref = ref}
            trigger={() => {
              // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
              // to the root node of the returned component as it will be overwritten.
              return <a href="#" className="btn btn-primary"><MDBIcon icon="receipt" /></a>;
            }}
            content={() => this.componentRef}
          />
        <div className="hide"><CreditMemoReceiptPrint creditMemo={this.props.creditMemo} openCashdrawer={false} order={this.props.order} ref={el => (this.componentRef = el)} /></div>
      </>
    );
  }
}

export default Receipt;
