import React, { Component } from 'react';

import {
  MDBCard,
  MDBIcon,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
  MDBLink,
} from 'mdbreact';

import OrderSearch from "./OrderSearch";

/** import config **/
import config from '../../../config';

/** import styles **/
import '../../../assets/css/sections/order.css';


class OrderOverview extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modal: false
    }

    this.sectionRef = React.createRef();
  }

  render() {
    return (
      <section id="order-overview" ref={this.sectionRef}>
        <MDBCard className="card-order">
          <MDBCardBody>
            <MDBCardTitle><MDBIcon icon="orderpaper mr-2" /> Bestellübersicht</MDBCardTitle>
            <MDBCardText tag="div">
              {localStorage.getItem('selectedCashRegisterSession') &&
                <OrderSearch overviewComponent={this} />
              }

              {!localStorage.getItem('selectedCashRegisterSession') &&
                <>Bitte wähle zuerst eine Kassensitzung aus</>
              }

            </MDBCardText>
          </MDBCardBody>
        </MDBCard>
      </section>
    );
  }
}

export default OrderOverview;
