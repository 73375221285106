import React, {useState,useEffect} from 'react';
import {
  MDBCol,
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
  MDBLink,
  MDBView,
  MDBMask,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBRow,
  MDBIcon,
  MDBInput,
  MDBCollapse
} from 'mdbreact';

import Currency from 'react-currency-formatter';
import PermissionHelper from "../../../helpers/PermissionHelper";


import moment from 'moment';
import 'moment/locale/de';



class OrderItem extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      total: this.props.result.node.field_qty[0].value * this.props.result.node.field_price[0].value,
      field_price:  this.props.result.node.field_price[0].value,
      field_qty: this.props.result.node.field_qty[0].value,
      collapseID: ""
    }
  }

  componentDidMount() {
    this._onApplyDiscount();
  }

  _onApplyDiscount() {

    let component = this;

    let total = component.state.total;

    Object.entries(component.props.result.discountItems).map(function(row, j){

      var discountValue = row[1].field_discount_value[0].value;
      var discountSubstract = 0;

      if(row[1].field_discount_percent[0].value) {
        discountValue = (component.state.total / 100) * discountValue;
      }

      total = total-discountValue;

    })

    this.setState({
      total: total
    });
  }




  render() {
    var component = this;
    console.log(this.props.result);

    return (

      <tr className={`${this.state.collapseID ? "active" : ""}`} key={'orderitem-'+this.props.result.node.nid[0].value+this.props.result.node.changed[0].value}>
          <td className="w-25">
            <span class="product-qty">{this.props.result.node.field_qty[0].value}</span>
          </td>

          <td className="w-50">
            <span class="product-name">{this.props.result.node.title[0].value}</span>

              {component.props.result.discountItems.length > 0 &&
                <strong>
                  <br />
                  Rabatt:
                  {Object.entries(component.props.result.discountItems).map(function(row, j){

                    var discountValue = row[1].field_discount_value[0].value;
                    let discountSuffix = 'CHF';

                    if(row[1].field_discount_percent[0].value) {
                      //discountValue = (component.state.price / 100) * discountValue;
                      discountSuffix = '%';
                    }

                    return ' ' + row[1].body[0].value + ' (' + discountValue + ' ' + discountSuffix + ')';

                  })}
                </strong>
              }

          </td>

          <td className="w-25">
            {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'CHF' }).format(this.props.result.node.field_price[0].value)}
          </td>

          <td className="w-25">
            {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'CHF' }).format(this.state.total)}
          </td>
      </tr>
    );
  }
}

export default OrderItem;
