import React, {
  Component
} from 'react';
import axios from "axios";
import config from '../../../../../config';



class TerminalActions extends Component {

  constructor(props) {
    super(props);
    /** states **/
    this.state = {}

    /** binding methods **/
    this._get = this._get.bind(this);
    this._post = this._post.bind(this);
    this._patch = this._patch.bind(this);
    this._put = this._put.bind(this);
    this._delete = this._delete.bind(this);
  }

  componentDidMount() {}


  static _get(id, filter = null, page = null) {
    var filterQuery = '';
    if(filter) {
      filterQuery = '&';
      const filterKeys = Object.keys(filter);
      const filterValues = Object.values(filter);
      const filterEntries = Object.entries(filter);
      for (const [field, value] of filterEntries) {
        filterQuery += field + "=" + value + "&";
      }
    }




    if(page) {
      filterQuery += '&page=' + page;
    }

    return axios.get(`${config.backend_base}rest/terminal_api/${id}?_format=json` + filterQuery, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        return response;
      })
  }

  static _post(values) {
    return axios.post(`${config.backend_base}rest/terminal_api/create?_format=json`, values.data, {
        headers: {
          'Content-Type': 'application/json',
        }
      }).then(response => {
        console.log(response);
        return response;
      }).catch(error => {
        console.log(error);
        throw error;
        //return error;
      })
  }


  static _put(values) {
    return axios.put(`${config.backend_base}rest/terminal_api/${values.nid}?_format=json`, values.data, {
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(response => {
        return response;
      })
  }

  static _patch(values) {
    //console.log(values);
    let nid = values.nid;
    values = JSON.stringify(values.data);
    return axios.patch(`${config.backend_base}rest/terminal_api/${nid}?_format=json`, values, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        return response;
      })
  }

  static _delete(nid) {
    return axios.delete(`${config.backend_base}rest/terminal_api/${nid}?_format=json`, {
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(response => {
        return response;
      })
  }

  static _uploadFile(values, extension) {

    var filename = 'file.' + extension;

    return axios.post(`${config.backend_base}file/upload/node/terminal/field_image`,values,{
        headers: {
          'Content-Type': 'application/octet-stream',
          'Content-Disposition': 'file; filename="'+filename+'"'
        }
      }
    ).then(response => {
      console.log(response);
      return response;
    })
  }
}

export default TerminalActions;
