import React, { Component } from 'react';
import {
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBInput,
  MDBModalFooter,
  MDBRow,
  MDBCol,
  MDBDatePicker,
  MDBFileInput,
  MDBSelect,
  MDBIcon,
  MDBSpinner,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBAnimation,
  MDBNavLink,
  MDBCardText,
  MDBLink,
  MDBCollapseHeader,
  MDBCollapse
} from 'mdbreact';
import CustomerActions from './actions/CustomerActions';
import { history } from '../../../../helpers/history';

import Select from 'react-select';

import { Modal } from 'antd';

import MDBFileupload from 'mdb-react-fileupload';


import PermissionHelper from "../../../../helpers/PermissionHelper";

import moment from 'moment';
import 'moment/locale/de';

import Image from "../../image/Image";

class CustomerForm extends Component {


  constructor(props) {
    super(props);
    this.state = {
      hasSubmitted: false,
      modal: false,
      nid: false,
      entity: this.props.entity,
      locationsFilter: this.props.locationsFilter,
      genderFilters: this.props.genderFilters,
      channelOptions: this.props.channelOptions,
      loaded: true,
      field_firstname: '',
      field_lastname: '',
      field_birthday: '',
      field_street: '',
      field_zip: '',
      field_city: '',
      field_phone: '',
      field_mobile: '',
      field_image: '',
      field_job: '',
      field_location: [JSON.parse(localStorage.getItem('selectedLocation')).nid[0].value],
      field_gender: [],
      field_channel: [],
      field_channel_other: null,
      field_selected_recommendation_customer: null,
      parent: (this.props.searchComponent) ? this.props.searchComponent : this.props.customerDetail
    }

    this.genderFilterRef = React.createRef();
    this.channelFilterRef = React.createRef();
    this.fileInputRef = React.createRef();

    this._onToggleModal = this._onToggleModal.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
    this._onInputChange = this._onInputChange.bind(this);
    this._onChangeHandleFile = this._onChangeHandleFile.bind(this);
    this._onFormReset = this._onFormReset.bind(this);
    this._onGetPickerValue = this._onGetPickerValue.bind(this);
    this._onPreventToggle = this._onPreventToggle.bind(this);
    this._onChannelChange = this._onChannelChange.bind(this);
  }

  componentDidMount() {

    var component = this;

    if(this.props.entity) {

      //console.log(this.props.locations);
      var selectedLocations = new Array();
      Object.entries(this.props.locations).forEach(function(item, key){
        selectedLocations.push(item[1].nid[0].value.toString());
      })
      var locations = this.props.locationsFilter;
      //console.log(locations);
      var locationsFilter = new Array();
      if(locations) {
        Object.entries(locations).forEach(function(item, key){
          var hasLocation = selectedLocations.includes(item[0].toString());
          var optionItem = {
            checked: (hasLocation) ? true : false,
            label: item[1].title[0].value,
            value: item[0]
          }
          locationsFilter.push(optionItem);
          //console.log(item);
        });
      }










      //console.log(this.props.locations);
      var selectedChannels = new Array();
      if(this.props.entity.field_channel[0]) {
        //console.log(this.props.entity.field_channel);
        selectedChannels.push(this.props.entity.field_channel[0].target_id.toString());
      }
      var channels = this.props.channelOptions;
      //console.log(locations);
      var channelOptions = new Array();
      if(channels) {
        Object.entries(channels).forEach(function(item, key){

          if(item[1].value == "29") {
            return;
          }
          var hasChannel = selectedChannels.includes(item[1].value.toString());
          var optionItem = {
            label: item[1].text,
            value: item[1].value
          }
          channelOptions.push(optionItem);
          //console.log(item);
        });
      }





      this.setState(prevState => ({
        loaded: true,
        nid: this.props.entity.nid[0].value,
        field_firstname: this.props.entity.field_firstname[0] ? this.props.entity.field_firstname[0].value : '',
        field_lastname: this.props.entity.field_lastname[0] ? this.props.entity.field_lastname[0].value : '',
        field_birthday: this.props.entity.field_birthday[0] ? this.props.entity.field_birthday[0].value : null,
        field_street: this.props.entity.field_street[0] ? this.props.entity.field_street[0].value : '',
        field_zip: this.props.entity.field_zip[0] ? this.props.entity.field_zip[0].value : '',
        field_city: this.props.entity.field_city[0] ? this.props.entity.field_city[0].value : '',
        field_phone: this.props.entity.field_phone[0] ? this.props.entity.field_phone[0].value : '',
        field_mobile: this.props.entity.field_mobile[0] ? this.props.entity.field_mobile[0].value : '',
        field_email: this.props.entity.field_email[0] ? this.props.entity.field_email[0].value : '',
        field_location: selectedLocations,
        field_gender: this.props.entity.field_gender[0] ? [this.props.entity.field_gender[0].target_id] : '',
        field_job: this.props.entity.field_job[0] ? this.props.entity.field_job[0].value : '',
        field_channel: this.props.entity.field_channel[0] ? [this.props.entity.field_channel[0].target_id] : '',
        field_channel_other: this.props.entity.field_channel_other[0] ? this.props.entity.field_channel_other[0].value : '',
        locationsFilter: locationsFilter,
        channelOptions: channelOptions,
      }))
    } else {

      if(PermissionHelper._hasPermission('ignore locations') === false) {
        var selectedLocation = JSON.parse(localStorage.getItem('selectedLocation'));
        this.setState({
          field_location: [selectedLocation]
        });
      }

      component.setState({
        channelOptions: this.props.searchComponent.state.channelOptions
      });

    }



  }

  _onToggleModal = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  _onSelectChange(e) {
    var name = e.target.name;
    var value;
    if(e.target.value) {
      value = e.target.value;
    } else {
      value = null;
    }
    this.setState({
      [e.target.name]: [e.target.value],
    });
  }

  _onSubmit(e) {
    e.preventDefault();
    var component = this;

    if(component.state.hasSubmitted) {
      return;
    }

    component.setState(prevState => ({
      loaded: false,
      hasSubmitted: true,
    }))

    let values = {
      nid: this.state.nid,
      field_firstname: this.state.field_firstname,
      field_lastname: this.state.field_lastname,
      field_birthday: this.state.field_birthday,
      field_street: this.state.field_street,
      field_zip: this.state.field_zip,
      field_city: this.state.field_city,
      field_phone: this.state.field_phone,
      field_mobile: this.state.field_mobile,
      field_email: this.state.field_email,
      field_image: this.state.field_image,
      field_job: this.state.field_job,
      field_location: this.state.field_location,
      field_gender: this.state.field_gender,
      field_channel: this.state.field_channel,
      field_channel_other: this.state.field_channel_other,
      field_selected_recommendation_customer: this.state.field_selected_recommendation_customer,
    }

    if(!this.state.nid) {
      CustomerActions._post(values)
        .then(response => {


          component.setState(prevState => ({
            loaded: true,
            hasSubmitted: false,
          }))

          component._onToggleModal();
          component._onFormReset();

          if(component.props.searchComponent) {
            component.props.searchComponent.loadCustomerCollection();
          }

          if(component.props.customerDetail) {
            component.props.customerDetail.componentDidMount();
          }
        }).catch(error => {
          component.setState(prevState => ({
            loaded: true,
            hasSubmitted: false,
          }))
        });
    } else {
      CustomerActions._patch(values)
        .then(response => {

          component.setState(prevState => ({
            loaded: true,
            hasSubmitted: false,
          }))

          component._onToggleModal();
          if(component.props.searchComponent) {
            component.props.searchComponent.loadCustomerCollection();
          }

          if(component.props.customerDetail) {
            component.props.customerDetail.componentDidMount();
          }
        }).catch(error => {
          component.setState(prevState => ({
            loaded: true,
            hasSubmitted: false,
          }))
        });
    }
  }

  _onInputChange(e) {
    //console.log(e);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  _onChangeHandleFile = (file) => {
    var component = this;
    var reader = new FileReader();
    reader.addEventListener("load", function () {
         var dataString = reader.result;
         var uint8 = new Uint8Array(dataString);
         //console.log(uint8);
         CustomerActions._uploadImage(uint8).then(response => {
           component.setState({
             field_image: response.data.fid[0].value
           });
         });
    }, false);

    if(file) {
      reader.readAsArrayBuffer(file);
      this.setState({
        imageUrl: URL.createObjectURL(file)
      });
    }
  }

  _onFormReset() {

    if(this.genderFilterRef.current) {
      this.genderFilterRef.current.select.clearValue();
    }

    if(this.channelFilterRef.current) {
      this.channelFilterRef.current.select.clearValue();
    }

    if(this.fileInputRef.current) {
      this.fileInputRef.current.resetPreview();
    }
    var genders = this.props.genders;
    var genderFilters = new Array();
    if(genders) {
      Object.entries(genders).forEach(function(item, key){
        //console.log(item);
        var optionItem = {
          label: item[1].name,
          value: item[1].id,
        }
        genderFilters.push(optionItem);
      });
    }

    var locations = this.props.locations;
    var locationsFilter = new Array();
    if(locations) {
      Object.entries(locations).forEach(function(item, key){
        var optionItem = {
          label: item[1].title[0].value,
          value: item[0]
        }
        locationsFilter.push(optionItem);
      });
    }


    this.setState(prevState => ({
      loaded: true,
      genderFilters: genderFilters,
      locationsFilter: locationsFilter,
      nid: false,
      field_firstname: '',
      field_lastname: '',
      field_birthday: '',
      field_street: '',
      field_zip: '',
      field_city: '',
      field_phone: '',
      field_mobile: '',
      field_email: '',
      field_image: '',
      field_job: '',
      field_location: [JSON.parse(localStorage.getItem('selectedLocation'))],
      field_gender: [],
      field_channel: [],
      field_channel_other: null,
      field_selected_recommendation_customer: null,
      field_gender: []
    }))
  }

  _onGetPickerValue = (value) => {
    //console.log(value);
    //var date = new Date(value).toJSON();
    this.setState({
      field_birthday: value
    });
  }

  _onPreventToggle() {
    return false;
  }

  _onChannelChange(value) {

    this.setState({
      field_channel: value
    });
  };

  render() {
    let component = this;


    let showChannelField = false;
    if(!component.state.entity || component.state.entity.field_channel.length == 0) {
      showChannelField = true;
    }


    let showChannelOtherField = false;
    if(component.state.field_channel == '32' || component.state.field_channel_other) {
      showChannelOtherField = true;
    }

    let showChannelCustomerField = false;
    if(component.state.field_channel == '29') {
      showChannelCustomerField = true;
    }



    var defaultGenderValue = false;

    if(this.state.nid) {
      if(this.state.parent.state.genderFilters.find(x => x.value === this.state.field_gender.toString())) {
        defaultGenderValue = { label: this.state.parent.state.genderFilters.find(x => x.value === this.state.field_gender.toString()).label, value: this.state.field_gender.toString() }
      }
    }

    var defaultChannelValue = false;

    if(this.state.nid) {
      if(this.state.parent.state.channelsFilters.find(x => x.value === this.state.field_channel.toString())) {
        defaultChannelValue = { label: this.state.parent.state.channelsFilters.find(x => x.value === this.state.field_channel.toString()).label, value: this.state.field_channel.toString() }
      }
    }

    return (
      <>
        {!this.state.entity &&
          <>
          <MDBBtn onClick={this._onToggleModal} color="primary"><MDBIcon icon="plus mr-2" /> Neuen Kunden erfassen</MDBBtn>
          </>
        }

        {this.state.entity &&
          <MDBBtn onClick={this._onToggleModal} color="primary"><MDBIcon far icon="edit" /></MDBBtn>
        }

        <Modal title="Formular" destroyOnClose={true} visible={this.state.modal} onOk={this._onToggleModal} onCancel={this._onToggleModal} cancelButtonProps={{ style: { display: 'none' } }} okButtonProps={{ style: { display: 'none' } }}>



                <form validate="true" onSubmit={this._onSubmit}>

                  <MDBRow>
                    <MDBCol size="12" className="hide">
                        <MDBFileupload
                            maxFileSize="16M"
                            ref={this.fileInputRef}
                            getValue={this._onChangeHandleFile}
                            />

                    </MDBCol>
                    <MDBCol size="12">

                      <MDBRow>
                        <MDBCol>
                          <MDBInput
                            outline
                            name='field_firstname'
                            label='Vorname'
                            group
                            type='text'
                            error='wrong'
                            success='right'
                            required
                            onChange={e => this.setState({ [e.target.name]: e.target.value })}
                            value={this.state.field_firstname}
                          />
                        </MDBCol>
                        <MDBCol>
                          <MDBInput
                            outline
                            name='field_lastname'
                            label='Name'
                            group
                            type='text'
                            error='wrong'
                            success='right'
                            required
                            onChange={e => this.setState({ [e.target.name]: e.target.value })}
                            value={this.state.field_lastname}
                          />
                        </MDBCol>
                      </MDBRow>



                      <MDBRow>
                        <MDBCol>
                          <MDBInput
                            outline
                            name='field_street'
                            label='Strasse + Nr.'
                            group
                            type='text'
                            error='wrong'
                            success='right'
                            required
                            onChange={e => this.setState({ [e.target.name]: e.target.value })}
                            value={this.state.field_street}
                          />
                        </MDBCol>
                        <MDBCol>
                          <MDBInput
                            outline
                            name='field_zip'
                            label='PLZ'
                            group
                            type='text'
                            error='wrong'
                            success='right'
                            required
                            onChange={e => this.setState({ [e.target.name]: e.target.value })}
                            value={this.state.field_zip}
                          />
                        </MDBCol>
                        <MDBCol>
                          <MDBInput
                            outline
                            name='field_city'
                            label='Ort'
                            group
                            type='text'
                            error='wrong'
                            success='right'
                            required
                            onChange={e => this.setState({ [e.target.name]: e.target.value })}
                            value={this.state.field_city}
                          />
                        </MDBCol>
                      </MDBRow>


                      <MDBRow>
                        <MDBCol>
                          <MDBInput
                            outline
                            required
                            name='field_email'
                            label='E-Mail'
                            group
                            type='email'
                            error='wrong'
                            success='right'
                            onChange={e => this.setState({ [e.target.name]: e.target.value })}
                            value={this.state.field_email}
                          />
                        </MDBCol>
                        <MDBCol>
                          <MDBInput
                            outline
                            name='field_phone'
                            label='Festnetz'
                            group
                            type='text'
                            error='wrong'
                            success='right'
                            onChange={e => this.setState({ [e.target.name]: e.target.value })}
                            value={this.state.field_phone}
                          />
                        </MDBCol>
                        <MDBCol>
                          <MDBInput
                            outline
                            required
                            name='field_mobile'
                            label='Mobil'
                            group
                            type='text'
                            error='wrong'
                            success='right'
                            onChange={e => this.setState({ [e.target.name]: e.target.value })}
                            value={this.state.field_mobile}
                          />
                        </MDBCol>
                      </MDBRow>


                      <MDBRow>
                        <MDBCol>
                          <MDBInput
                            outline
                            name='field_job'
                            label='Job'
                            group
                            type='text'
                            error='wrong'
                            success='right'
                            onChange={e => this.setState({ [e.target.name]: e.target.value })}
                            value={this.state.field_job}
                          />
                        </MDBCol>
                        <MDBCol>

                          <div className="md-form form-group md-outline">
                            <MDBInput outline type="date" id="field_birthday" label="Geburtstag" placeholder="Geburtstag" name="field_birthday" value={this.state.field_birthday} onChange={this._onInputChange} required />
                          </div>


                        </MDBCol>
                      </MDBRow>


                      <MDBRow>
                        <MDBCol className="text-left">


                          <div className="md-form form-group md-outline">

                              <Select
                                ref={this.genderFilterRef}
                                required
                                className="react-select"
                                classNamePrefix="react-select"
                                placeholder="Geschlecht"
                                options={this.state.parent.state.genderFilters}
                                defaultValue={defaultGenderValue}
                                isClearable={true}
                                onChange={(value) => {

                                  if(value) {
                                    value = value.value;
                                  } else {
                                    value = '';
                                  }

                                  this._onSelectChange({
                                    target: {
                                      name: 'field_gender',
                                      value: value,
                                    },
                                  });
                                }}
                              />
                          </div>

                        </MDBCol>
                        <MDBCol className="text-left hide">

                          {/*
                            {PermissionHelper._hasPermission('ignore locations') === true &&
                              <MDBSelect
                                ref={this.locationsFilterRef}
                                options={this.state.locationsFilter}
                                selected="Bitte auswählen"
                                label="Standort"
                                name="field_location"
                                id="field_location"
                                multiple
                                getValue={(e) => {
                                  this._onSelectChange({
                                    target: {
                                      name: 'field_location',
                                      value: e,
                                    },
                                  });
                                }}
                              />
                            }
                            */}



                        </MDBCol>
                      </MDBRow>




                      {showChannelField &&
                        <MDBRow>
                          <MDBCol className="text-left">

                            <div className="md-form form-group md-outline">

                                <Select
                                  ref={this.channelFilterRef}
                                  required
                                  className="react-select"
                                  classNamePrefix="react-select"
                                  placeholder="Wie haben Sie uns gefunden"
                                  options={this.state.parent.state.channelsFilters}
                                  defaultValue={defaultChannelValue}
                                  isClearable={true}
                                  onChange={(value) => {

                                    if(value) {
                                      value = value.value;
                                    } else {
                                      value = '';
                                    }

                                    this._onSelectChange({
                                      target: {
                                        name: 'field_channel',
                                        value: value,
                                      },
                                    });
                                  }}
                                />
                            </div>

                          </MDBCol>
                        </MDBRow>
                      }


                      {showChannelOtherField && showChannelField &&
                        <MDBRow>
                          <MDBCol className="text-left">
                            <MDBInput outline  id="field_channel_other" name="field_channel_other" value={component.state.field_channel_other} onChange={component._onInputChange} label="Andere" required />
                          </MDBCol>
                        </MDBRow>
                      }

                      <MDBRow>
                        <MDBCol>

                          <div className="form-action-bar">
                            {!this.state.entity &&
                              <MDBBtn color="danger" onClick={this._onFormReset} className="mr-2 hide"><MDBIcon icon="sync mr-2" /> Zurücksetzen</MDBBtn>
                            }
                            <MDBBtn color="primary" type="submit" disabled={((this.state.hasSubmitted) || (this.state.field_channel == 29 && !this.state.field_selected_recommendation_customer)) ? true : false}><MDBIcon far icon="save" className="mr-2" /> Speichern</MDBBtn>
                          </div>

                        </MDBCol>
                      </MDBRow>

                    </MDBCol>
                  </MDBRow>
                </form>

        </Modal>
      </>
    );
  }
}

export default CustomerForm;
