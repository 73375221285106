import React, { Component } from 'react';
import PermissionHelper from "../../../helpers/PermissionHelper";


import {
  MDBSpinner,
  MDBTable,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBLink,
  MDBBtn,
  MDBIcon,
  MDBCardImage,
  MDBInput,
  MDBTooltip,
  MDBTableHead,
  MDBTableBody
} from 'mdbreact';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as AllLightIcons from '@fortawesome/pro-light-svg-icons'

import CheckoutItem from "./CheckoutItem";

import config from '../../../config';


class CheckoutItemList extends Component {

  constructor(props) {
    super(props);
    this.state = {

    }
  }


  componentDidMount(){
  }


  render() {

    var component = this;

    console.log(component.props.checkout.state.order.orderItems);

    return (
      <MDBCard className="w-100">
        <MDBCardBody>


          {component.props.checkout.state.order.orderItems.length > 0 && (
            <ul className="w-100 table">
                {true && (
                  Object.entries(component.props.checkout.state.order.orderItems).map(function(row, j){
                    console.log(row)
                    return (
                      <CheckoutItem checkout={component.props.checkout} result={row[1]}  key={'checkout-items-view-' + row[1].node.nid[0].value + row[1].node.changed[0].value} searchComponent={component} />
                    )
                  })
                )}
            </ul>
          )}

          {component.props.checkout.state.order.orderItems.length == 0 &&
            <div className="empty">
              <div class="icon-bar">
                <FontAwesomeIcon icon={AllLightIcons.faCartPlus} />
              </div>
              <h2>Keine Produkte im Warenkorb</h2>
            </div>
          }

        </MDBCardBody>
      </MDBCard>
    );
  }
}

export default CheckoutItemList;
