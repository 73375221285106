import React, {useState,useEffect} from 'react';
import {
  MDBCol,
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
  MDBLink,
  MDBView,
  MDBMask,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBRow,
  MDBIcon,
  MDBTimeline,
  MDBTimelineStep
} from 'mdbreact';


import SubscriptionComment from "../../comment/CommentModalSearch";

import SubscriptionActions from "./actions/SubscriptionActions";
import PermissionHelper from "../../../../helpers/PermissionHelper";

import moment from 'moment';
import 'moment/locale/de';


class SubscriptionResultRow extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false
    }

  }

  _onToggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {
    return (
      <tr>
        <td>{this.props.result.node.field_subscription_number[0].value}</td>
        <td>{this.props.result.subscription_category.name[0].value}</td>
        <td>{this.props.result.treatment_type.name[0].value}</td>
        <td>{this.props.result.node.field_counter[0].value} / {this.props.result.subscription_category.field_limit[0].value}</td>

        <td className="text-right">

          <div className="action-bar">

            <SubscriptionComment showImportant={true} parentComponent={this} ref={this.subscriptionCommentRef} entityId={this.props.result.node.nid[0].value} entityType="node" fieldName="field_subscription_comment" commentType="subscription_comment" />

            {this.props.result.node.field_counter[0].value < this.props.result.subscription_category.field_limit[0].value &&
              <MDBBtn color="black" onClick={() => { this.props.searchComponent.props.onHandleSubscriptionPayment(this.props.result.node.nid[0].value) }} className="ml-2 mr-2"><MDBIcon far icon="check-circle" /></MDBBtn>
            }
          </div>
        </td>
      </tr>
    );
  }
}

export default SubscriptionResultRow;
