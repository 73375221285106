import React, {useState,useEffect} from 'react';
import {
  MDBCol,
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
  MDBLink,
  MDBView,
  MDBMask,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBRow,
  MDBIcon,
  MDBCardFooter,
  MDBTooltip
} from 'mdbreact';

import PermissionHelper from "../../../../helpers/PermissionHelper";

import OrderItemActions from "../../order/actions/OrderItemActions";

import moment from 'moment';
import 'moment/locale/de';

import Logo from '../../../../assets/images/logoTop.svg' // relative path to image




class ProductResultRow extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false
    };

    this._onAddToBasket = this._onAddToBasket.bind(this);
  }

  componentDidMount() {
    var component = this;
  }



  _onAddToBasket(e) {
    var component = this;
    console.log('_onAddToBasket');
    console.log(e);




    var values = {
      field_order: component.props.searchComponent.props.checkout.state.order.node.nid[0].value,
      field_product: e
    }



    console.log(e);



    OrderItemActions._post(values)
      .then(response => {
        console.log(response);
        component.props.searchComponent.props.checkout._onUpdateOrder(values.field_order);
      });

  }

  render() {

    var component = this;
    console.log(this.props.result);

    return (

      <article onClick={() => { this._onAddToBasket(this.props.result.node.nid[0].value) }} tabindex="0" data-product-id="7" aria-labelledby="article_product_7" class="product">
          <div class="product-img" style={{backgroundColor: '#60bfbb', width: '100%', height: '100%'}}>
            {this.props.result.imageData &&
              <>
              <MDBCardImage
        className="img-fluid"
        src={this.props.result.imageData}
        waves
      /></>
            }

            {!this.props.result.imageData &&
              <>

              <MDBCardImage
                  style={{padding: '10px'}}
                  className="img-fluid"
                  src={Logo}
                  waves
                />
              </>
            }

              <span class="price-tag">  { this.props.result.node.field_price[0].value } CHF</span>
          </div>
          <div id="article_product_7" class="product-name">{ this.props.result.node.title[0].value }</div>
      </article>





    );
  }
}

export default ProductResultRow;
