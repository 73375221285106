import React, {useState,useEffect} from 'react';
import {
  MDBCol,
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
  MDBLink,
  MDBView,
  MDBMask,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBRow,
  MDBIcon,
  MDBInput,
  MDBCollapse
} from 'mdbreact';


import Currency from 'react-currency-formatter';

import OrderItemActions from "../order/actions/OrderItemActions";

import PermissionHelper from "../../../helpers/PermissionHelper";

import CheckoutDiscount from './CheckoutDiscount';

import CheckoutItemComment from "../comment/CommentModalSearch.jsx";



import moment from 'moment';
import 'moment/locale/de';



class CheckoutItem extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      total: this.props.result.node.field_qty[0].value * this.props.result.node.field_price[0].value,
      field_price:  this.props.result.node.field_price[0].value,
      field_qty: this.props.result.node.field_qty[0].value,
      title: this.props.result.node.title[0].value,
      collapseID: ""
    };

    this._onDelete = this._onDelete.bind(this);
    this._onQtyUpdate = this._onQtyUpdate.bind(this);
    this._onPriceUpdate = this._onPriceUpdate.bind(this);
    this._applyDiscount = this._applyDiscount.bind(this);
    this._onToggleCollapse = this._onToggleCollapse.bind(this);
    this._onHandleChange = this._onHandleChange.bind(this);
    this._onSave = this._onSave.bind(this);
  }



  _onToggleCollapse = collapseID => () => {
    console.log(collapseID);
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : ""
    }));
  }



  _onDelete(e) {
    var component = this;
    const r = window.confirm("Löschen Bestätigen");
    if (r === true) {
      OrderItemActions._delete(this.props.result.node.nid[0].value)
        .then(response => {
          component.props.searchComponent.props.checkout._onUpdateOrder(this.props.result.node.field_order[0].target_id);
        });
    }
  }



  _onQtyUpdate(num) {
    var component = this;

    var values = {
      nid: this.props.result.node.nid[0].value,
      data: {
        field_order: component.props.searchComponent.props.checkout.state.order.node.nid[0].value,
        field_qty: num
      }
    }

    OrderItemActions._patch(values)
      .then(response => {
        console.log(response);
        component.props.searchComponent.props.checkout._onUpdateOrder(values.data.field_order);
      });

  }


  _onPriceUpdate(price) {
    var component = this;

    var values = {
      nid: this.props.result.node.nid[0].value,
      data: {
        field_order: component.props.searchComponent.props.checkout.state.order.node.nid[0].value,
        field_price: price
      }
    }

    OrderItemActions._patch(values)
      .then(response => {
        console.log(response);
        component.props.searchComponent.props.checkout._onUpdateOrder(values.data.field_order);
      });

  }

  _onHandleChange(e) {

    var component = this;

    this.setState({
        [e.target.name]: e.target.value
    },() => {

    });
  }

  _onSave(e) {
    var component = this;

    var values = {
      nid: this.props.result.node.nid[0].value,
      data: {
        field_order: component.props.searchComponent.props.checkout.state.order.node.nid[0].value,
        field_qty: this.state.field_qty,
        title: this.state.title,
        field_price: this.state.field_price
      }
    }

    OrderItemActions._patch(values)
      .then(response => {
        console.log(response);
        component.props.searchComponent.props.checkout._onUpdateOrder(values.data.field_order);
      });
  }

  _applyDiscount(total) {
    this.setState(prevState =>({
      total:total,
    }))
  }



  render() {
    var component = this;
    console.log(this.props.result);

    return (

      <li className={`${this.state.collapseID ? "active" : ""}`} key={'checkoutitem-'+this.props.result.node.nid[0].value+this.props.result.node.changed[0].value}>

        <div className="orderline">

          <span className="w-20" onClick={this._onToggleCollapse('item-collapse-'+this.props.result.node.nid[0].value)}>
            <span class="product-qty">{parseInt(this.props.result.node.field_qty[0].value)}</span> x
          </span>

          <span className="w-50" onClick={this._onToggleCollapse('item-collapse-'+this.props.result.node.nid[0].value)}>
            <span class="product-name">{this.props.result.node.title[0].value}</span>
          </span>

          <span className="w-30">
            {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'CHF' }).format(this.state.total)}
            {PermissionHelper._hasPermission('restful delete OrderItem') === true &&
              <>
                <MDBBtn color="danger" onClick={this._onDelete} flat><MDBIcon far icon="trash-alt" /></MDBBtn>
              </>
            }



            {/*
              <span class="comment">
                <CheckoutItemComment component={this} entityId={this.props.result.node.nid[0].value} entityType="node" fieldName="field_order_item_comment" commentType="order_item_comment" showImportant={true} />
              </span>
              */}


          </span>



        </div>

        <MDBCollapse id={'item-collapse-'+this.props.result.node.nid[0].value} isOpen={this.state.collapseID}>

          <div className="d-flex align-items-center checkout-item-form">
            <div>
              <MDBInput type="Number" label="Menge" name="field_qty" value={parseInt(this.state.field_qty)} onChange={(e) => this._onHandleChange(e)} outline />
            </div>
            <div>
              <MDBInput type="Number" label="Preis" name="field_price" value={this.state.field_price} onChange={(e) => this._onHandleChange(e)} outline />
            </div>
            <div>
              <MDBInput label="Name" name="title" value={this.state.title} onChange={(e) => this._onHandleChange(e)} outline />
            </div>
            <div>
              <MDBBtn color="primary" onClick={(e) => this._onSave(e)}>
                <MDBIcon far icon="save mr-2" />
              </MDBBtn>
            </div>
          </div>

          <hr />

          <CheckoutDiscount checkout={this.props.checkout} entityRef={this.props.result} applyDiscount={this._applyDiscount} total={this.state.total} />

        </MDBCollapse>
      </li>
    );
  }
}

export default CheckoutItem;
