import React, {
  Component
} from "react";

import {DebounceInput} from 'react-debounce-input';
import PermissionHelper from "../../../../helpers/PermissionHelper";
import { Collapse } from 'antd';

import { Modal } from 'antd';

import {
  MDBSpinner,
  MDBTable,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBInput,
  MDBSelect,
  MDBCollapseHeader,
  MDBIcon,
  MDBCollapse,
  MDBPagination,
  MDBPageItem,
  MDBPageNav,
  MDBBtn,
  MDBBtnGroup
} from 'mdbreact';

class ProductFilter extends Component {

  constructor(props) {
    super(props);

    this.state = {
      productFilter: null,
      modal: false,
    }

    this._onCheckboxChange = this._onCheckboxChange.bind(this);
    this._onSelectChange = this._onSelectChange.bind(this);
    this._onInputChange = this._onInputChange.bind(this);
    this._onClick = this._onClick.bind(this);
    this._onDirectionChange = this._onDirectionChange.bind(this);
    this._onToggleModal = this._onToggleModal.bind(this);
  }

  componentDidMount() {
    var component = this;
  }

  _onCheckboxChange(e) {
    var name = e.target.name;
    var value = e.target.value;
    var filters = this.props.searchComponent.state.filters;
    filters[name] = value;


    this.props.searchComponent.setState({
        filters: filters,
        page: 0
    }, () => {
        //var productFilter = this.props.searchComponent.state;
        //localStorage.setItem('productFilter', JSON.stringify(productFilter));
        this.props.searchComponent.loadProductCollection();
    });

  }

  _onSelectChange(e) {
    var name = e.target.name;
    var value;
    if(e.target.value[0]) {
      value = e.target.value[0];
    } else {
      value = null;
    }
    var filters = this.props.searchComponent.state.filters;
    filters[name] = value;


    this.props.searchComponent.setState({
        filters: filters,
        page: 0
    }, () => {
        //var productFilter = this.props.searchComponent.state;
        //localStorage.setItem('productFilter', JSON.stringify(productFilter));
        this.props.searchComponent.loadProductCollection();
    });
  }


  _onClick(name, value) {
    var component = this;
    var filters = this.props.searchComponent.state.filters;
    filters[name] = value;


    if(name == 'field_is_favorite') {
      filters['field_product_category'] = null;
      filters['field_is_favorite'] = 1;
    } else {
      filters['field_is_favorite'] = 0;
    }

    this.props.searchComponent.setState({
        filters: filters,
        page: 0
    }, () => {
        //var productFilter = this.props.searchComponent.state;
        //localStorage.setItem('productFilter', JSON.stringify(productFilter));
        component.setState(prevState => ({
          modal: false
        }));
        this.props.searchComponent.loadProductCollection();
    });
  }

  _onDirectionChange(e) {
    var name = e.target.name;
    var value = e.target.value;

    var filters = this.props.searchComponent.state.filters;
    filters[name] = value;

    this.props.searchComponent.setState({
        filters: filters,
        page: 0
    }, () => {
        //var productFilter = this.props.searchComponent.state;
        //localStorage.setItem('productFilter', JSON.stringify(productFilter));
        this.props.searchComponent.loadProductCollection();
    });
  }

  _onInputChange(e) {
    var name = e.target.name;
    var value = e.target.value;
    var filters = this.props.searchComponent.state.filters;
    filters[name] = value;


    this.props.searchComponent.setState({
        filters: filters,
        page: 0
    }, () => {
        //var productFilter = this.props.searchComponent.state;
        //localStorage.setItem('productFilter', JSON.stringify(productFilter));
        this.props.searchComponent.loadProductCollection();
    });
  }


  toggleCollapse = collapseID => () => {
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : ""
    }));
  }

  _onToggleModal = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {
    //console.log(this.props.searchComponent.state.productCategoryData);
    let component = this;

    const { Panel } = Collapse;


    return (
      <div className="filter-card">

        <MDBRow>
          <MDBCol md="12" xs="12">
            <div className="category-filter">


    <MDBBtn
color="primary"
onClick={this._onToggleModal}
>
<MDBIcon icon="tags" />   Kategorien
</MDBBtn>


      <Modal width={700} title="Kategorien" destroyOnClose={true} visible={this.state.modal} onOk={this._onToggleModal} onCancel={this._onToggleModal} cancelButtonProps={{ style: { display: 'none' } }} okButtonProps={{ style: { display: 'none' } }}>

        <div className="category-filter-buttons">
        <MDBBtn className={`${this.props.searchComponent.state.filters.field_is_favorite == '1' ? "active" : ""}`} color="primary" onClick={() => { component._onClick('field_is_favorite', 1) }}><MDBIcon icon="tags" /> - Favoriten -</MDBBtn>


        {Object.values(this.props.searchComponent.state.productCategoryData).map((value, index) => {
          console.log(value);
          return (
            <><MDBBtn className={`${this.props.searchComponent.state.filters.field_product_category == value.tid[0].value ? "active" : ""}`} color="primary" onClick={() => { component._onClick('field_product_category', value.tid[0].value) }}><MDBIcon icon="tags" /> {value.name[0].value}</MDBBtn></>
          )
        })}

      </div>

      </Modal>


            </div>
          </MDBCol>

        </MDBRow>
        <hr />
          <MDBRow>
            <MDBCol>
                <div className="sui-search-box">
                  <div className="sui-search-box__wrapper">
                    <DebounceInput
                      minLength={3}
                      debounceTimeout={800}
                      id="field_searchterm"
                      name="field_searchterm"
                      placeholder="Suchbegriff"
                      className="sui-search-box__text-input "
                      onChange={(e) => {
                        this._onInputChange({
                          target: {
                            name: 'field_searchterm',
                            value: e.target.value,
                          },
                        });
                      }}
                      value={this.props.searchComponent.state.filters.field_searchterm}
                    />

                   {!this.props.searchComponent.state.collectionLoaded && (
                     <><MDBSpinner red small /></>
                   )}
                  </div>
                </div>
            </MDBCol>
          </MDBRow>
      </div>
    )
  }

}

export default ProductFilter;
