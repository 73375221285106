import React, { Component } from 'react';
import QRCode from "react-qr-code";
import moment from 'moment';
import 'moment/locale/de';


export class CreditMemoReceiptPrint extends React.PureComponent {
  render() {
    const receipt = require('receipt');
    receipt.config.currency = 'CHF ';
    receipt.config.width = 31;
    receipt.config.ruler = '-';



    var creditMemoLine = new Array();


    var line = {
      item: this.props.creditMemo.product.title[0].value,
      qty: 1,
      cost: -Number(this.props.creditMemo.node.field_amount[0].value).toFixed(2).replace('.', ""),
    }


    creditMemoLine.push(line);


    var creditMemoAmount = this.props.creditMemo.node.field_amount[0].value;
    var creditMemoTax = Number(this.props.creditMemo.orderItem.field_tax[0].value);
    var creditMemoTaxValue = creditMemoAmount - creditMemoAmount / (100 + creditMemoTax) * 100;

    const output = receipt.create([
        { type: 'text', value: [
          '',
          'Gutschrift',
        ], align: 'center' },
        { type: 'empty' },
        { type: 'text', value: [
          'Venus Beauty AG',
          'Bäumlistrasse 32',
          '8404 Winterthur',
          'info@venus-beauty.ch',
          'www.venus-beauty.ch',
          'CHE-261.630.740 MWST'
        ], align: 'center' },
        { type: 'empty' },
        { type: 'properties', lines: [
            { name: 'Bestellnr', value: this.props.order.node.field_order_number[0].value },
            { name: 'Gutschrift', value: this.props.creditMemo.node.field_credit_memo_number[0].value },
            { name: 'Datum', value:  moment(new Date(this.props.creditMemo.node.created[0].value)).tz('Europe/Zurich').format("DD.MM.YYYY HH:mm") },
            { name: 'Kassierer', value:  JSON.parse(localStorage.getItem('user')).field_firstname[0].value + ' ' + JSON.parse(localStorage.getItem('user')).field_lastname[0].value},
            { name: 'Standort', value:  JSON.parse(localStorage.getItem('selectedLocation')).title[0].value}
        ] },
        { type: 'table', lines: creditMemoLine },
        { type: 'empty' },

        { type: 'properties', lines: [
            { name: 'Steuer', value: new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'CHF' }).format(creditMemoTaxValue) },
            { name: 'Gesamtsumme', value: new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'CHF' }).format(this.props.creditMemo.node.field_amount[0].value) }
        ] },
        { type: 'empty' },
        { type: 'text', value: 'Vielen Dank für Ihren Besuch.', align: 'left' },
        { type: 'empty' },
    ]);


    return (
      <>
      <pre>
        <img src="https://www.venus-beauty.ch/themes/venusbeauty/images/logo.svg" className="order-logo" />
        {output}
      </pre>

      </>
    );
  }
}
