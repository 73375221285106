import React, { Component } from 'react';


/** import config **/
import config from '../../../config';
import { MDBContainer, MDBRow, MDBCol, MDBStepper, MDBStep, MDBBtn, MDBInput, MDBSpinner, MDBDatePickerV5 } from "mdbreact";
import { connect } from 'react-redux';
import { withTranslation, initReactI18next } from "react-i18next";

import AdminCardSection1 from './sections/AdminCardSection1';
import AdminCardSection2 from './sections/AdminCardSection2';
import TableSection from './sections/TableSection';
import BreadcrumSection from './sections/BreadcrumSection';
import ChartSection1 from './sections/ChartSection1';
import ChartSection2 from './sections/ChartSection2';
import ModalSection from './sections/ModalSection';

import StatisticActions from "./actions/StatisticActions";
import StatisticFilter from "./StatisticFilter";


import DailyStats from './sections/DailyStats';
import WeeklyStats from './sections/WeeklyStats';
import WeeklySalesStats from './sections/WeeklySalesStats';
import SellStats from './sections/SellStats';


/** import styles **/
import '../../../assets/css/sections/statistic.css';




class Statistic extends Component {

  constructor(props) {
    super(props);

    var selectedLocation = JSON.parse(localStorage.getItem('selectedLocation'));
    this.state = {
      loaded: false,
      selectedLocation: selectedLocation,
      filters: {
        field_location: (selectedLocation) ? selectedLocation.nid[0].value : false,
        field_from: null,
        field_to: null,
        field_ignore_location: false
      },
      page: 0,
      result: []
    }
 }

  componentDidMount() {
    console.log(this);


    var component = this;


    var statisticFilter = JSON.parse(localStorage.getItem('statisticFilter'));

    if(statisticFilter) {
      statisticFilter.filters.field_from = new Date(statisticFilter.filters.field_from);
      statisticFilter.filters.field_to = new Date(statisticFilter.filters.field_to);
      statisticFilter.filters.field_location = (this.state.selectedLocation) ? this.state.selectedLocation.nid[0].value : false;
      component.setState({
        filters: statisticFilter.filters
      }, function() {
        //console.log('1');
        //return;
        component.loadData();
      });
    } else {
      //console.log('2');
      //return;
      let statisticFilter = component.state.filters;

      statisticFilter.field_location = (this.state.selectedLocation) ? this.state.selectedLocation.nid[0].value : false;
      var fromDate = new Date(new Date().setHours('00','00','00'));
      var toDate = new Date(new Date().setHours('23','59','50'));
      statisticFilter.field_from = fromDate.toJSON();
      statisticFilter.field_to = toDate.toJSON();

      component.setState({
        filters: statisticFilter
      }, function() {
        //console.log('1');
        //return;
        component.loadData();
      });
    }




  }


  loadData() {


    console.log(this.state.filters);

    this.setState(prevState => ({
      result: [],
      loaded: false
    }))


    var filters = this.state.filters;

    filters.field_from = new Date(filters.field_from).toJSON();
    filters.field_to = new Date(filters.field_to).toJSON();

    StatisticActions._get('dashboard', filters, this.state.page).then(response => {


        this.setState(prevState => ({
          result: response.data.data,
          loaded: true
        }))

      });
  }


  render() {


    return (
      <section id="statistic">


        {this.state.selectedLocation &&
          <>
          <StatisticFilter statistic={this} />

          <hr />

              <>
              <DailyStats statistic={this} />

              {/*<WeeklyStats statistic={this} />*/}

              {/*<WeeklySalesStats statistic={this} />*/}

              <SellStats statistic={this} />

              {/*<StackedStats statistic={this} />*/}
              </>

          </>
        }

        {!this.state.selectedLocation &&
          <>
            <>Bitte wähle zuerst eine Standort aus</>
          </>
        }





      </section>
    );
  }
}

export default Statistic;
