import React, { Component } from 'react';

import {
  MDBCard,
  MDBIcon,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
  MDBLink,
  MDBInput
} from 'mdbreact';

import axios from 'axios';

import userActions from '../../../actions/userActions';

import { withTranslation, initReactI18next } from "react-i18next";

import { connect } from 'react-redux';


import OrderActions from "../order/actions/OrderActions";
import OrderSearch from "../order/OrderSearch";

import ProductSearch from '../checkout/products/ProductSearch';
import CheckoutTotals from '../checkout/CheckoutTotals';
import CheckoutItemList from '../checkout/CheckoutItemList';
import CheckoutCustomer from '../checkout/CheckoutCustomer';
import CheckoutPayment from '../checkout/CheckoutPayment';
import CheckoutComment from "../comment/CommentModalSearch.jsx";


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as AllLightIcons from '@fortawesome/pro-light-svg-icons'


/** import config **/
import config from '../../../config';

/** import styles **/
import '../../../assets/css/sections/odoo.css';


class CheckoutOdoo extends Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      order: null,
      updateOrder: false,
      user: this.props.user,
    }


    this._onClose = this._onClose.bind(this);
    this._onStartCheckout = this._onStartCheckout.bind(this);
    this._onUpdateOrder = this._onUpdateOrder.bind(this);
  }


  componentDidMount() {
  }


  _onStartCheckout(order) {
    var component = this;

    if(component.props.application.streamRef.current) {
      component.props.application.streamRef.current._onStart();
    }

    this.setState(prevState =>({
      order:order,
      updateOrder:false
    }))

    console.log(component.props.application.streamRef);

    if(component.props.application.streamRef.current.state.presentationConnection) {
      component.props.application.streamRef.current.state.presentationConnection.send(JSON.stringify(order));
    }
  }

  _onUpdateOrder(orderId) {
    var component = this;
    this.setState(prevState =>({
      updateOrder:true
    }))

    OrderActions._get(orderId, null, 1).then(response => {
      console.log(response);
      component._onStartCheckout(response.data.entity);
    })
  }

  _onClose() {
    var component = this;

    if(component.props.application.streamRef.current.state.presentationConnection) {
      component.props.application.streamRef.current.state.presentationConnection.send(null);
    }

    this.setState(prevState =>({
      order:null,
    }), function(prevState) {
    })
  }

  _onOpen() {
    var component = this;

    if(component.props.stream.current.state.presentationConnection) {
      component.props.stream.current.state.presentationConnection.send(null);
    }

    this.setState(prevState =>({
      order:null,
    }), function(prevState) {
    })
  }

  render() {
    return (
      <section id="checkout-overview">

        {!this.state.order &&
          <>
            <OrderSearch checkout={this} cashRegisterSessionId={JSON.parse(localStorage.getItem('selectedCashRegisterSession')).node.nid[0].value} />
          </>
        }

        {this.state.order &&
          <div class="pos">
              <div class="pos-receipt-print"></div>

              <div class="pos-topheader">

                <div class="listheader oe_status"><span class="username">Warenkorb</span></div>

                  <div class="pos-rightheader">
                    <div class="status-buttons-portal">
                      <div class="status-buttons">

                        <div class="oe_status"><span class="username">{this.state.user.field_firstname[0].value} {this.state.user.field_lastname[0].value}</span></div>

                        <div class="header-button close_button">
                          <CheckoutComment key={'order-comment-'+this.state.order.node.changed[0].value}  component={this} entityId={this.state.order.node.nid[0].value} entityType="node" fieldName="field_order_comment" commentType="order_comment" showImportant={true} />
                        </div>

                        <div class="header-button close_button" onClick={this._onClose}>
                          <FontAwesomeIcon icon={AllLightIcons.faTimes} />
                        </div>

                      </div>
                    </div>
                  </div>
              </div>
              <div class="pos-content">
                  <div class="window">
                      <div class="subwindow">
                          <div class="subwindow-container">
                              <div class="subwindow-container-fix screens">
                                  <div class="product-screen screen">
                                      <div class="screen-full-width">
                                          <div class="leftpane">

                                              <div class="order-container">
                                                  <div class="order">
                                                      <ul class="orderlines">
                                                          <CheckoutItemList key={'order-item-list-'+this.state.order.node.changed[0].value} checkout={this} />
                                                      </ul>
                                                  </div>
                                              </div>


                                              <div class="pads">
                                                  <CheckoutCustomer key={'order-customer-'+this.state.order.node.changed[0].value} checkout={this} />
                                              </div>

                                              <div class="pads">
                                                <div class="summary clearfix">
                                                    <CheckoutTotals showPrint={true} key={'order-totals-'+this.state.order.node.changed[0].value} checkout={this} />
                                                </div>
                                              </div>

                                                <div class="pads">
                                                    <CheckoutPayment key={'order-payment-'+this.state.order.node.changed[0].value} checkout={this} />
                                                </div>


                                          </div>
                                          <div class="rightpane">
                                              <div class="products-widget p-3">
                                                <MDBCard className="product-card">
                                                  <MDBCardBody>
                                                    <MDBCardText>
                                                      <ProductSearch checkout={this} />
                                                    </MDBCardText>
                                                  </MDBCardBody>
                                                </MDBCard>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        }
      </section>
    );
  }
}


function mapState(state) {
    const { authenticationReducer } = state;
    const { user } = authenticationReducer;
    return { user };
}

const actionCreators = {
  checkSession: userActions.checkSession,
  logout: userActions.logout
}

const connectedCheckoutOdoo = withTranslation()(connect(mapState, actionCreators)((CheckoutOdoo)));
export { connectedCheckoutOdoo as CheckoutOdoo };
