import React from 'react';
import {
  MDBNotification,
  MDBContainer
} from 'mdbreact';
import axios from "axios";
import config from '../config';



class JwtComponent extends React.Component {

  constructor(props) {
    super(props);

    let component = this;
    axios.interceptors.request.use( async(requestConfig) => {
      console.log(requestConfig);
      requestConfig.timeout = 0;
        /**
         * Add jwt token to requests
        */
        if(!requestConfig.url.includes("session/token") && !requestConfig.url.includes("nexo") && !requestConfig.url.includes("adyen.com")) {
          if(localStorage.getItem('user')) {
            const user = JSON.parse(localStorage.getItem('user'));
            requestConfig.headers['Authorization'] = 'Bearer ' + user.access_token;
          }
        }
        return requestConfig;
    }, function (error) {
      //alert(error.message);
      //return Promise.reject(error)
    })
  }

  render() {
    return (
      <>
      </>
    );
  }
}

export default JwtComponent;
