import React, {useState,useEffect} from 'react';
import {
  MDBCol,
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
  MDBLink,
  MDBView,
  MDBMask,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBRow,
  MDBIcon
} from 'mdbreact';

import CommentActions from "./actions/CommentActions";

import Image from "../image/Image";

import PermissionHelper from "../../../helpers/PermissionHelper";

import moment from 'moment';
import 'moment/locale/de';



class CommentResultRow extends React.Component {

  constructor(props) {
    super(props);

    this._onDelete = this._onDelete.bind(this);
  }



  _onDelete(e) {
    var component = this;
    const r = window.confirm("Löschen Bestätigen");
    if (r === true) {

      component.props.searchComponent.setState(prevState => ({
        loaded: false,
      }))

      CommentActions._delete(this.props.result.cid)
        .then(response => {
          component.props.searchComponent.formRef.current._onFormReset();
          component.props.searchComponent.loadCommentCollection();
        });
    }
  }


  render() {
    return (


      <li>
        <div className={"comment-main-level " + (this.props.result.field_important == '1' ? 'important' : '')}>
          <div class="comment-box">
            <div class="comment-head">
              <h6 class="comment-name by-author">
                {this.props.result.field_important == 1 &&
                  <MDBIcon icon="exclamation-triangle" />
                }

                <a href="#">{this.props.result.firstname} {this.props.result.lastname}</a>

            </h6>
              <span>{moment.unix(this.props.result.created, "YYYYMMDD").tz('Europe/Zurich').fromNow()}</span>
                {PermissionHelper._hasPermission('restful delete Comment') === true &&
                <div className="action-bar">
                      <MDBBtn color="danger" onClick={this._onDelete}><MDBIcon far icon="trash-alt" /></MDBBtn>
                </div>
                }
            </div>
            <div class="comment-content">
              <h4>{this.props.result.subject}</h4>
              {this.props.result.comment_body}
            </div>
          </div>
        </div>

      </li>


    );
  }
}

export default CommentResultRow;
