import React, { Component } from 'react';
import PermissionHelper from "../../../helpers/PermissionHelper";
import ReactToPrint from 'react-to-print';

import Receipt from "../receipt/Receipt";
import { Modal } from 'antd';

import {
  MDBSpinner,
  MDBTable,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBLink,
  MDBBtn,
  MDBIcon,
  MDBCardImage,
  MDBContainer,
  MDBModal,
  MDBModalHeader,
  MDBModalBody
} from 'mdbreact';

import Countdown from 'react-countdown';

import QRCode from "react-qr-code";

import Currency from 'react-currency-formatter';

import CheckoutDiscount from './CheckoutDiscount';

import Totals from '../totals/Totals';

import moment from 'moment';
import 'moment/locale/de';

import config from '../../../config';


class CheckoutTotals extends Component {

  constructor(props) {
    super(props);
    this.state = {
      subtotal: 0,
      total: 0,
      discount: 0,
      tax: 0,
      modal: true
    }


    this.receiptRef = React.createRef();

    this._onComplete = this._onComplete.bind(this);
  }


  componentDidMount(){
    var component = this;
    if(this.props.checkout.state.order.totals.due <= 0 && this.props.checkout.state.order.totals.total > 0) {

      if(this.props.showPrint) {
        this.receiptRef.current.ref.handlePrint();
      }
      window.setTimeout(function() {
        //component.ref.handlePrint();
    }, 1000);
    }
  }

  _onComplete() {
    var component = this;
    this.props.checkout._onClose();
  }


  render() {
    let component = this;

    // Renderer callback with condition
    const renderer = ({ hours, minutes, seconds, completed }) => {
      if (completed) {
        return <span></span>
      } else {
        // Render a countdown
        return <span>Dieses Fenster schliest in: {seconds} Sekunden</span>;
      }
    };


    return (
      <section id="checkout-totals">

        {this.props.checkout.state.order.totals.due <= 0 && this.props.checkout.state.order.totals.total > 0 &&
          <MDBContainer class="success-modal">
            <Modal closable={false} title="" destroyOnClose={true} visible={this.state.modal} onOk={this._onToggleModal} onCancel={this._onToggleModal} cancelButtonProps={{ style: { display: 'none' } }} okButtonProps={{ style: { display: 'none' } }}>

              <div className="text-center">
                <h1>Bestellung abgeschlossen</h1>
                <Countdown
                   date={Date.now() + 15000}
                   renderer={renderer}

                   onComplete={this._onComplete}
                 />
                {this.props.showPrint &&
                  <div class="hide">
                  <Receipt order={this.props.checkout.state.order} ref={this.receiptRef} />
                  </div>
                }
              </div>


            </Modal>
          </MDBContainer>
        }


        <MDBCard>
          <MDBCardBody>
              <>
                <CheckoutDiscount checkout={this.props.checkout} entityRef={this.props.checkout.state.order} />
              </>
          </MDBCardBody>
        </MDBCard>



        <Totals order={this.props.checkout.state.order} />

      </section>
    );
  }
}


export default CheckoutTotals;
