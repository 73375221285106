import React, { Component } from 'react';

import {
  MDBSpinner,
  MDBTable,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBLink,
  MDBBtn,
  MDBIcon,
  MDBCardImage
} from 'mdbreact';

import CashRegisterIcon from '../../../../assets/images/kasse-icon.svg' // relative path to image

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as AllLightIcons from '@fortawesome/pro-light-svg-icons'

class LocationSearch extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      result: JSON.parse(localStorage.getItem('locations')),
    }


    this._onSelect = this._onSelect.bind(this);
  }

  componentDidMount(){
    if(this.state.result.length == 1) {
      this._onSelect(this.state.result[0]);
    }
  }

  _onSelect(entity) {
    console.log(this.props.searchComponent);
    localStorage.setItem('selectedLocation', JSON.stringify(entity));
    this.props.stepper.setState({
      ['formActivePanel' + 1]: 3,
      ['formActivePanel' + 1 + 'Changed']: true,
      ['selectedLocation']: entity,
    });
  }

  render() {
    let component = this;
    return (
      <>
        <ul>
      {true && (
        component.state.result.map(function(row, j){
          console.log(row)
          return (

            <li className="cashregister-item" onClick={() => component._onSelect(row)}>
              <div className="icon">
                <FontAwesomeIcon icon={AllLightIcons.faMapMarker} />
              </div>
              <div className="title">
                {row.title[0].value}
              </div>

            </li>

          )
        })
      )}

    </ul>

      </>
    );
  }
}

export default LocationSearch;
