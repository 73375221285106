import React, {Component} from "react";
import {
  MDBContainer,
  MDBCollapse,
  MDBCard,
  MDBCardBody,
  MDBCollapseHeader,
  MDBIcon,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBSpinner,
  MDBFileInput,
  MDBSelect,
  MDBTooltip,
  MDBModal,
  MDBModalHeader,
  MDBModalBody
} from "mdbreact";

import MDBFileupload from 'mdb-react-fileupload';

import { Editor } from '@tinymce/tinymce-react';


import CommentActions from "./actions/CommentActions";

import moment from 'moment';
import 'moment/locale/de';


class CommentFormInline extends Component {

  constructor(props) {
    super(props);
    this.state = {
      subject: '',
      comment_body: '',
      field_important: '0',
      entity_type: this.props.entityType,
      entity_id: this.props.entityId,
      field_name: this.props.fieldName,
      comment_type: this.props.commentType,
      loaded: true,
    }


    this._onSubmit = this._onSubmit.bind(this);
    this._onInputChange = this._onInputChange.bind(this);
    this._onFormReset = this._onFormReset.bind(this);
  }

  _onSubmit(e) {
    var component = this;
    e.preventDefault();

    component.props.searchComponent.setState(prevState => ({
      loaded: false,
    }))

    let values = {
      subject: this.state.subject,
      comment_body: this.state.comment_body,
      field_important: this.state.field_important,
      entity_type: this.props.entityType,
      entity_id: this.props.entityId,
      field_name: this.props.fieldName,
      comment_type: this.props.commentType,
    }

    CommentActions._post(values)
      .then(response => {

        component.props.searchComponent.loadCommentCollection();
        component._onFormReset();
      });
  }

  _onInputChange(e) {
    //console.log(e);
    if(e.target.checked) {
      this.setState({
        [e.target.name]: e.target.checked
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  }

  _onFormReset() {

    this.setState(prevState => ({
      loaded: true,
      subject: '',
      comment_body: '',
      field_important: '0',
      entity_type: this.props.entityType,
      entity_id: this.props.entityId,
      field_name: this.props.fieldName,
      comment_type: this.props.commentType,
    }))
  }



  render() {
    let component = this;
    return (

      <>



      <form id="comment-add-form" onSubmit={this._onSubmit}>

            <MDBInput outline label="Titel" name="subject" id="subject" onChange={this._onInputChange} value={this.state.subject} required />
            <MDBInput outline label="Kommentar" name="comment_body" type="textarea" id="comment_body" onChange={this._onInputChange} value={this.state.comment_body} required />

            {this.props.showImportant &&
              <MDBInput outline label="Als wichtig markieren" type="checkbox" id="field_important" name="field_important" checked={(this.state.field_important == 1) ? true : false} value={this.state.field_important} onChange={this._onInputChange} />
            }


           <MDBRow>
             <MDBCol>
                 {/*<MDBBtn color="danger" onClick={this._onFormReset} className="mr-2"><MDBIcon icon="sync mr-2" /> Zurücksetzen</MDBBtn>*/}
                 <MDBBtn color="primary" type="submit"><MDBIcon far icon="save" className="mr-2" /> Speichern</MDBBtn>
             </MDBCol>
           </MDBRow>


      </form>

      </>


    );
  }
}

export default CommentFormInline;
