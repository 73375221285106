import React, { Component } from 'react';
import Pager from 'react-pager';
import Image from "../image/Image";
import PermissionHelper from "../../../helpers/PermissionHelper";
import axios from "axios";


import QRCode from "react-qr-code";

import PowerpayActions from './actions/PowerpayActions';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as AllLightIcons from '@fortawesome/pro-light-svg-icons'

import {
  MDBSpinner,
  MDBTable,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBLink,
  MDBBtn,
  MDBIcon,
  MDBCardImage
} from 'mdbreact';

import MDBFileupload from 'mdb-react-fileupload';

import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';



import config from '../../../config';

/** import styles **/
import '../../../assets/css/sections/powerpay.css';

class PowerpayDistribute extends Component {

  constructor(props) {
    super(props);
    this.state = {
      qrcode: '',
      standaloneurl: '',
      powerpayActive: false,
      stream: null,
      IDFrontImage: null,
      IDBackImage: null
    }

    this.checkPaymentInterval = null;

    this._onDistribute = this._onDistribute.bind(this);
    this._onClose = this._onClose.bind(this);
    this._onHandleTakePhoto = this._onHandleTakePhoto.bind(this);
    this._onHandleTakePhotoAnimationDone = this._onHandleTakePhotoAnimationDone.bind(this);
    this._onHandleCameraError = this._onHandleCameraError.bind(this);
    this._onHandleCameraStart = this._onHandleCameraStart.bind(this);
    this._onHandleCameraStop = this._onHandleCameraStop.bind(this);
  }

  componentDidMount(){
  }

  _onDistribute = event => {
    var component = this;
    console.log(event);

    var order = component.props.checkout.state.order;

    var values = {
      amount: component.props.checkoutPayment.state.data.field_amount,
      order_id: order.node.nid[0].value
    }

    PowerpayActions._post(values)
      .then(response => {

        console.log(response);

        order.powerpay = true;
        order.powerpayIDFront = this.state.IDFrontImage;
        order.powerpayIDBack = this.state.IDBackImage;
        order.cashier = localStorage.getItem('user');

        order.orderTransactionId = response.data.message;
        console.log(order);

        component.setState(prevState => ({
          powerpayActive: true
        }));

        if(component.props.checkout.props.application.streamRef.current.state.presentationConnection) {
          var result = component.props.checkout.props.application.streamRef.current.state.presentationConnection.send(JSON.stringify(order));
          console.log(result);
        }


        this.checkPaymentInterval = setInterval(function(){

          var filters = {
            method: 'checkState'
          }


          PowerpayActions._get(response.data.message, filters)
            .then(response => {
              if(response.data.message == 'COMPLETED') {
                component.props.checkoutPayment._onToggleModal();
                component.props.checkout._onUpdateOrder(component.props.checkout.state.order.nid);
                clearInterval(component.checkPaymentInterval);
              }

              if(response.data.message == 'REFUSED') {
                component.props.checkoutPayment._onToggleModal();
                component.props.checkout._onUpdateOrder(component.props.checkout.state.order.nid);
                clearInterval(component.checkPaymentInterval);
              }
            }).catch(error => {
              clearInterval(component.checkPaymentInterval);
              component._onClose();
              throw error;
            })

          ; }, 4000);


      })




    /*
    PowerpayActions._post(values)
      .then(response => {
        console.log(response);
      })

    var order = component.props.checkout.state.order;
    order.powerpay = true;
    console.log(order);
    if(component.props.checkout.props.application.streamRef.current.state.presentationConnection) {
      component.props.checkout.props.application.streamRef.current.state.presentationConnection.send(JSON.stringify(order));
    }*/
  }

  _onClose = event => {
    var component = this;
    console.log(event);


    clearInterval(this.checkPaymentInterval);

    component.setState(prevState => ({
      powerpayActive: false
    }));

    var order = component.props.checkout.state.order;
    order.powerpay = false;
    console.log(order);
    if(component.props.checkout.props.application.streamRef.current.state.presentationConnection) {
      component.props.checkout.props.application.streamRef.current.state.presentationConnection.send(JSON.stringify(order));
    }

    /*
    PowerpayActions._post(values)
      .then(response => {
        console.log(response);
      })

    var order = component.props.checkout.state.order;
    order.powerpay = true;
    console.log(order);
    if(component.props.checkout.props.application.streamRef.current.state.presentationConnection) {
      component.props.checkout.props.application.streamRef.current.state.presentationConnection.send(JSON.stringify(order));
    }*/
  }

  _onCameraClose() {
    //this.state.stream.getTracks().forEach((track) => track.stop());
  }

  _onHandleTakePhoto (dataUri, name) {
    // Do stuff with the photo...
    this.setState(prevState => ({
      [name]: dataUri
    }));
    console.log('takePhoto');
   }

  _onHandleTakePhotoAnimationDone (dataUri, name) {
   // Do stuff with the photo...
   this.setState(prevState => ({
     [name]: dataUri,
     cameraBack: false,
     cameraFront: false
   }));
   console.log('takePhoto');
  }

  _onHandleCameraError (error) {
   console.log('handleCameraError', error);
  }

  _onHandleCameraStart (stream) {
   console.log('handleCameraStart');
  }

  _onImageDelete (name) {
    this.setState(prevState => ({
      [name]: null
    }));
    console.log('_onImageDelete');
  }

  _onEnableCamera (name) {
    this.setState(prevState => ({
      cameraBack: false,
      cameraFront: false
    }));


    this.setState(prevState => ({
      [name]: true
    }));
    console.log('_onImageDelete');
  }

  _onHandleCameraStop () {
   console.log('handleCameraStop');
  }

  render() {
    let component = this;
    return (
      <section id="powerpay-distribute">
        <hr />

        {!component.props.checkout.state.order.customer &&
          <>Bitte Kunden auswählen</>
        }

        {!this.state.powerpayActive && component.props.checkout.state.order.customer &&
            <>
              <h3>Ausweissbilder</h3>
              <div className="row">
                <div className="col-md-6">
                  <h4>Vorderseite</h4>
                    {this.state.IDFrontImage != null &&
                      <>
                        <img src={this.state.IDFrontImage} className="img-fluid" />
                        <hr />
                        <MDBBtn id="delete-button" color="danger" onClick={() => { this._onImageDelete('IDFrontImage') }}>
                          <MDBIcon icon="times" />
                          löschen
                        </MDBBtn>
                      </>
                    }
                    {this.state.cameraFront && this.state.IDFrontImage == null &&
                      <>
                      <Camera
                        onTakePhoto = { (dataUri) => { this._onHandleTakePhoto(dataUri, 'IDFrontImage'); } }
                        onTakePhotoAnimationDone = { (dataUri) => { this._onHandleTakePhotoAnimationDone(dataUri, 'IDFrontImage'); } }
                        onCameraError = { (error) => { this._onHandleCameraError(error); } }
                        idealFacingMode = {FACING_MODES.ENVIRONMENT}
                        idealResolution = {{width: 1024, height: 960}}
                        imageType = {IMAGE_TYPES.PNG}
                        imageCompression = {1}
                        isMaxResolution = {true}
                        isImageMirror = {false}
                        isSilentMode = {false}
                        isDisplayStartCameraError = {true}
                        isFullscreen = {false}
                        sizeFactor = {1.5}
                        onCameraStart = { (stream) => { this._onHandleCameraStart(stream); } }
                        onCameraStop = { () => { this._onHandleCameraStop(); } }
                      />
                      </>
                    }
                    {!this.state.cameraFront && !this.state.cameraBack && this.state.IDFrontImage == null &&
                      <>
                      <MDBBtn className="camera-btn" onClick={() => { this._onEnableCamera('cameraFront') }}>
                        <FontAwesomeIcon icon={AllLightIcons.faCamera} />
                      </MDBBtn>
                      </>
                    }
                </div>
                <div className="col-md-6">
                  <h4>Rückseite</h4>
                    {this.state.IDBackImage != null &&
                      <>
                        <img src={this.state.IDBackImage} className="img-fluid" />
                        <hr />
                        <MDBBtn id="delete-button" color="danger" onClick={() => { this._onImageDelete('IDBackImage') }}>
                          <MDBIcon icon="times" />
                          löschen
                        </MDBBtn>
                      </>
                    }

                    {this.state.cameraBack && this.state.IDBackImage == null &&
                      <>
                      <Camera
                        onTakePhoto = { (dataUri) => { this._onHandleTakePhoto(dataUri, 'IDBackImage'); } }
                        onTakePhotoAnimationDone = { (dataUri) => { this._onHandleTakePhotoAnimationDone(dataUri, 'IDBackImage'); } }
                        onCameraError = { (error) => { this._onHandleCameraError(error); } }
                        idealFacingMode = {FACING_MODES.ENVIRONMENT}
                        idealResolution = {{width: 1024, height: 960}}
                        imageType = {IMAGE_TYPES.PNG}
                        imageCompression = {1}
                        isMaxResolution = {true}
                        isImageMirror = {false}
                        isSilentMode = {false}
                        isDisplayStartCameraError = {true}
                        isFullscreen = {false}
                        sizeFactor = {1.5}
                        onCameraStart = { (stream) => { this._onHandleCameraStart(stream); } }
                        onCameraStop = { () => { this._onHandleCameraStop(); } }
                      />
                      </>
                    }

                    {!this.state.cameraFront && !this.state.cameraBack && this.state.IDBackImage == null &&
                      <>
                      <MDBBtn className="camera-btn" onClick={() => { this._onEnableCamera('cameraBack') }}>
                        <FontAwesomeIcon icon={AllLightIcons.faCamera} />
                      </MDBBtn>
                      </>
                    }
                </div>
              </div>


              {this.state.IDBackImage && this.state.IDBackImage &&
                <>
                  <hr />
                  <div className="powerpay-form-actions">
                    <MDBBtn id="distribute-button" color="primary" onClick={this._onDistribute}>
                      <MDBIcon far icon="save" className="mr-2" />
                      Kundendisplay aktivieren
                    </MDBBtn>
                  </div>
                </>
              }

          </>
        }
        {this.state.powerpayActive &&
          <section id="powerpay-waiting">
            <h1>Warte bist Zahlung akzeptiert wurde</h1>
            <div className="text-center"><MDBSpinner key={'powerpay-loader'} red /></div>
            <hr />
            <MDBBtn id="distribute-button" color="danger" onClick={this._onClose}>
              <MDBIcon icon="times" className="mr-2" />
              Zahlung Abbrechen
            </MDBBtn>
          </section>
        }
      </section>
    );
  }
}

export default PowerpayDistribute;
