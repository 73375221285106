import React, { Component } from 'react';


/** import config **/
import config from '../../../config';
import { MDBContainer, MDBRow, MDBCol, MDBStepper, MDBStep, MDBBtn, MDBInput, MDBSpinner, MDBCard, MDBCardBody, MDBModal, MDBModalHeader, MDBModalBody, MDBIcon } from "mdbreact";
import { connect } from 'react-redux';
import { withTranslation, initReactI18next } from "react-i18next";


import ReactPlayer from 'react-player/lazy';

import Totals from '../totals/Totals';

import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

//import PowerpayDisplay from '../powerpay/PowerpayDisplay';

import KlarnaCustomerDisplay from '../checkout/klarna/KlarnaCustomerDisplay';

import CustomerDisplayItem from "./CustomerDisplayItem";
import CheckoutTotals from '../checkout/CheckoutTotals';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as AllLightIcons from '@fortawesome/pro-light-svg-icons'

import userActions from '../../../actions/userActions';

import Logo from '../../../assets/images/logoTop.svg' // relative path to image

import LogoSuccess from '../../../assets/images/logoSuccess.svg' // relative path to image


/** import styles **/
import '../../../assets/css/sections/dashboard.css';
import '../../../assets/css/sections/customerdisplay.css';




class CustomerDisplay extends Component {

  constructor(props) {
    super(props);

    this.state = {
      connectionIdx: 0,
      messageIdx: 0,
      order: null,
      connection: null,
    }

    this.particlesInit = async (main) => {
      console.log(main);

      // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
      // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
      // starting from v2 you can add only the features you need reducing the bundle size
      await loadFull(main);
    };

    this.addConnection = this.addConnection.bind(this);
 }

  componentDidMount() {
    let component = this;


    if (navigator.presentation.receiver) {
      navigator.presentation.receiver.connectionList.then(list => {
        list.connections.map(connection => this.addConnection(connection));
        list.addEventListener('connectionavailable', function(event) {

          component.props.application.streamRef.current.setState(prevState =>({
            connected:true
          }))


          console.log(component.props.application.streamRef);

          this.addConnection(event.connection);



        });
      });
    }
  }


  addConnection(connection) {
    let component = this;
    let distributionView;
    connection.addEventListener('message', function(event) {


      connection.send('Received message ' + 'test');

      console.log(event);

      let result = connection.send(event);



      component.setState({
        order: false,
        connection: connection
      }, function(e) {
        let order = JSON.parse(event.data);
        component.setState(prevState =>({
          order:order
        }))
      })





      return result;
    });

    connection.addEventListener('close', function(event) {
      console.log(event);
    });


  };


  render() {



    return (
      <section id="customerdisplay">



        <Particles id="particles-js"
          init={this.particlesInit}
          options={{
              particles: {
                  number: {
                      value: 30,
                      density: {
                          enable: true,
                          value_area: 1000
                      }
                  },
                  color: {
                      value: '#fff'
                  },
                  opacity: {
                      value: 0.5,
                      anim: {
                          enable: true
                      }
                  },
                  size: {
                      value: 65,
                      random: true,
                      anim: {
                          enable: true,
                          speed: 8
                      }
                  },
                  line_linked: {
                      enable: false
                  },
                  move: {
                        directions: "none",
                        enable: true,
                        outModes: {
                            default: "bounce",
                        },
                        random: false,
                        speed: 1.4,
                        straight: false,
                    },
               }
          }}
        />



        {this.state.order && this.state.order.totals.due <= 0 && this.state.order.totals.total > 0 &&
          <MDBContainer className="success-modal">
            <MDBModal isOpen={true} className="text-center">
              <MDBModalBody>


                <div class="logo-bar">
                  <img src={LogoSuccess} className="img-fluid" />
                </div>

                <div className="icon-bar">
                  <FontAwesomeIcon icon={AllLightIcons.faCheckCircle} />
                </div>

                <h1>Vielen Dank für Ihren Einkauf und auf Wiedersehen</h1>

                <h2>Thank you for your purchase and goodbye</h2>
                <h2>Grazie per il tuo acquisto e arrivederci</h2>
                <h2>Merci pour votre achat et au revoir</h2>


              </MDBModalBody>
            </MDBModal>
          </MDBContainer>
        }



        {this.state.order &&
          <div className="details-bar">
          <div className="row">
            <div className="col-md-8">
              <MDBCard className="w-100">
                <MDBCardBody>
                  <table className="w-100 table">
                    <thead>
                      <tr>
                        <th className="hide">
                          Bild
                        </th>
                        <th>
                          Name
                        </th>
                        <th>
                          Menge
                        </th>
                        <th>
                          Preis
                        </th>
                        <th className="text-right">
                          Gesamt
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.order && (
                        Object.entries(this.state.order.orderItems).map(function(row, j){
                          console.log(row)
                          return (
                            <CustomerDisplayItem result={row[1].node} discountItems={(row[1].discountItems) ? row[1].discountItems : {}}  key={'checkout-items-view-' + row[1].node.nid + row[1].node.changed} />
                          )
                        })
                      )}
                    </tbody>
                  </table>
                </MDBCardBody>
              </MDBCard>
            </div>
            <div className="col-md-4">

                  {this.state.order && (
                    <Totals order={this.state.order} />
                  )}

            </div>
          </div>

          <div className="logo-bar">
            <img alt="" src={Logo} className="img-fluid" />
          </div>

          </div>
        }


        {!this.state.order &&
          <div class="standby-bar">
          <div className="logo-bar">
            <img alt="" src={Logo} className="img-fluid" />
          </div>
          {/*<video controls="" autoplay="" loop name="media"><source src="https://data.ammedia.ch/Venus-Beauty/Marketing/VB-Covid-Posting.mp4" type="video/mp4" /></video>*/}
        </div>

        }

        {this.state.order && this.state.order.klarnaResponse &&
          <KlarnaCustomerDisplay customerDisplay={this} />
        }


      </section>
    );
  }
}

export default CustomerDisplay;
