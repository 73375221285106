import React, { Component } from 'react';
import {
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBRow,
  MDBListGroup,
  MDBListGroupItem,
  MDBBadge,
  MDBIcon,
  MDBCardTitle,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBTabContent,
  MDBTabPane,
  MDBSpinner
} from 'mdbreact';
import { Bar, Pie, Line } from 'react-chartjs-2';

class WeeklyStats extends Component {

  constructor(props) {
    super(props);

    this.state = {
      activeItem: 0
    }

    this.toggle = this.toggle.bind(this);
 }


  componentDidMount() {
    var component = this;
    if(component.props.statistic.state.loaded) {
      this.setState({
        activeItem: 0
      });
    }
  }

  toggle = tab => () => {
      if (this.state.activeItem !== tab) {
      this.setState({
        activeItem: tab
      });
      }
    }

    render(){

      var component = this;

        const barChartOptions = {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
            xAxes: [{
                barPercentage: 1,
                gridLines: {
                display: true,
                color: 'rgba(0, 0, 0, 0.1)'
                }
            }],
            yAxes: [{
                gridLines: {
                display: true,
                color: 'rgba(0, 0, 0, 0.1)'
                },
                ticks: {
                beginAtZero: true
                }
            }]
            }
        }

        return (
            <MDBRow className={`mb-4 ${(component.props.statistic.state.loaded && Object.entries(component.props.statistic.state.result.week).length > 6) ? "hide" : ""}`}>
              <MDBCol md="12"className="mb-12">
                  <MDBCard className="mb-12">
                    <MDBCardBody>
                      <MDBCardTitle>Wochenverlauf</MDBCardTitle>
                    </MDBCardBody>
                      <MDBCardBody>

                        {!component.props.statistic.state.loaded &&
                          <MDBSpinner red />
                        }


                        <MDBNav tabs>
                          {component.props.statistic.state.loaded && Object.entries(component.props.statistic.state.result.week).length < 8 && (
                            Object.entries(component.props.statistic.state.result.week).map(function(row, i){

                              console.log(row);

                              return (
                                <MDBNavItem>
                                  <MDBNavLink
                                    link
                                    to="#"
                                    active={component.state.activeItem === i}
                                    onClick={component.toggle(i)}
                                    role="tab"
                                  >
                                  KW {row[0]}
                                  </MDBNavLink>
                                </MDBNavItem>
                              )

                            })
                            )}
                        </MDBNav>
                        <MDBTabContent
                          className="card"
                          activeItem={this.state.activeItem}
                        >

                        {component.props.statistic.state.loaded && Object.entries(component.props.statistic.state.result.week).length < 8 && (
                          Object.entries(component.props.statistic.state.result.week).map(function(row, j){


                            var dataBarTemp = {
                                labels: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
                                datasets: [
                                {
                                    label: 'Kunden',
                                    data: [row[1][0].customers, row[1][1].customers, row[1][2].customers, row[1][3].customers, row[1][4].customers, row[1][5].customers, row[1][6].customers],
                                    backgroundColor: '#005f89',
                                    borderWidth: 1
                                }, {
                                    label: 'Behandlungen',
                                    data: [row[1][0].treatments, row[1][1].treatments, row[1][2].treatments, row[1][3].treatments, row[1][4].treatments, row[1][5].treatments, row[1][6].treatments],
                                    backgroundColor: '#0093b7',
                                    borderWidth: 1
                                },{
                                    label: 'Termine',
                                    data: [row[1][0].appointments, row[1][1].appointments, row[1][2].appointments, row[1][3].appointments, row[1][4].appointments, row[1][5].appointments, row[1][6].appointments],
                                    backgroundColor: '#a0c5cf',
                                    borderWidth: 1
                                }
                                ]
                            };

                            return (
                              <MDBTabPane tabId={j} role="tabpanel">
                                <p className="mt-2">
                                  {component.state.activeItem == j &&
                                    <Bar data={dataBarTemp} height={500} options={barChartOptions} />
                                  }
                                </p>
                              </MDBTabPane>
                            )
                          })
                        )}


                      </MDBTabContent>



                      </MDBCardBody>
                  </MDBCard>
              </MDBCol>

            </MDBRow>
        )
    }
}

export default WeeklyStats;
