import React, { Component } from 'react';
import Pager from 'react-pager';
import CashRegisterSessionResultRow from "./CashRegisterSessionResultRow";
import CashRegisterSessionActions from "./actions/CashRegisterSessionActions";
import Image from "../../image/Image";
import CashRegisterSessionForm from "./CashRegisterSessionForm";
import PermissionHelper from "../../../../helpers/PermissionHelper";
import { Empty } from 'antd';

import {
  MDBSpinner,
  MDBTable,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBLink,
  MDBBtn,
  MDBIcon,
  MDBCardImage
} from 'mdbreact';

import config from '../../../../config';


class CashRegisterSessionSearch extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      result: [],
      page: 0,
      limit: 10,
      total: 0,
      visiblePage: 5,
      filters: {
        field_searchterm: null,
        field_cash_register: this.props.nid,
        field_is_completed: 0,
        field_sort: 'nfd.changed',
        field_sort_direction: 'desc',
        field_limit: 10
      }
    }

    this.formRef = React.createRef();
    this._onHandlePageChanged = this._onHandlePageChanged.bind(this);
  }

  _onHandlePageChanged(newPage) {
    this.setState({
        page: newPage
    }, () => {
        this.loadCashRegisterSessionCollection();
    });
  }

  componentDidMount(){
    this.loadCashRegisterSessionCollection();
  }

  loadCashRegisterSessionCollection() {
      var component = this;
      /** build filter query **/
      CashRegisterSessionActions._get('All', this.state.filters, this.state.page).then(response => {
        var result = [];
        if(response.data.result) {
          result = Object.entries(response.data.result);
        }

        component.setState({
          result: result,
          loaded: true,
          total: response.data.total
        });
      });
  }

  render() {
    let component = this;
    return (
      <>

        {PermissionHelper._hasPermission('restful post CashRegisterSession') === true && component.state.result && component.state.result.length == 0 &&
          <>
            <CashRegisterSessionForm searchComponent={this} ref={this.formRef} nid={this.props.nid} />
            <hr />
          </>
        }

        {!this.state.loaded &&
          <div className="text-center"><MDBSpinner key={'cashregistersession-loader'} red /></div>
        }

        {component.state.loaded && (
          <>
            {component.state.result.length > 0 && (
              <MDBTable responsive hover striped>
                <thead>
                  <tr>
                    <th>Sitzungsnummer</th>
                    <th>Eröffnet</th>
                    <th>läuft seit</th>
                    <th>Kassenstock</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {true && (
                    component.state.result.map(function(row, j){
                      //console.log(row)
                      return (
                        <CashRegisterSessionResultRow result={row[1]}  key={'cashregistersession-result-view-' + row[1].nid + row[1].changed} searchComponent={component} />
                      )
                    })
                  )}
                </tbody>
              </MDBTable>
            )}
            {component.state.result.length == 0 &&
              <div className="empty">
                <Empty />
              </div>
            }
          </>
        )}
      </>
    );
  }
}

export default CashRegisterSessionSearch;
