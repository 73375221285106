import React, { Component } from 'react';
import Pager from 'react-pager';
import SubscriptionResultRow from "./SubscriptionResultRow";
import SubscriptionActions from "./actions/SubscriptionActions";
import Image from "../image/Image";
import SubscriptionFilter from "./SubscriptionFilter";
import SubscriptionForm from "./SubscriptionForm";
import SubscriptionScan from "./SubscriptionScan";
import PermissionHelper from "../../../helpers/PermissionHelper";
import { Empty } from 'antd';



import {
  MDBSpinner,
  MDBTable,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBLink,
  MDBBtn,
  MDBIcon,
  MDBCardImage
} from 'mdbreact';

import config from '../../../config';

import '../../../assets/css/sections/subscription.css';

class SubscriptionSearch extends Component {

  constructor(props) {
    super(props);

    let selectedLocation = JSON.parse(localStorage.getItem('selectedLocation'));

    this.state = {
      selectedLocation: selectedLocation,
      loaded: false,
      result: [],
      page: 0,
      limit: 10,
      total: 0,
      collectionLoaded: false,
      visiblePage: 5,
      filters: {
        field_searchterm: '',
        field_sort: 'nfd.changed',
        field_sort_direction: 'desc',
        field_limit: 10
      },
      sortOptions: [
        {
          label: 'Geändert',
          value: 'nfd.changed'
        },
        {
          label: 'Titel',
          value: 'nfd.title'
        }
      ]
    }


    var subscriptionCategoryData = null;
    var taxRulesData = null;

    this.formRef = React.createRef();

    this._onHandlePageChanged = this._onHandlePageChanged.bind(this);
    //console.log(props);
  }

  _onHandlePageChanged(newPage) {
    this.setState({
        page: newPage
    }, () => {
        this.loadSubscriptionCollection();
    });
  }

  componentDidMount(){
    this.loadSubscriptionCollection();
    //console.log(this);
  }

  loadSubscriptionCollection() {


      var component = this;

      component.setState({
        collectionLoaded: false,
      });

      /** build filter query **/

      SubscriptionActions._get('All', this.state.filters, this.state.page).then(response => {

        var result = [];
        if(response.data.result) {
          result = Object.entries(response.data.result);
        }



        var treatmentTypeData = response.data.treatmentTypeData;


        var subscriptionCategoryData = response.data.subscriptionCategoryData;


        component.setState({
          result: result,
          collectionLoaded: true,
          total: response.data.total,
          subscriptionCategoryData: subscriptionCategoryData,
          treatmentTypeData: treatmentTypeData,
          loaded: true,
        });



      });
  }

  render() {
    let component = this;




    return (
      <>



      {!this.state.selectedLocation &&
        <>
          <>Bitte wähle zuerst eine Standort aus</>
        </>
      }


      {this.state.selectedLocation &&
        <>




      {PermissionHelper._hasPermission('restful post Subscription') === true && this.state.loaded &&
        <>
          <SubscriptionForm key={'subscription-add-form'} searchComponent={component} ref={this.formRef} />
          <hr />
        </>
      }

      {this.state.loaded &&
        <>
          <SubscriptionFilter searchComponent={component} />
          <SubscriptionScan searchComponent={component} />
          <hr />
        </>
      }



        {!this.state.loaded &&
          <div className="text-center"><MDBSpinner key={'subscription-loader'} red /></div>
        }

        {component.state.loaded && (
          <>

          {/*<SubscriptionFilter searchComponent={this} /><hr />*/}

            { component.state.result.length > 0 && (


              <MDBTable responsive hover striped className={`${!component.state.collectionLoaded ? "loading" : ""}`}>
                <thead>
                  <tr>
                    <th className='th-lg'>
                      Kartennummer
                    </th>
                    <th>
                      Typ
                    </th>
                    <th>Kunde</th>
                    <th>Mitarbeiter</th>
                    <th>
                      Behandlung
                    </th>
                    <th>
                      Notiz
                    </th>
                    <th>
                      Eingelöst
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {true && (
                    component.state.result.map(function(row, j){
                      //console.log(row)
                      return (
                        <SubscriptionResultRow result={row[1]}  key={'subscription-result-view-' + row[1].nid + row[1].changed} searchComponent={component} />
                      )
                    })
                  )}
                </tbody>
              </MDBTable>

            )}

            {component.state.result.length == 0 &&
              <div className="empty">
                <Empty />
              </div>
            }

            {component.state.loaded && component.state.total > 10 && (
              <>
                <hr />
                <Pager
                      total={Math.ceil(this.state.total / this.state.filters.field_limit)}
                      current={this.state.page}
                      visiblePages={this.state.visiblePage}
                      titles={{ first: '<|', last: '>|' }}
                      className="pagination-sm pull-right"
                      onPageChanged={this._onHandlePageChanged}
                  />
              </>
            )}
          </>
        )}

        </>
      }


      </>
    );
  }
}

export default SubscriptionSearch;
