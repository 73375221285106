import React, { Component } from 'react';
import Pager from 'react-pager';
import CreditMemoResultRow from "./CreditMemoResultRow";
import CreditMemoActions from "./actions/CreditMemoActions";
import Image from "../image/Image";
import CreditMemoFilter from "./CreditMemoFilter";
import CreditMemoForm from "./CreditMemoForm";
import PermissionHelper from "../../../helpers/PermissionHelper";


import { Empty } from 'antd';



import {
  MDBSpinner,
  MDBTable,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBLink,
  MDBBtn,
  MDBIcon,
  MDBCardImage
} from 'mdbreact';

import config from '../../../config';

/** import styles **/
import '../../../assets/css/sections/creditmemo.css';


class CreditMemoSearch extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      result: [],
      page: 0,
      limit: 10,
      total: 0,
      visiblePage: 5,
      filters: {
        field_order: this.props.orderId ? this.props.orderId :  null,
        field_sort: 'nfd.changed',
        field_sort_direction: 'desc'
      },
      sortOptions: [
        {
          label: 'Geändert',
          value: 'nfd.changed'
        },
        {
          label: 'Titel',
          value: 'nfd.title'
        }
      ]
    }

    this.formRef = React.createRef();
    this._onHandlePageChanged = this._onHandlePageChanged.bind(this);
  }

  _onHandlePageChanged(newPage) {
    this.setState({
        page: newPage
    }, () => {
        this.loadCreditMemoCollection();
    });
  }

  componentDidMount(){
    this.loadCreditMemoCollection();
  }

  loadCreditMemoCollection() {


      var component = this;

      /** build filter query **/

      CreditMemoActions._get('All', this.state.filters, this.state.page).then(response => {

        var result = [];
        if(response.data.result) {
          result = Object.entries(response.data.result);
        }



        component.setState({
          result: result,
          collectionLoaded: true,
          total: response.data.total,
          loaded: true,
        });



      });
  }

  render() {
    let component = this;




    return (
      <>

      {PermissionHelper._hasPermission('restful post CreditMemo') === true && this.state.loaded && false &&
        <>
          <CreditMemoForm key={'creditmemo-add-form'} searchComponent={component} ref={this.formRef} />
        </>
      }

      {this.state.loaded &&
        <>
          <CreditMemoFilter searchComponent={component} />
        </>
      }



        {!this.state.loaded &&
          <div className="text-center"><MDBSpinner key={'creditmemo-loader'} red /></div>
        }

        {component.state.loaded && (
          <>

          {/*<CreditMemoFilter searchComponent={this} /><hr />*/}

            {component.state.result.length > 0 && (


              <MDBTable responsive hover striped>
                <thead>
                  <tr>
                    <th className='th-lg'>
                      CreditMemoNr
                    </th>
                    <th className='th-lg'>
                      Notiz
                    </th>
                    <th className='th-lg'>
                      erstellt
                    </th>
                    <th className='th-lg'>
                      aktualisiert
                    </th>
                    <th className='th-lg'>
                      Betrag
                    </th>
                    <th className='th-lg'>
                      Artikel
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {true && (
                    component.state.result.map(function(row, j){
                      //console.log(row)
                      return (
                        <CreditMemoResultRow result={row[1]}  key={'creditmemo-result-view-' + row[1].nid + row[1].changed} searchComponent={component} />
                      )
                    })
                  )}
                </tbody>
              </MDBTable>

            )}

            {component.state.result.length == 0 &&
              <div className="empty">
                <Empty />
              </div>
            }

            {component.state.loaded && component.state.total > 10 && (
              <>
                <hr />
                <Pager
                      total={Math.ceil(this.state.total / this.state.limit)}
                      current={this.state.page}
                      visiblePages={this.state.visiblePage}
                      titles={{ first: '<|', last: '>|' }}
                      className="pagination-sm pull-right"
                      onPageChanged={this._onHandlePageChanged}
                  />
              </>
            )}
          </>
        )}
      </>
    );
  }
}

export default CreditMemoSearch;
