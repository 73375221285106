import React, { Component } from 'react';
import Pager from 'react-pager';
import OrderTransactionResultRow from "./OrderTransactionResultRow";
import OrderTransactionActions from "./actions/OrderTransactionActions";

import OrderTransactionFilter from "./OrderTransactionFilter";
import PermissionHelper from "../../../helpers/PermissionHelper";
import { Empty } from 'antd';

import {
  MDBSpinner,
  MDBTable,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBLink,
  MDBBtn,
  MDBIcon,
  MDBCardImage
} from 'mdbreact';

import config from '../../../config';


class OrderTransactionSearch extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      result: [],
      page: 0,
      limit: 10,
      total: 0,
      visiblePage: 5,
      filters: {
        field_entity: this.props.orderId ? this.props.orderId :  null,
        field_gift_card: this.props.giftCardId ? this.props.giftCardId :  null,
        field_subscription: this.props.subscriptionCardId ? this.props.subscriptionCardId :  null,
        field_sort: 'nfd.changed',
        field_sort_direction: 'desc'
      },
      sortOptions: [
        {
          label: 'Geändert',
          value: 'nfd.changed'
        },
        {
          label: 'Titel',
          value: 'nfd.title'
        }
      ]
    }

    this._onHandlePageChanged = this._onHandlePageChanged.bind(this);
  }

  _onHandlePageChanged(newPage) {
    this.setState({
        page: newPage
    }, () => {
        this.loadOrderTransactionCollection();
    });
  }

  componentDidMount(){
    this.loadOrderTransactionCollection();
  }

  loadOrderTransactionCollection() {


      var component = this;

      /** build filter query **/

      OrderTransactionActions._get('All', this.state.filters, this.state.page).then(response => {

        var result = [];
        if(response.data.result) {
          result = Object.entries(response.data.result);
        }

        component.setState({
          result: result,
          collectionLoaded: true,
          total: response.data.total,
          loaded: true,
        });
      });
  }

  render() {
    let component = this;




    return (
      <>





        {!this.state.loaded &&
          <div className="text-center"><MDBSpinner key={'ordertransaction-loader'} red /></div>
        }

        {component.state.loaded && (
          <>

          {/*<OrderTransactionFilter searchComponent={this} /><hr />*/}

            {component.state.result.length > 0 && (


              <MDBTable responsive hover striped>
                <thead>
                  <tr>
                    <th className='th-lg'>
                      Transaktionsnummer
                    </th>
                    <th className='th-lg'>
                      erstellt
                    </th>
                    <th className='th-lg'>
                      aktualisiert
                    </th>
                    <th className='th-lg'>
                      Zahlungsart
                    </th>
                    <th className='th-lg'>
                      Betrag
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {true && (
                    component.state.result.map(function(row, j){
                      //console.log(row)
                      return (
                        <OrderTransactionResultRow result={row[1]}  key={'ordertransaction-result-view-' + row[1].nid + row[1].changed} searchComponent={component} />
                      )
                    })
                  )}
                </tbody>
              </MDBTable>

            )}

            {component.state.result.length == 0 &&
              <div className="empty">
                <Empty />
              </div>
            }

            {component.state.loaded && component.state.total > 10 && (
              <>
                <hr />
                <Pager
                      total={Math.ceil(this.state.total / this.state.limit)}
                      current={this.state.page}
                      visiblePages={this.state.visiblePage}
                      titles={{ first: '<|', last: '>|' }}
                      className="pagination-sm pull-right"
                      onPageChanged={this._onHandlePageChanged}
                  />
              </>
            )}
          </>
        )}
      </>
    );
  }
}

export default OrderTransactionSearch;
