import React, {
  useState,
  useEffect
} from 'react';

import {
  MDBLink,
  MDBBtn,
  MDBIcon,
  MDBSpinner,
  MDBPopover,
  MDBPopoverBody,
  MDBPopoverHeader,
  MDBInput,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBModal,
  MDBModalBody,
  MDBRow,
  MDBCol,
  MDBModalFooter,
  MDBAvatar
} from 'mdbreact';

import Image from "../../image/Image";

import NumberFormat from 'react-number-format';


import CustomerActions from "./actions/CustomerActions";
import CustomerForm from "./CustomerForm";

import moment from 'moment';
import 'moment/locale/de';

import PermissionHelper from "../../../../helpers/PermissionHelper";



class CustomerResultRow extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false
    };

    this._onDelete = this._onDelete.bind(this);
    this._onModalToggle = this._onModalToggle.bind(this);
  }

  _onModalToggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  _onDelete(e) {
    var component = this;

    const r = window.confirm("Löschen Bestätigen");
    if (r === true) {
      CustomerActions._delete(this.props.result.nid)
        .then(response => {
          component.props.searchComponent.formRef.current._onFormReset();
          component.props.searchComponent.loadCustomerCollection();
        });
    }
  }

  render() {
    return (
      <tr className="customer-item" key={'customer-' + this.props.result.nid + this.props.result.changed}>

        <td>{(this.props.result.node.field_customer_number[0] ? this.props.result.node.field_customer_number[0].value : '')}</td>
        <td>{(this.props.result.node.field_firstname[0] ? this.props.result.node.field_firstname[0].value : '')}</td>
        <td>{(this.props.result.node.field_lastname[0] ? this.props.result.node.field_lastname[0].value : '')}</td>
        <td>
          {(this.props.result.node.field_mobile[0] ? <NumberFormat displayType={'text'} format="### ### ## ##" mask="_" value={this.props.result.node.field_mobile[0].value}/> : '')}
        </td>
        <td>{(this.props.result.node.field_city[0] ? this.props.result.node.field_city[0].value : '')}</td>
          <td>
              {
                this.props.result.locations.map(function(item, i){
                  //console.log(item);
                  return <span key={i}><span>{item.title[0].value}</span></span>
                })
              }
          </td>
        <td>
          <div className="action-bar">

            {PermissionHelper._hasPermission('edit any customer content') === true &&
              <>
                <CustomerForm key={'customer-edit-form-' + this.props.result.nid + this.props.result.changed} entity={this.props.result.node} locations={this.props.result.locations} genders={this.props.searchComponent.state.genders} searchComponent={this.props.searchComponent} channelOptions={this.props.searchComponent.state.channelOptions} genderFilters={this.props.searchComponent.state.genderFilters} locationsFilter={this.props.searchComponent.state.locations} />
              </>
            }

            <MDBBtn color="black" onClick={() => this.props.searchComponent.props.checkoutCustomer._onSelect(this.props.result.node.nid[0].value)}>
              <MDBIcon far icon="check-circle" />
            </MDBBtn>
          </div>
        </td>
      </tr>
    );
  }
}

export default CustomerResultRow;
