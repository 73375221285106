import React, { Component } from 'react';
import PermissionHelper from "../../../helpers/PermissionHelper";
import ReactToPrint from 'react-to-print';

import Receipt from "../receipt/Receipt";

import {
  MDBSpinner,
  MDBTable,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBLink,
  MDBBtn,
  MDBIcon,
  MDBCardImage,
  MDBContainer,
  MDBModal,
  MDBModalHeader,
  MDBModalBody
} from 'mdbreact';

import Countdown from 'react-countdown';

import Currency from 'react-currency-formatter';

import moment from 'moment';
import 'moment/locale/de';

import config from '../../../config';


class Totals extends Component {

  constructor(props) {
    super(props);
    this.state = {
      subtotal: 0,
      total: 0,
      discount: 0,
      tax: 0,
    }
  }


  componentDidMount(){
    var component = this;
  }


  render() {
    let component = this;

    return (
      <section id="totals">

        <MDBCard>
          <MDBCardBody>

            <MDBRow>
              <MDBCol sm="9">
                <strong>Zwischensumme</strong>
              </MDBCol>
              <MDBCol sm="3">
                {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'CHF' }).format(this.props.order.totals.subtotal)}
              </MDBCol>
            </MDBRow>
            <hr />
            <MDBRow>
              <MDBCol sm="9">
                <strong>Steuer</strong>
              </MDBCol>
              <MDBCol sm="3">
                {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'CHF' }).format(this.props.order.totals.tax)}
              </MDBCol>
            </MDBRow>
            <hr />


        {this.props.order.discountItems.length > 0 &&
          <>
          <MDBRow>
            <MDBCol sm="9">
              <strong>Rabatt</strong>
            </MDBCol>
            <MDBCol sm="3">

              {this.props.order.discountItems[0].body[0].value} ({this.props.order.discountItems[0].field_discount_value[0].value} {(this.props.order.discountItems[0].field_discount_percent[0].value) ? '%' : 'CHF'})

            </MDBCol>
          </MDBRow>
          <hr />
          </>
        }

            <MDBRow>
              <MDBCol sm="9">
                <strong>Gesamt</strong>
              </MDBCol>
              <MDBCol sm="3">
                {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'CHF' }).format(this.props.order.totals.total)}

                {this.props.order.totals.total != this.props.order.totals.totalNoDiscount &&
                  <span><br />statt {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'CHF' }).format(this.props.order.totals.totalNoDiscount)}</span>
                }

              </MDBCol>
            </MDBRow>
            <hr />

            <MDBRow>
              <MDBCol sm="9">
                <strong>Beglichener Betrag</strong>
              </MDBCol>
              <MDBCol sm="3">
                {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'CHF' }).format(this.props.order.totals.paid)}
              </MDBCol>
            </MDBRow>




            {this.props.order.totals.due > 0 &&
              <>
              <hr />
              <MDBRow>
                <MDBCol sm="9">
                  <strong>Offener Betrag</strong>
                </MDBCol>
                <MDBCol sm="3">
                  {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'CHF' }).format(this.props.order.totals.due)}
                </MDBCol>
              </MDBRow>
              </>
            }

            {this.props.order.totals.due < 0 &&
              <>
              <hr />
              <MDBRow>
                <MDBCol sm="9">
                  <strong>Wechselgeld</strong>
                </MDBCol>
                <MDBCol sm="3">
                  {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'CHF' }).format(this.props.order.totals.due)}
                </MDBCol>
              </MDBRow>
              </>
            }

          </MDBCardBody>
        </MDBCard>
      </section>
    );
  }
}


export default Totals;
