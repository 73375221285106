
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { withRouter } from "react-router";
import { Login } from './components/modules/login/Login'
import AlertComponent from './components/AlertComponent'
import JwtComponent from './components/JwtComponent'
import { AutoLogout } from './components/AutoLogout'
import StreamComponent from './components/StreamComponent'
import { history } from './helpers/history';
import { connect } from 'react-redux';
import userActions from './actions/userActions';
import RoutesWithNavigation from './components/RoutesWithNavigation';
import UpdateNotification from './components/modules/updater/UpdateNotification';

import { Offline, Online } from "react-detect-offline";
import OfflineImage from './assets/images/offline.gif' // relative path to image


import {
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBSpinner,
  MDBIcon
} from 'mdbreact';


import CustomerDisplay from './components/modules/customerdisplay/CustomerDisplay';
import moment from 'moment-timezone';



class App extends React.Component {
  constructor(props) {
    super(props);


    var url = window.location.href
    var arr = url.split("/");
    var result = arr[0] + "//" + arr[2]

    this.polling = {
      enabled: true,
      url: result,
      interval: 15000,
      timeout: 15000
    };

    moment.tz.setDefault('Etc/Utc');

    //console.log(props);
    if(window.location.pathname != '/pages/login') {
      this.props.checkSession(this.props.user);
    }




    this._handleContextMenu = this._handleContextMenu.bind(this);
    this._handleClick = this._handleContextMenu.bind(this);
    this._handleScroll = this._handleContextMenu.bind(this);

    // Listen to history changes.
    // You can unlisten by calling the constant (`unlisten()`).



  }

  componentDidUpdate(prevProps) {
    if (prevProps.user !== this.props.user) {
      //console.log('user changed');
    }
  }

  componentDidMount() {
    let component = this;



    this.alertRef = React.createRef();
    this.streamRef = React.createRef();

    const unlisten = history.listen((location, action) => {
      if(component && component.streamRef && component.streamRef.current && component.streamRef.current.state.presentationConnection) {
        component.streamRef.current.state.presentationConnection.send(null);
      }

      //ReactGA.pageview(window.location.pathname + window.location.search);
    });





    if(!localStorage.getItem('lang')) {
      localStorage.setItem('lang', 'de');
    }

    document.addEventListener('contextmenu', this._handleContextMenu);

    //console.log(this.props);
    /** set session active **/
    this.sessionCheckInterval = setInterval(function() {
      if(window.location.pathname != '/pages/login') {
        component.props.checkSession(component.props.user);
      }
    }, 360 * 1000);
  }

  componentWillUnmount() {
    document.removeEventListener('contextmenu', this._handleContextMenu);
  }

  _handleContextMenu = (event) => {
    //event.preventDefault();
  };

  render() {
    return (
      <>

          <JwtComponent application={this} />

          <AutoLogout application={this} />

          {typeof PresentationRequest != "undefined" &&
            <StreamComponent ref={this.streamRef} />
          }


          <Switch history={history}>
            <Route path='/pages/login' exact render={(props) => <Login {...props} application={this} />} />
            <Route path='/customerdisplay' exact component={CustomerDisplay} application={this} />
            <RoutesWithNavigation application={this} />
          </Switch>


          <AlertComponent ref={this.alertRef} application={this} />
          <UpdateNotification alert={this.alertRef} />


          <Offline polling={this.polling}>
            <div className="offline-bar">
              <MDBModal size="lg" isOpen={true}>
               <MDBModalBody>
                 <img src={OfflineImage} className="img-fluid" />
               </MDBModalBody>
             </MDBModal>
            </div>
         </Offline>

         <img src={OfflineImage} className="img-fluid hide" />
      </>
    );
  }
}

function mapState(state) {
    const { authenticationReducer } = state;
    const { user } = authenticationReducer;
    return { user };
}


const actionCreators = {
  checkSession: userActions.checkSession
}

const connectedApp = connect(mapState, actionCreators)(App);
export { connectedApp as App };
