import React, {Component} from "react";
import {
  MDBContainer,
  MDBCollapse,
  MDBCard,
  MDBCardBody,
  MDBCollapseHeader,
  MDBIcon,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBSpinner,
  MDBFileInput,
  MDBSelect,
  MDBTooltip,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBAlert
} from "mdbreact";

import Subscription from '../../../../assets/images/qrCard.png' // relative path to image

import SubscriptionActions from "./actions/SubscriptionActions";

import QrReader from 'react-qr-reader'
import BarcodeReader from '../../barcodescanner/BarcodeScanner'
import { Modal } from 'antd';


import Select from 'react-select';


class SubscriptionForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: {
        field_card_secret_key: ''
      }
    }


    this._onSubmit = this._onSubmit.bind(this);
    this.handleScan = this.handleScan.bind(this);
  }

  componentDidMount() {
  }

  _onToggleModal = () => {
    this.setState({
      modal: !this.state.modal
    });
  }


  _onHandleScanError = error => {
    var component = this;
    console.error(error);
    if(error.name == "NotFoundError" || error.name == "NotAllowedError" || error.name == "OverconstrainedError") {
      var data = '0';
      alert(error);
    }
  }

  _onHandleScanSuccess = data => {
    var component = this;

    var formData = this.state.data;

    if(data == null) {
      //alert();
      return;
    }


    const dataParts = data.split('-');



    formData['field_card_secret_key'] = dataParts[1];

    component.setState({
      data: formData
    }, function() {
    });

  }


  handleScan(data){

    var component = this;

    var formData = this.state.data;

    data = data.replace("'", '-');
    data = data.replace("ß", '-');


    if(data == null) {
      return;
    }

    const dataParts = data.split("-");


    formData['field_card_secret_key'] = dataParts[1];

    component.setState({
      data: formData
    }, function() {
    });

  }
  handleError(err){
    console.error(err)
  }

  _onSubmit(e) {
    var component = this;
    e.preventDefault();

    if(component.state.hasSubmitted) {
      return;
    }


    let values = {
      nid: this.state.nid,
      field_card_secret_key: this.state.data.field_card_secret_key,
      field_customer: this.props.customer,
    }


    SubscriptionActions._patch(values)
      .then(response => {
        component.setState(prevState => ({
          hasSubmitted: false,
        }))
        component._onFormReset();
        component.props.searchComponent.loadSubscriptionCollection();
      }).catch(error => {
        console.log(error);
        component._onFormReset();
        component.props.searchComponent.loadSubscriptionCollection();
      });
  }


  _onFormReset() {

    var component = this;


    this.setState(prevState => ({
      hasSubmitted: false,
      loaded: true,
      nid: false,
      field_card_secret_key: '',
    }))
  }



  render() {
    let component = this;



    return (
      <>
      {!this.state.entity &&
        <>
          <MDBBtn onClick={this._onToggleModal} color="primary"><MDBIcon icon="plus mr-2" /> Abo verknüpfen</MDBBtn>
          <hr />
        </>
      }

      {this.state.entity &&
        <MDBBtn onClick={this._onToggleModal} color="primary"><MDBIcon far icon="edit" /></MDBBtn>
      }

      <Modal title="Abokarte scannen" destroyOnClose={true} visible={this.state.modal} onOk={this._onToggleModal} onCancel={this._onToggleModal} cancelButtonProps={{ style: { display: 'none' } }} okButtonProps={{ style: { display: 'none' } }}>

          <form id="subscription-add-form" onSubmit={this._onSubmit}>



              <MDBRow>
                <MDBCol>


                  {this.state.data.field_card_secret_key &&
                    <MDBAlert color="success" >
                      Abonement erkannt. Bitte speichern.
                    </MDBAlert>
                  }

                  <BarcodeReader
                    onError={this.handleError}
                    onScan={this.handleScan}
                    />

                      <img src={Subscription} className="img-fluid" />

                        {this.state.data.field_card_secret_key &&
                  <div className="form-action-bar">
      
                    <MDBBtn color="primary" type="submit" disabled={((this.state.hasSubmitted) || (this.state.field_channel == 29 && !this.state.field_selected_recommendation_customer)) ? true : false}><MDBIcon far icon="save" className="mr-2" /> Speichern</MDBBtn>
                  </div>
                }

                </MDBCol>
              </MDBRow>

          </form>
      </Modal>
      </>

    );
  }
}

export default SubscriptionForm;
