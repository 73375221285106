import React, { Component } from 'react';

import {
  MDBCard,
  MDBIcon,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
  MDBLink,
} from 'mdbreact';

import axios from 'axios';

import userActions from '../actions/userActions';

import { withTranslation, initReactI18next } from "react-i18next";

import { connect } from 'react-redux';

import { Beforeunload } from 'react-beforeunload';

import '../assets/css/sections/stream.css';


class StreamComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      presentationRequest: false,
      presentationConnection: false,
      connected: false
    }

    this.startButton = React.createRef();

    this._onStart = this._onStart.bind(this);
    this._onUnload = this._onUnload.bind(this);
  }


  componentDidMount() {
    let component = this;

  }


  _onUnload() {
    this.state.presentationConnection.terminate();
  }

  componentWillUnmount() {
  }


  _onStart() {
    let component = this;



    if(!this.state.presentationConnection) {

      const presentationRequest = new PresentationRequest(['/customerdisplay']);
      navigator.presentation.defaultRequest = presentationRequest;



      component.setState(prevState =>({
        presentationRequest:presentationRequest,
      }), function() {
        // Make this presentation the default one when using the "Cast" browser menu.
        navigator.presentation.defaultRequest = presentationRequest;

        let presentationConnection;

        this.state.presentationRequest.start()
        .then(connection => {
          console.log('> Connected to ' + connection.url + ', id: ' + connection.id);

          component.setState(prevState =>({
            presentationConnection:presentationConnection,
          }), function() {
            //connection.send(JSON.stringify(component.state.order));
            localStorage.setItem('presentationId', connection.id);
          })


        })
        .catch(error => {
          if(error.name == 'NotFoundError') {
            component.setState(prevState =>({
              connected: true
            }));
          }

          console.log('> ' + error.name + ': ' + error.message);
        });


        this.state.presentationRequest.addEventListener('connectionavailable', function(event) {
          presentationConnection = event.connection;
            console.log(event);

              component.setState(prevState =>({
                presentationConnection:presentationConnection,
                connected: true
              }), function() {
              })


          component.state.presentationConnection.addEventListener('close', function() {
            component.setState(prevState =>({
              connected: false
            }));
            console.log('> Connection closed.');
          });
          component.state.presentationConnection.addEventListener('terminate', function() {
            component.setState(prevState =>({
              connected: false
            }));
            console.log('> Connection terminated.');
          });
          component.state.presentationConnection.addEventListener('message', function(event) {
            console.log(event);
            return event.data;
            console.log('> ' + event.data);
          });
        });


        /* Availability monitoring */

        this.state.presentationRequest.getAvailability()
        .then(availability => {
          console.log('Available presentation displays: ' + availability.value);
          availability.addEventListener('change', function() {

            console.log('> Available presentation displays: ' + availability.value);
          });
        })
        .catch(error => {
          console.log('Presentation availability not supported, ' + error.name + ': ' +
              error.message);
        });
      })
    }
  }




  render() {
    return (
      <section id="stream-overview" className={`${this.state.connected ? "active" : ""}`}>
        <Beforeunload onBeforeunload={this._onUnload} />
        <MDBBtn color="primary" onClick={this._onStart}>
          <MDBIcon icon="tv" />
          Kundendisplay aktivieren
        </MDBBtn>
      </section>
    );
  }
}


export default StreamComponent;
