import React, { Component } from 'react';
import Pager from 'react-pager';
import Image from "../../image/Image";
import PermissionHelper from "../../../../helpers/PermissionHelper";
import axios from "axios";
import { Empty } from 'antd';


import QRCode from "react-qr-code";



import KlarnaActions from './actions/KlarnaActions';

import {
  MDBSpinner,
  MDBTable,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBLink,
  MDBBtn,
  MDBIcon,
  MDBCardImage
} from 'mdbreact';

import config from '../../../../config';


class KlarnaPayment extends Component {

  constructor(props) {
    super(props);
    this.state = {
      qrcode: '',
      standaloneurl: '',
      isActive: false,
      checkPaymentInterval: false
    }

    this._onDistribute = this._onDistribute.bind(this);
    this._onInitPayment = this._onInitPayment.bind(this);
    this._onExit = this._onExit.bind(this);
  }



  componentDidMount(){
  }

  _onInitPayment() {


    clearInterval(window.checkPaymentInterval);

    let component = this;

    let customer = this.props.checkoutPayment.props.checkout.state.order.customer;
    let selectedLocation = JSON.parse(localStorage.getItem('selectedLocation'));

    let order = component.props.checkout.state.order;

    console.log(order);


    let orderItems = order.orderItems;
    let discountOrderItems = order.discountItems;

    const orderLines = orderItems.map(function(item) {


      let orderItem = item.node;
      let discountItemItems = item.discountItems;
      console.log(discountItemItems);
      let product = item.product;
      let price = orderItem.field_price[0].value;
      let qty = orderItem.field_qty[0].value;
      let taxRate = orderItem.field_tax[0].value;
      let itemTotalNoDiscount = price*qty;
      let itemTotal = price*qty;

      discountItemItems.forEach((item) => {
        console.log(item);

        let discountValue = item.field_discount_value[0].value;
        let discountSubstract = 0;

        if(item.field_discount_percent[0].value) {
          discountValue = (itemTotal / 100) * discountValue;
        }

        itemTotal = itemTotal-discountValue;
      });


      discountOrderItems.forEach((item) => {
        console.log(item);

        let discountValue = item.field_discount_value[0].value;
        let discountSubstract = 0;

        if(item.field_discount_percent[0].value) {
          discountValue = (itemTotal / 100) * discountValue;
        } else {
          discountValue = discountValue / orderItems.length;
        }
        itemTotal = itemTotal-discountValue;
      });



      let taxTotal =  itemTotal - (itemTotal / ((taxRate / 100) + 1));





      let orderLine = {
        "type": "physical",
        "reference": orderItem.field_order_item_number[0].value,
        "name": product.title[0].value,
        "quantity": parseInt(qty),
        "unit_price": price.toFixed(2).replace('.', ''),
        "tax_rate": parseFloat(taxRate).toFixed(2).replace('.', ''),
        "total_amount": itemTotal.toFixed(2).replace('.', ''),
        "total_discount_amount": (itemTotalNoDiscount - itemTotal).toFixed(2).replace('.', ''),
        "total_tax_amount": taxTotal.toFixed(2).replace('.', ''),
        "image_url": "https://www.exampleobjects.com/logo.png",
        "product_url": "https://www.estore.com/products/f2a8d7e34"
      }

      return orderLine;
    });





    let values = {
      //'acquiring_channel': "IN_STORE",
      "merchant_reference1": order.node.field_order_number[0].value,
      "merchant_reference2": selectedLocation.title[0].value + " - " + JSON.parse(localStorage.getItem('selectedCashRegister')).field_cash_register_number[0].value,
      'purchase_country': "CH",
      'purchase_currency': "CHF",
      "locale": "de-CH",
      'auto_capture': true,
      "order_lines": orderLines,
      "order_amount": this.props.checkoutPayment.state.data.field_amount.toFixed(2).replace('.', ''),
      "order_tax_amount": order.totals.tax.toFixed(2).replace('.', ''),
      'options': {
        "disable_confirmation_modals": false
      },

      "billing_address": {
        "given_name": customer.field_firstname[0].value,
        "family_name": customer.field_lastname[0].value,
        "email": customer.field_email[0].value,
        //"title": "Mr",
        "street_address": customer.field_street[0].value,
        "street_address2": "",
        "postal_code": customer.field_zip[0].value,
        "city": customer.field_city[0].value,
        "phone": customer.field_mobile[0].value,
        "country": "CH",
      }
      /*
      'customer': {
        'date_of_birth': customer.field_birthday[0].value,
      }*/
    }


    console.log(values);



    /*
    let values = {
      "purchase_country": "CH",
      "purchase_currency": "CHF",
      "locale": "de-CH",
      "order_amount": 10,
      "order_tax_amount": 0,
      "order_lines": [{
        "type": "physical",
        "reference": "19-402",
        "name": "Battery Power Pack",
        "quantity": 1,
        "unit_price": 10,
        "tax_rate": 0,
        "total_amount": 10,
        "total_discount_amount": 0,
        "total_tax_amount": 0,
        "image_url": "https://www.exampleobjects.com/logo.png",
        "product_url": "https://www.estore.com/products/f2a8d7e34"
      }]
    }*/



    return KlarnaActions._post(values)
      .then(response => {
        console.log(response);

        response = JSON.parse(response.data.message);
        //response = response.data;
        console.log(response);


        response.values = values;
        order.cashier = localStorage.getItem('user');


        component.setState(prevState => ({
          klarnaResponse: response,
        }))

        component.state.checkPaymentInterval = setInterval(function(){

          if(!component.props.checkout.state.order) {
            clearInterval(component.state.checkPaymentInterval);
            return;
          }

          let values = {
            'method': 'checkPayment',
            'session_id': response.session_id,
            'order_id': component.props.checkout.state.order.nid,
            'amount': component.props.checkoutPayment.state.data.field_amount
          }



          KlarnaActions._post(values)
            .then(response => {
              console.log(response);

              if(response.data.message == 'COMPLETED') {
                component.props.checkoutPayment._onToggleModal();
                component.props.checkout._onUpdateOrder(component.props.checkout.state.order.nid);
                clearInterval(component.state.checkPaymentInterval);
                component.setState(prevState =>({
                  checkPaymentInterval:null
                }))

              }
            }).catch(error => {
              clearInterval(component.state.checkPaymentInterval);
              return error;
            })

          ; }, 10000);

        component.setState(prevState =>({
          checkPaymentInterval: component.state.checkPaymentInterval
        }))

        return response;
      }).catch(error => {
        clearInterval(component.state.checkPaymentInterval);
        return error;
      })

  }

  _onDistribute = event => {


    let component = this;
    console.log(event);

    component.setState(prevState =>({
      isActive: true
    }))


    this._onInitPayment().then(function(response){
      let order = component.props.checkout.state.order;
      order.klarnaResponse = component.state.klarnaResponse;
      console.log(order);
      if(component.props.checkout.props.application.streamRef.current.state.presentationConnection) {
        component.props.checkout.props.application.streamRef.current.state.presentationConnection.send(JSON.stringify(order));
      }
    });


  }

  _onExit = event => {

    let component = this;
    console.log(event);

    clearInterval(component.state.checkPaymentInterval);



    component.setState(prevState =>({
      isActive: false,
      checkPaymentInterval: false
    }))

    let order = component.props.checkout.state.order;
    order.klarnaResponse = null;

    if(component.props.checkout.props.application.streamRef.current.state.presentationConnection) {
      console.log(order);
      component.props.checkout.props.application.streamRef.current.state.presentationConnection.send(JSON.stringify(order));
    }
  }

  render() {
    let component = this;
    return (

      <>


      <hr />



        {!this.props.checkoutPayment.props.checkout.state.order.customer &&
          <><Empty description={'Bitte wählen einen Kunden aus'} /></>
        }

        {this.props.checkoutPayment.props.checkout.state.order.customer &&
          <>

          {!this.state.isActive &&
            <MDBBtn id="distribute-button" color="primary" onClick={this._onDistribute}>
              <MDBIcon icon="qrcode" /> QR Code anzeigen
            </MDBBtn>
          }

          {this.state.isActive &&
            <MDBBtn id="exit-button" color="danger" onClick={this._onExit}>
              <MDBIcon icon="times" />Abrechen
            </MDBBtn>
          }


          </>
        }






        </>

    );
  }
}

export default KlarnaPayment;
