import React, { Component } from 'react';
import Pager from 'react-pager';
import Image from "../../image/Image";
import PermissionHelper from "../../../../helpers/PermissionHelper";
import axios from "axios";

import QrReader from 'react-qr-reader'

import TerminalActions from "./actions/TerminalActions";
import TerminalSearch from "./TerminalSearch";



import {
  MDBSpinner,
  MDBTable,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBLink,
  MDBBtn,
  MDBIcon,
  MDBCardImage
} from 'mdbreact';

import config from '../../../../config';


class TerminalPayment extends Component {

  constructor(props) {
    super(props);


    this._onHandleTerminalPayment = this._onHandleTerminalPayment.bind(this);
    this._onHandleTerminalDisplay = this._onHandleTerminalDisplay.bind(this);
  }


  _onHandleTerminalPayment(terminal) {

    var component = this;

    var order = this.props.checkoutPayment.props.checkout.state.order.node;

    let values = {
      data: {
        method: 'payment',
        order: order,
        terminal: terminal,
        cashregister: JSON.parse(localStorage.getItem('selectedCashRegister')),
        amount: this.props.checkoutPayment.state.data.field_amount
      }
    }


    return TerminalActions._post(values)
      .then(response => {
        this.props.checkoutPayment._onToggleModal();
        this.props.checkoutPayment.props.checkout._onUpdateOrder(this.props.checkoutPayment.props.checkout.state.order.nid);
        console.log(response);
        return response;
      }).catch(error => {
        return error;
      })

  }

  _onHandleTerminalDisplay(terminal) {
    var component = this;

    var order = this.props.checkoutPayment.props.checkout.state.order.node;

    let values = {
      data: {
        method: 'display',
        order: order,
        terminal: terminal,
        cashregister: JSON.parse(localStorage.getItem('selectedCashRegister')),
        amount: this.props.checkoutPayment.props.checkout.state.order.totals.total
      }
    }

    TerminalActions._post(values)
      .then(response => {
        console.log(response);
        return response;
      }).catch(error => {
        return error;
      });

  }





  render() {
    let component = this;
    return (
      <>
      <MDBRow className="terminal-search-bar">
        <MDBCol>
          <TerminalSearch nid={JSON.parse(localStorage.getItem('selectedCashRegister')).nid[0].value} checkoutPayment={this} />
        </MDBCol>
      </MDBRow>
      </>
    );
  }
}

export default TerminalPayment;
