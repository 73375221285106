import React, { Component } from 'react';
import PermissionHelper from "../../../helpers/PermissionHelper";
import ReactToPrint from 'react-to-print';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as AllLightIcons from '@fortawesome/pro-light-svg-icons'


import {
  MDBSpinner,
  MDBTable,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBLink,
  MDBBtn,
  MDBIcon,
  MDBCardImage,
  MDBInput,
  MDBTooltip,
  MDBTableHead,
  MDBTableBody,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter
} from 'mdbreact';

import moment from 'moment';
import 'moment/locale/de';


import config from '../../../config';


class CheckoutOpenCashdrawer extends Component {

  constructor(props) {
    super(props);
  }


  componentDidMount(){
  }




  render() {

    var component = this;

    return (
      <>
          <ReactToPrint
                trigger={() => {
                  // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                  // to the root node of the returned component as it will be overwritten.
                  return <MDBBtn color="primary"><MDBIcon icon="external-link-alt" /> Kasse öffnen</MDBBtn>;
                }}
                content={() => this.componentRefOpen}
              />
              <div className="hide"><ComponentToPrint openCashdrawer={true} ref={el => (this.componentRefOpen = el)} /></div>

      </>
    );
  }
}





export class ComponentToPrint extends React.PureComponent {
  render() {
    const receipt = require('receipt');
    receipt.config.currency = 'CHF ';
    receipt.config.width = 32;
    receipt.config.ruler = '-';



    const output = receipt.create([
        { type: 'text', value: [
          'Venus Beauty AG',
          'Bäumlistrasse 32',
          '8404 Winterthur',
            'info@venus-beauty.ch',
            'www.venus-beauty.ch'
        ], align: 'center' },
        { type: 'empty' },
        { type: 'properties', lines: [
            { name: 'Kassierer', value:  JSON.parse(localStorage.getItem('user')).field_firstname[0].value + ' ' + JSON.parse(localStorage.getItem('user')).field_lastname[0].value},
            { name: 'Standort', value:  JSON.parse(localStorage.getItem('selectedLocation')).title[0].value}
        ] },
        { type: 'text', value: 'Kasse wurde geöffnet. ('+moment().tz('Europe/Zurich').format("DD.MM.YYYY HH:mm")+')', align: 'center' },
        { type: 'empty' },
        { type: 'text', value: '', align: 'center' },

    ]);


    return (
      <>
      <pre>
        <img src="https://www.venus-beauty.ch/themes/venusbeauty/images/logo.svg" className="order-logo" />
        {output}
      </pre>
      </>
    );
  }
}


export default CheckoutOpenCashdrawer;
