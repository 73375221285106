import React, {Component} from "react";
import {
  MDBContainer,
  MDBCollapse,
  MDBCard,
  MDBCardBody,
  MDBCollapseHeader,
  MDBIcon,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBSpinner,
  MDBFileInput,
  MDBSelect,
  MDBTooltip,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBAlert,
  MDBSwitch
} from "mdbreact";
import { Modal } from 'antd';

import ReactToPrint from 'react-to-print';

import MDBFileupload from 'mdb-react-fileupload';

import CashMovementActions from "./actions/CashMovementActions";

import moment from 'moment';
import 'moment/locale/de';

import { Switch } from 'antd';

class CashMovementForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loaded: true,
      modal: false,
      isNegative: true,
      entity: this.props.entity,
      data: {
        field_cash_register_session: JSON.parse(localStorage.getItem('selectedCashRegisterSession')).node.nid[0].value,
        body: '',
        field_amount: 0
      }
    }


    this._onSubmit = this._onSubmit.bind(this);
    this._onInputChange = this._onInputChange.bind(this);
    this._onFormReset = this._onFormReset.bind(this);
    this._onToggleModal = this._onToggleModal.bind(this);
    this.handleSwitchChange = this.handleSwitchChange.bind(this);
  }


  componentDidMount() {
    var component = this;
  }

  /*
  handleSwitchChange = nr => () => {
    var name = 'isNegative';
    this.setState(prevState => ({
      [name]: !prevState.isNegative
    }))
  }
  */

  handleSwitchChange(checked) {
      var name = 'isNegative';
    this.setState(prevState => ({
      [name]: checked
    }))
  }

  _onSubmit(e) {
    var component = this;
    e.preventDefault();

    component.props.searchComponent.setState(prevState => ({
      loaded: false,
    }))



    let values = {
      data: this.state.data
    }


    if(this.state.isNegative) {
      values.data.field_amount = -Math.abs(this.state.data.field_amount);
    }


    if(!this.state.entity) {
      CashMovementActions._post(values)
        .then(response => {
          component.ref.handlePrint();
          component._onFormReset();
          component._onToggleModal();
          component.props.searchComponent.loadCashMovementCollection();
        });
    } else {
      values.nid = this.state.entity.nid[0].value;
      CashMovementActions._patch(values)
        .then(response => {
          component._onFormReset();
          component._onToggleModal();
          component.props.searchComponent.loadCashMovementCollection();
        });
    }
  }

  _onFormReset() {
    this.setState(prevState => ({
      loaded: true,
      data: {
        field_cash_register_session: JSON.parse(localStorage.getItem('selectedCashRegisterSession')).node.nid[0].value,
        body: '',
        field_amount: 0
      },
      nid: false
    }))
  }

  _onToggleModal = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  _onInputChange(e) {
    var name = e.target.name;
    var value = e.target.value;
    this.setState(prevState => ({
      data: {                   // object that we want to update
          ...prevState.data,    // keep all other key-value pairs
          [name]: value  // update the value of specific key
      }
    }))
  }

  _onSelectChange(e) {
    var name = e.target.name;
    var value;
    if(e.target.value[0]) {
      value = e.target.value[0];
    } else {
      value = null;
    }

    //console.log(e.target.value);

    this.setState(prevState => ({
      data: {                   // object that we want to update
          ...prevState.data,    // keep all other key-value pairs
          [name]: value  // update the value of specific key
      }
    }))

  }


  render() {
    let component = this;
    return (
      <>
        {!this.props.entity &&
          <MDBBtn onClick={this._onToggleModal} color="primary"><MDBIcon icon="plus mr-2" /> Bargeldbewegung</MDBBtn>
        }

        {this.props.entity &&
          <MDBBtn onClick={this._onToggleModal} color="primary"><MDBIcon far icon="edit" /></MDBBtn>
        }

        <Modal title="Bargeldbewegung" destroyOnClose={true} visible={this.state.modal} onOk={this._onToggleModal} onCancel={this._onToggleModal} cancelButtonProps={{ style: { display: 'none' } }} okButtonProps={{ style: { display: 'none' } }}>

              <form id="cashmovement-add-form" onSubmit={this._onSubmit}>
                <MDBRow>
                  <MDBCol>
                    <MDBRow>
                      <MDBCol>
                        <MDBInput
                          outline
                          name='field_amount'
                          label='Betrag'
                          group
                          type='Number'
                          error='wrong'
                          success='right'
                          required
                          onChange={this._onInputChange}
                          value={this.state.data.field_amount}
                        />
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBCol>
                        <MDBInput
                          outline
                          name='body'
                          label='Notiz'
                          group
                          type='textarea'
                          error='wrong'
                          success='right'
                          required
                          onChange={this._onInputChange}
                          value={this.state.data.body}
                        />
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBCol>
                        {/*<MDBSwitch labelLeft="Einzahlung" labelRight="Auszahlung" ref={this.switchRef} checked={this.state.isNegative} onChange={this.handleSwitchChange(1)} />*/}

                        <Switch onClick={(e) => { this.handleSwitchChange(e) }} checkedChildren="Auszahlung" unCheckedChildren="Einzahlung" defaultChecked />
                    </MDBCol>
                    </MDBRow>

                    <div className="form-action-bar">

                    {!this.state.entity &&
                      <MDBBtn color="danger" onClick={this._onFormReset} className="mr-2 hide"><MDBIcon icon="sync mr-2" /> zurücksetzen</MDBBtn>
                    }
                    <MDBBtn color="primary" type="submit"><MDBIcon far icon="save" className="mr-2" /> Speichern</MDBBtn>

                    </div>

                      <>
                      <ReactToPrint
                        ref={ref => this.ref = ref}
                            trigger={() => {
                              // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                              // to the root node of the returned component as it will be overwritten.
                              return <a href="#" className="btn btn-primary hide">Kassenbon drucken</a>;
                            }}
                            content={() => this.componentRef}
                          />
                        <div className="hide"><ComponentToPrint cashMovement={this.state} ref={el => (this.componentRef = el)} /></div>
                      </>

                  </MDBCol>
                </MDBRow>

              </form>

        </Modal>
      </>
    );
  }
}

export class ComponentToPrint extends React.PureComponent {
  render() {
    const receipt = require('receipt');
    receipt.config.currency = 'CHF ';
    receipt.config.width = 31;
    receipt.config.ruler = '-';

    let amount = this.props.cashMovement.data.field_amount;

    if(this.props.cashMovement.isNegative) {
      amount = -Math.abs(amount);
    }

    const output = receipt.create([
        { type: 'text', value: [
          'Venus Beauty GmbH',
          'Zürichstrasse 12',
          '6004 Luzern',
          'info@venus-beauty.ch',
          'www.venus-beauty.ch',
          'CHE-261.630.740 MWST'
        ], align: 'center' },
        { type: 'empty' },
        { type: 'properties', lines: [
            { name: 'Betrag', value: new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'CHF' }).format(amount) },
            { name: 'Mitarbeiter', value: JSON.parse(localStorage.getItem('user')).field_firstname[0].value + ' ' + JSON.parse(localStorage.getItem('user')).field_lastname[0].value },
            { name: 'Datum', value: moment().tz('Europe/Zurich').format("DD.MM.YYYY HH:mm") },
        ] },
    ]);


    return (
      <>
      <pre>
        <img src="https://www.venus-beauty.ch/themes/venusbeauty/images/logo.svg" className="order-logo" />
        {output}

        <hr />

        {this.props.cashMovement.data.body}
      </pre>

      </>
    );
  }
}

export default CashMovementForm;
