import React, { Component } from 'react';
import PermissionHelper from "../../../helpers/PermissionHelper";


import {
  MDBSpinner,
  MDBTable,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBLink,
  MDBBtn,
  MDBIcon,
  MDBCardImage,
  MDBInput,
  MDBTooltip,
  MDBTableHead,
  MDBTableBody,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter
} from 'mdbreact';
import { Modal } from 'antd';

import CustomerSearch from "./customer/CustomerSearch";
import OrderActions from "../order/actions/OrderActions";

import config from '../../../config';


class CheckoutCustomer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modal: false
    }


    this._onModalToggle = this._onModalToggle.bind(this);
    this._onSelect = this._onSelect.bind(this);
    this._onDelete = this._onDelete.bind(this);
  }


  componentDidMount(){
  }


  _onModalToggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }


  _onSelect(nid) {
    var component = this;



    let values = {
      data: {
        field_customer: nid
      }
    }

    values.nid = this.props.checkout.state.order.node.nid[0].value;
    OrderActions._patch(values)
      .then(response => {
        this.props.checkout._onUpdateOrder(this.props.checkout.state.order.node.nid[0].value);
        component._onModalToggle();
      });

  }


  _onDelete(nid) {
    var component = this;



    let values = {
      data: {
        field_customer: 'NULL'
      }
    }

    values.nid = this.props.checkout.state.order.node.nid[0].value;
    OrderActions._patch(values)
      .then(response => {
        this.props.checkout._onUpdateOrder(this.props.checkout.state.order.node.nid[0].value);
      });

  }


  render() {

    var component = this;

    return (
      <>
      <MDBCard id="checkout-customer">
        <MDBCardBody>

          {this.props.checkout.state.order.totals.total == 0 &&
            <><MDBBtn>Der Warenkorb ist leer</MDBBtn></>
          }

          {this.props.checkout.state.order.totals.due > 0  &&
            <MDBBtn onClick={this._onModalToggle} color="primary">

              <MDBIcon icon="users" />

              {!this.props.checkout.state.order.customer &&
                <>Kunde auswählen</>
              }

              {this.props.checkout.state.order.customer &&
                <>
                {this.props.checkout.state.order.customer.field_firstname[0].value} {this.props.checkout.state.order.customer.field_lastname[0].value}
                </>
              }
            </MDBBtn>
          }

          {this.props.checkout.state.order.totals.due <= 0 && this.props.checkout.state.order.totals.total > 0  &&
            <>
            {!this.props.checkout.state.order.customer &&
              <MDBBtn>Laufkundschaft</MDBBtn>
            }

            {this.props.checkout.state.order.customer &&
              <MDBBtn>
              {this.props.checkout.state.order.customer.field_firstname[0].value} {this.props.checkout.state.order.customer.field_lastname[0].value}
              </MDBBtn>
            }
            </>
          }



          {this.props.checkout.state.order.customer &&
            <>
            <MDBBtn color="danger" onClick={() => this._onDelete(this.props.checkout.state.order.node.nid[0].value)}><MDBIcon icon="trash-alt" /></MDBBtn>
            </>
          }

          <Modal width={1000} title="Kunden auswählen" destroyOnClose={true} visible={this.state.modal} onOk={this._onModalToggle} onCancel={this._onModalToggle} cancelButtonProps={{ style: { display: 'none' } }} okButtonProps={{ style: { display: 'none' } }}>
            <div id="checkout-customer"><CustomerSearch checkoutCustomer={this} /></div>
          </Modal>
        </MDBCardBody>
      </MDBCard>

      </>
    );
  }
}

export default CheckoutCustomer;
