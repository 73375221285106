import React, { Component } from 'react';


/** import config **/
import config from '../../../config';
import { MDBContainer, MDBRow, MDBCol, MDBStepper, MDBStep, MDBBtn, MDBInput, MDBSpinner, MDBCard, MDBCardBody, MDBLink, MDBIcon, MDBCardTitle, MDBModal, MDBModalHeader, MDBModalBody} from "mdbreact";

import CashRegisterSearch from './cashregister/CashRegisterSearch';
import LocationSearch from './location/LocationSearch';
import CashRegisterSessionSearch from './cashregistersession/CashRegisterSessionSearch';
import {CashRegisterSessionClose} from './cashregistersession/CashRegisterSessionClose';
import OrderSearch from '../order/OrderSearch';
import CashMovementSearch from '../cashmovement/CashMovementSearch';
import {CheckoutOdoo} from '../checkout/CheckoutOdoo';
import CheckoutOpenCashdrawer from '../checkout/CheckoutOpenCashdrawer';
import ReactToPrint from 'react-to-print';
import { Modal } from 'antd';

import CashRegisterSessionActions from "./cashregistersession/actions/CashRegisterSessionActions";

import { connect } from 'react-redux';
import userActions from '../../../actions/userActions';


import moment from 'moment';
import 'moment/locale/de';

class DashboardSteps extends Component {

  constructor(props) {
    super(props);

    this.state = {
      cashMovementModal: false,
      formActivePanel1: 1,
      formActivePanel1Changed: false,
      selectedCashRegister: JSON.parse(localStorage.getItem('selectedCashRegister')),
      selectedLocation: JSON.parse(localStorage.getItem('selectedLocation')),
      selectedCashRegisterSession: JSON.parse(localStorage.getItem('selectedCashRegisterSession'))
    }

    this._onSwapFormActive = this._onSwapFormActive.bind(this);
    this._onHandleNextPrevClick = this._onHandleNextPrevClick.bind(this);
    this._onCalculateAutofocus = this._onCalculateAutofocus.bind(this);
    this._onCashRegisterSessionComplete = this._onCashRegisterSessionComplete.bind(this);

 }

  componentDidMount() {
    console.log(this);
  }


  _onSwapFormActive = (a) => (param) => (e) => {
    this.setState({
      ['formActivePanel' + a]: param,
      ['formActivePanel' + a + 'Changed']: true
    });
  }

  _onHandleNextPrevClick = (a) => (param) => (e) => {
    this.setState({
      ['formActivePanel' + a]: param,
      ['formActivePanel' + a + 'Changed']: true
    });
  }

  _onCalculateAutofocus = (a) => {
    if (this.state['formActivePanel' + a + 'Changed']) {
      return true
    }
  }

  _onCashMovementModalToggle = () => {
    this.setState({
      cashMovementModal: !this.state.cashMovementModal
    });
  }


  _onCashRegisterSessionComplete = () => {
    var component = this;

    console.log(component.state.selectedCashRegisterSession);

    const r = window.confirm("Tagesabschluss erstellen?");
    if (r === true) {
      let values = {
        data: {
          nid: component.state.selectedCashRegisterSession.node.nid[0].value,
          field_is_completed: 1
        }
      }



      CashRegisterSessionActions._patch(values)
        .then(response => {
          component.ref.handlePrint();
          component.props.logout();
        })
    }


  }


  render() {


    return (
      <section id="dashboard">

        <MDBCard>
            <MDBCardBody>

                <MDBRow>


                  {!this.state.selectedLocation &&
                    <MDBCol md="12" className="step-location">
                      <MDBCardTitle>Bitte wählen deinen Standort</MDBCardTitle>
                      <LocationSearch stepper={this} />
                    </MDBCol>
                  }


                  {!this.state.selectedCashRegister && this.state.selectedLocation &&
                    <MDBCol md="12" className="step-cashregister">
                      <MDBCardTitle>Bitte wählen Sie eine Kasse aus</MDBCardTitle>
                      <CashRegisterSearch stepper={this} />
                    </MDBCol>
                  }

                  {this.state.selectedCashRegister && !this.state.selectedCashRegisterSession &&
                    <MDBCol md="12">
                      <MDBCardTitle>Sitzung eröffnen/weiterführen</MDBCardTitle>

                      <CheckoutOpenCashdrawer />

                      <CashRegisterSessionSearch stepper={this} nid={this.state.selectedCashRegister.nid[0].value} />
                    </MDBCol>
                  }


                  {this.state.selectedCashRegisterSession &&
                    <MDBCol md="12">


                      <div class="cash-register-session-details mb-5">
                        <MDBCardTitle>Aktuelle Sitzung</MDBCardTitle>



                        <div className="row">
                          <div className="col-md-5">
                            <div className="item">
                              <div className="label">Kassennummer: </div>
                              <div className="value">{this.state.selectedCashRegister.field_cash_register_number[0].value}</div>
                            </div>

                            <div className="item">
                              <div className="label">Kasse geöffnet:</div>
                              <div className="value">
                                <time> {moment(new Date(this.state.selectedCashRegisterSession.node.created[0].value), "YYYYMMDD").tz('Europe/Zurich').format("DD.MM.YYYY HH:mm")}</time>
                              </div>
                            </div>

                            {/*
                            <div className="item">
                              <div className="label">Läuft seit:</div>
                              <div className="value">
                                <time> {moment(new Date(this.state.selectedCashRegisterSession.node.created[0].value), "YYYYMMDD").tz('Europe/Zurich').fromNow()}</time>
                              </div>
                            </div>
                            */}

                            <div className="item">
                              <div className="label">Kassestock:</div>
                              <div className="value">
                                {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'CHF' }).format(this.state.selectedCashRegisterSession.node.field_cash_amount[0].value)}
                              </div>
                            </div>

                            <div className="item">
                              <div className="label">Eröffnet von:</div>
                              <div className="value">
                                {this.state.selectedCashRegisterSession.user.field_firstname[0].value} {this.state.selectedCashRegisterSession.user.field_lastname[0].value}
                              </div>
                            </div>




                          </div>
                          <div className="col-md-7">
                            <div className="cash-register-actions">
                              <MDBBtn color="danger" className="hide" onClick={this._onCashRegisterSessionComplete}><MDBIcon icon="file-pdf" /> Tagesabschluss</MDBBtn>

                              <CashRegisterSessionClose />

                                <>
                                <ReactToPrint
                                  ref={ref => this.ref = ref}
                                      trigger={() => {
                                        // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                                        // to the root node of the returned component as it will be overwritten.
                                        return <a href="#" className="btn btn-primary hide">Kassenbon drucken</a>;
                                      }}
                                      content={() => this.componentRef}
                                    />
                                  <div className="hide"><CashRegisterCloseReceipt ref={el => (this.componentRef = el)} /></div>
                                </>

                              <CheckoutOpenCashdrawer />

                                <MDBBtn color="primary" onClick={this._onCashMovementModalToggle}><MDBIcon icon="money-bill" /> Bargeldbewegung</MDBBtn>
                                  <Modal width={600} title="Bargeldbewegung" destroyOnClose={true} visible={this.state.cashMovementModal} onOk={this._onCashMovementModalToggle} onCancel={this._onCashMovementModalToggle} cancelButtonProps={{ style: { display: 'none' } }} okButtonProps={{ style: { display: 'none' } }}>
                                    <CashMovementSearch />
                                </Modal>

                             </div>
                          </div>
                        </div>

                      </div>
                      <MDBCardTitle>Bestellübersicht</MDBCardTitle>
                      <CheckoutOdoo application={this.props.application} />
                    </MDBCol>
                  }


                </MDBRow>
            </MDBCardBody>
          </MDBCard>

      </section>
    );
  }
}



export class CashRegisterCloseReceipt extends React.PureComponent {
  render() {
    const receipt = require('receipt');
    receipt.config.currency = 'CHF ';
    receipt.config.width = 31;
    receipt.config.ruler = '-';

    const output = receipt.create([
        { type: 'text', value: [
          'Venus Beauty GmbH',
          'Bäumlistrasse 32',
          '8404 Winterthur',
          'info@venus-beauty.ch',
          'www.venus-beauty.ch',
          'CHE-261.630.740 MWST'
        ], align: 'center' },
        { type: 'empty' },
        { type: 'properties', lines: [
            { name: 'Mitarbeiter', value: JSON.parse(localStorage.getItem('user')).field_firstname[0].value + ' ' + JSON.parse(localStorage.getItem('user')).field_lastname[0].value },
            { name: 'Datum', value: moment().tz('Europe/Zurich').format("DD.MM.YYYY HH:mm") },
        ] },
    ]);


    return (
      <>
      <pre>
        <img src="https://www.venus-beauty.ch/themes/venusbeauty/images/logo.svg" className="order-logo" />
        {output}

        <hr />
        Tagesabschluss
      </pre>

      </>
    );
  }
}

//export default DashboardSteps;


function mapState(state) {
    const { authenticationReducer } = state;
    const { user } = authenticationReducer;
    return { user };
}

const actionCreators = {
  logout: userActions.logout
}

const connectedDashboardSteps = connect(mapState, actionCreators)(DashboardSteps);
export { connectedDashboardSteps as DashboardSteps };
