import React from 'react';

import {
  MDBBtn,
  MDBIcon,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBInput,
  MDBSpinner
} from "mdbreact";

import NumberFormat from 'react-number-format';

import CashRegisterSessionActions from "./actions/CashRegisterSessionActions";

import moment from 'moment';
import 'moment/locale/de';

import PermissionHelper from "../../../../helpers/PermissionHelper";
import { Modal } from 'antd';
import SignaturePad from 'react-signature-canvas';
import ReactToPrint from 'react-to-print';
import { connect } from 'react-redux';
import userActions from '../../../../actions/userActions';
import CashRegisterCalculator from "./CashRegisterCalculator";



class CashRegisterSessionClose extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      hasSubmitted: false,
      modal: false,
      totals: null,
      imageURL: null,
      dataChecked: false,
      data: {
        field_is_completed: false,
        field_image: null,
        body: '',
        nid: null,
        field_cash_withdrawal: 0,
        field_cash_amount: 0,
        field_cash_amount_counted: 0
      }
    };


    this.sigCanvas = React.createRef();

    this._onToggleModal = this._onToggleModal.bind(this);
    this._onSignatureSave = this._onSignatureSave.bind(this);
    this._onSignatureClear = this._onSignatureClear.bind(this);
    this._onCheckboxChange = this._onCheckboxChange.bind(this);
    this._onInputChange = this._onInputChange.bind(this);
    this._onReset = this._onReset.bind(this);
  }

  componentDidMount() {
    let component = this;
    this._onReset();
  }

  _onToggleModal = () => {


    this._loadCashRegisterSession();
    this._onReset();

    this.setState({
      modal: !this.state.modal
    });
  }

  _loadCashRegisterSession() {
    let component = this;
    var selectedCashRegisterSession = localStorage.getItem('selectedCashRegisterSession');

    selectedCashRegisterSession = JSON.parse(selectedCashRegisterSession);
    console.log(selectedCashRegisterSession);

    CashRegisterSessionActions._get(selectedCashRegisterSession.node.nid[0].value, this.state.filters, this.state.page).then(response => {
      component.setState({
        totals: response.data.entity.cashRegisterTotals
      });

    });
  }

  _onCheckboxChange(e) {
    //console.log(e.target.checked);
    this.setState({
      [e.target.name]: e.target.checked
    },() => {
        //console.log(this.state);
    });
  }

  _onSignatureSave() {


    if(this.state.hasSubmitted) {
      return;
    }

    this.setState(prevState => ({
      hasSubmitted: true,
    }))



    var cashInRegister = Number(Object.entries(this.state.totals.paymentTotals).find(x => x[1].field_cash_payment === '1')[1].total) + Number(this.state.totals.cashRegisterSession.node.field_cash_amount[0].value) - Number(this.state.totals.creditMemosCashTotal) + Number(this.state.totals.cashMovementTotal)



    if(this.state.data.field_cash_withdrawal > cashInRegister) {
      alert('Es befindet sich nicht genug Geld in der Kasse');
      return;
    }



    var component = this;
    this.setState({
      imageURL: this.sigCanvas.current.getTrimmedCanvas().toDataURL("image/png")
    });

    var dataURI = this.sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
    var BASE64_MARKER = ';base64,';
    var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
    var base64 = dataURI.substring(base64Index);
    var raw = window.atob(base64);
    var rawLength = raw.length;
    var array = new Uint8Array(new ArrayBuffer(rawLength));

    var i = 0;
    for(i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }

    CashRegisterSessionActions._uploadFile(array, 'png').then(response => {


      var selectedCashRegisterSession = localStorage.getItem('selectedCashRegisterSession');
      selectedCashRegisterSession = JSON.parse(selectedCashRegisterSession);

      var data = component.state.data;
      data.field_image = response.data.fid[0].value;
      data.field_is_completed = 1;
      data.nid = selectedCashRegisterSession.node.nid[0].value;

      data.field_cash_amount_counted = component.state.data.field_cash_amount;


      component.setState({
        data: data
      }, function(e) {


        let values = {
          data: data
        }



        CashRegisterSessionActions._patch(values)
          .then(response => {



            component.sigCanvas.current.clear();
            component.props.logout();
            component.setState(prevState => ({
              hasSubmitted: false,
            }))

          })
      });
    });

  }

  _onSignatureClear() {
    if(this.sigCanvas.current) {
      this.sigCanvas.current.clear();
    }

    var data = this.state.data;
    data.field_image = false;
    this.setState({
      imageURL: null,
      data: data
    });
  }


  _onInputChange(e) {

    var name = e.target.name;
    var value = e.target.value;

    this.setState(prevState => ({
      data: {                   // object that we want to update
          ...prevState.data,    // keep all other key-value pairs
          [name]: value  // update the value of specific key
      }
    }))

  }

  _onReset() {
    this.setState(prevState => ({
      hasSubmitted: false,
      totals: null,
      imageURL: null,
      dataChecked: false,
      data: {
        field_is_completed: false,
        field_image: null,
        body: '',
        nid: null,
        field_cash_withdrawal: 0,
        field_cash_amount: 0,
        field_cash_amount_counted: 0
      }
    }))
  }

  render() {

    let component = this;
    let taxTotal = 0;
    let debitValue = 0;
    let creditValue = 0;

    if(this.state.totals) {
      debitValue = Number(Object.entries(this.state.totals.paymentTotals).find(x => x[1].field_cash_payment === '1')[1].total) + Number(this.state.totals.cashRegisterSession.node.field_cash_amount[0].value) - Number(this.state.totals.creditMemosCashTotal) + Number(this.state.totals.cashMovementTotalPositive) + Number(this.state.totals.cashMovementTotalNegative);
    }



    return (
      <>

      <MDBBtn onClick={this._onToggleModal} color="primary"><MDBIcon icon="file-pdf" /> Tagesabschluss</MDBBtn>
      <Modal width={800} title="Tagesabschluss" destroyOnClose={true} visible={this.state.modal} onOk={this._onToggleModal} onCancel={this._onToggleModal} cancelButtonProps={{ style: { display: 'none' } }} okButtonProps={{ style: { display: 'none' } }}>


        <div className="cashregistersession-close-bar">

        {!this.state.totals &&
          <div className="text-center">
            <MDBSpinner red />
          </div>
        }

        {this.state.totals &&
          <>

          {this.state.dataChecked == 0 &&
            <>
              <CashRegisterCalculator form={this} />
              <hr />
            </>
          }

          <MDBCard>
            <MDBCardBody>
              <MDBCardTitle>Allgemeine Infos</MDBCardTitle>
              <MDBCardText tag="div">
                <table className="table table-striped">
                  <tr>
                    <td>Kassenstock: </td>
                    <td>
                      {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'CHF' }).format(this.state.totals.cashRegisterSession.node.field_cash_amount[0].value)}
                    </td>
                  </tr>
                  <tr>
                    <td>Bareingang: </td>
                    <td>
                      {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'CHF' }).format(this.state.totals.cashMovementTotalPositive)}
                    </td>
                  </tr>
                  <tr>
                    <td>Barausgang: </td>
                    <td>
                      {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'CHF' }).format(this.state.totals.cashMovementTotalNegative)}
                    </td>
                  </tr>
                </table>
              </MDBCardText>
            </MDBCardBody>
          </MDBCard>




          <hr />


            <MDBCard>
              <MDBCardBody>
                <MDBCardTitle>Umsätze</MDBCardTitle>
                <MDBCardText>
                  <table width="100%" valign="top">
                    <tr>
                      <td valign="top">
                        <h3>Produktgruppen</h3>
                        <table className="table table-striped">
                          {Object.entries(this.state.totals.productCategoryTotals).map(function(productCategory){
                            console.log(productCategory);
                            productCategory = productCategory[1];
                            if(productCategory.total > 0) {
                              return (
                                <tr>
                                  <td>{ productCategory.name }: </td>
                                  <td>
                                    {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'CHF' }).format(productCategory.total)}
                                  </td>
                                </tr>
                              )
                            }
                          })}
                        </table>


                        <h3>Mitarbeiter</h3>
                        <table className="table table-striped">
                          {Object.entries(this.state.totals.usersTotals).map(function(user){
                            console.log(user);
                            user = user[1];
                            if(user.total > 0) {
                              return (
                                <tr>
                                  <td>{ user.name }: </td>
                                  <td>
                                    {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'CHF' }).format(user.total)}
                                  </td>
                                </tr>
                              )
                            }
                          })}
                        </table>
                      </td>
                      <td valign="top">
                        <h3>Zahlungsmethode</h3>
                        <table className="table table-striped">
                          {Object.entries(this.state.totals.paymentTotals).map(function(paymentMethod){
                            console.log(paymentMethod);
                            paymentMethod = paymentMethod[1];
                            if(paymentMethod.total > 0) {
                              return (
                                <tr>
                                  <td>{ paymentMethod.name }: </td>
                                  <td>
                                    {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'CHF' }).format(paymentMethod.total)}
                                  </td>
                                </tr>
                              )
                            }
                          })}
                        </table>


                      </td>
                    </tr>
                  </table>

                </MDBCardText>
              </MDBCardBody>
            </MDBCard>



          <hr />



            <MDBCard>
              <MDBCardBody>
                <MDBCardTitle>Steuer</MDBCardTitle>
                <MDBCardText>
                  <table width="100%">
                    <tr>
                      <td valign="top">
                        <h3>Umsatz</h3>

                        <table className="table table-striped">
                          {Object.entries(this.state.totals.taxTotals).map(function(tax){
                            console.log(tax);
                            var key = tax[0];
                            tax = tax[1];
                            if(tax > 0) {

                              taxTotal += tax;

                              return (
                                <tr>
                                  <td>{ key } %: </td>
                                  <td>
                                    {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'CHF' }).format(tax)}
                                  </td>
                                </tr>
                              )
                            }
                          })}
                        </table>
                      </td>
                      <td valign="top">


                        <h3>Erstattungen</h3>

                        <table className="table table-striped">
                          {Object.entries(this.state.totals.creditMemoTaxTotals).map(function(tax){
                            console.log(tax);
                            var key = tax[0];
                            tax = tax[1];

                            if(tax > 0) {
                              return (
                                <tr>
                                  <td>{ key } %: </td>
                                  <td>
                                    {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'CHF' }).format(tax)}
                                  </td>
                                </tr>
                              )
                            }

                          })}
                        </table>
                      </td>
                    </tr>
                  </table>
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>




          <hr />




            <MDBCard>
              <MDBCardBody>
                <MDBCardTitle>Gesamt</MDBCardTitle>
                <MDBCardText>
                  <table width="100%" className="table">
                    <tr>
                      <td>
                        Gesamterstattung:
                      </td>
                      <td>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'CHF' }).format(this.state.totals.creditMemosTotal)}</td>
                    </tr>
                    <tr>
                      <td>
                        Storniert:
                      </td>
                      <td>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'CHF' }).format(this.state.totals.canceledTotal)}</td>
                    </tr>
                    <tr>
                      <td>
                        Gewährter Rabatt:
                      </td>
                      <td>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'CHF' }).format(this.state.totals.discountTotal)}</td>
                    </tr>
                    <tr>
                      <td>
                        Barbestand SOLL:
                      </td>
                      <td>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'CHF' }).format(debitValue) }</td>
                    </tr>

                    <tr>
                      <td>
                        Barbestand gezählt:
                      </td>
                      <td>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'CHF' }).format(component.state.data.field_cash_amount)}</td>
                    </tr>
                    <tr>
                      <td>
                        Barbestand Differenz:
                      </td>
                      <td>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'CHF' }).format(component.state.data.field_cash_amount - debitValue)}</td>
                    </tr>
                    <tr>
                      <td>
                        Abgeschöpft:
                      </td>
                      <td>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'CHF' }).format(component.state.data.field_cash_withdrawal)}</td>
                    </tr>
                    <tr>
                      <td>
                        Barbestand IST:
                      </td>
                      <td>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'CHF' }).format(component.state.data.field_cash_amount - component.state.data.field_cash_withdrawal)}</td>
                    </tr>
                    <tr>
                      <td>
                        Gesamtsteuer:
                      </td>
                      <td>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'CHF' }).format(Number(taxTotal).toFixed(2) - Number(this.state.totals.creditMemoTaxTotal).toFixed(2))}</td>
                    </tr>

                    <tr>
                      <td>
                        Gesamtumsatz:
                      </td>
                      <td>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'CHF' }).format(this.state.totals.cashRegisterSessionTotal)}</td>
                    </tr>



                    {Object.entries(this.state.totals.paymentTotals).find(x => x[1].field_is_giftcard === '1') && Object.entries(this.state.totals.paymentTotals).find(x => x[1].field_is_subscription === '1') &&
                      <tr>
                        <td>
                          Gesamtumsatz (ohne Abos & Gutschein):
                        </td>
                        <td>
                          {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'CHF' }).format(Number(this.state.totals.cashRegisterSessionTotal) - Number(Object.entries(this.state.totals.paymentTotals).find(x => x[1].field_is_giftcard === '1')[1].total) - Number(Object.entries(this.state.totals.paymentTotals).find(x => x[1].field_is_subscription === '1')[1].total))  }
                        </td>
                      </tr>
                    }

                  </table>

                  <hr />

                  <MDBInput label="Ich habe die Angaben überprüft"  checked={this.state.dataChecked} onChange={this._onCheckboxChange} type="checkbox" name="dataChecked" id="dataChecked" />

                <hr />




            {this.state.dataChecked == 1 &&
                  <>
                  <MDBInput label="Notitz" onChange={this._onInputChange}  type="textarea" outline name="body" id="body" />

                  <MDBInput label="Abschöpfen" onChange={this._onInputChange}  type="text" outline name="field_cash_withdrawal" id="cashWithdrawal" />


                                <h3>Ihre Unterschrift</h3>
                                <SignaturePad
                                  ref={component.sigCanvas}
                                  canvasProps={{
                                    className: "signatureCanvas"
                                  }}
                                />

                                <hr />
                                {/* Button to trigger save canvas image */}
                                <MDBBtn onClick={component._onSignatureClear} color="primary" className="mr-2"><MDBIcon icon="sync" className="mr-2" /> Unterschrift löschen</MDBBtn>
                                <MDBBtn onClick={component._onSignatureSave} disabled={((this.state.hasSubmitted)) ? true : false} color="primary"><MDBIcon far icon="save mr-2" /> Unterschrift speichern</MDBBtn>
                              </>

                          }
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>


          </>
        }

        </div>



      </Modal>
      </>
    );
  }
}





function mapState(state) {
    const { authenticationReducer } = state;
    const { user } = authenticationReducer;
    return { user };
}

const actionCreators = {
  logout: userActions.logout
}

const connectedCashRegisterSessionClose = connect(mapState, actionCreators)(CashRegisterSessionClose);
export { connectedCashRegisterSessionClose as CashRegisterSessionClose };

//export default CashRegisterSessionClose;
