import React, {useState,useEffect} from 'react';
import {
  MDBCol,
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
  MDBLink,
  MDBView,
  MDBMask,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBRow,
  MDBIcon
} from 'mdbreact';
import { Modal } from 'antd';


import GiftCardActions from "./actions/GiftCardActions";

import GiftCardForm from "./GiftCardForm";

import OrderTransactionSearch from "../ordertransaction/OrderTransactionSearch";



import PermissionHelper from "../../../helpers/PermissionHelper";

import moment from 'moment';
import 'moment/locale/de';



class GiftCardResultRow extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false
    };

    this._onDelete = this._onDelete.bind(this);
    this._onEdit = this._onEdit.bind(this);
    this._onModalToggle = this._onModalToggle.bind(this);
  }

  _onModalToggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  _onDelete(e) {
    var component = this;
    const r = window.confirm("Löschen Bestätigen");
    if (r === true) {
      component.props.searchComponent.setState(prevState => ({
        loaded: false,
      }))

      GiftCardActions._delete(this.props.result.nid)
        .then(response => {
          component.props.searchComponent.loadGiftCardCollection();
        });
    }
  }

  _onEdit(e) {

  }

  render() {

    console.log(this.props.result);

    return (
      <tr className="giftcard-item">
        <td>{ this.props.result.node.field_gift_card_number[0].value }</td>

          <td>
              {this.props.result.owner &&
                <>
                  {this.props.result.owner.field_firstname[0].value} {this.props.result.owner.field_lastname[0].value}
                </>
              }
            </td>

          <td>
            {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'CHF' }).format(this.props.result.node.field_amount[0].value)}
          </td>

          <td>
            {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'CHF' }).format(this.props.result.giftCardLeftAmount)}
          </td>


          <td>
            {this.props.result.node.body[0].value}
          </td>

        <td className="text-right">
          <div className="action-bar">

            <MDBBtn onClick={this._onModalToggle} color="info"><MDBIcon icon="info" /></MDBBtn>

              {PermissionHelper._hasPermission('restful delete GiftCard') === true &&
                <>
                  <MDBBtn color="danger" onClick={this._onDelete}><MDBIcon far icon="trash-alt" /></MDBBtn>
                </>
              }
              {false && PermissionHelper._hasPermission('restful patch GiftCard') === true &&
                <>
                  <GiftCardForm key={'giftcard-edit-form-' + this.props.result.nid + this.props.result.changed} entity={this.props.result.node} searchComponent={this.props.searchComponent} />
                </>
              }
          </div>
        </td>


        <Modal width={1000} title="Transaktionen" destroyOnClose={true} visible={this.state.modal} onOk={this._onModalToggle} onCancel={this._onModalToggle} cancelButtonProps={{ style: { display: 'none' } }} okButtonProps={{ style: { display: 'none' } }}>
              <OrderTransactionSearch giftCardId={this.props.result.nid} />
        </Modal>

      </tr>
    );
  }
}

export default GiftCardResultRow;
