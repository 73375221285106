import React, {
  Component
} from 'react';

import Pager from 'react-pager';
import CustomerFilter from "../../checkout/customer/CustomerFilter";
import PermissionHelper from "../../../../helpers/PermissionHelper";
import CustomerActions from "../../checkout/customer/actions/CustomerActions";

import {
  MDBSpinner,
  MDBTable,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBInput,
  MDBSelect,
  MDBCollapseHeader,
  MDBIcon,
  MDBCollapse,
  MDBPagination,
  MDBPageItem,
  MDBPageNav,
  MDBAnimation,
  MDBBtn
} from 'mdbreact';

import moment from 'moment';
import 'moment/locale/de';
import { Pagination, Tooltip } from 'antd';


import config from '../../../../config';



class CustomerSearch extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      result: [],
      page: 0,
      limit: 10,
      total: 0,
      visiblePage: 5,
      treatmentsFilter: [],
      treatments: [],
      locationsFilter: [],
      locations: [],
      genderFilters: [],
      genders: [],
      channelsFilters: [],
      channels: [],
      communicationTypesFilters: [],
      communicationTypes: [],
      roles: [],
      filters: {
        field_location: null,
        field_treatments: null,
        field_sort: 'nfd.changed',
        field_sort_direction: 'desc',
        field_limit: 10
      },
      sortOptions: [
        {
          label: 'Geändert',
          value: 'nfd.changed'
        },
        {
          label: 'Nachname',
          value: 'nf_lastname.field_lastname_value'
        }
      ]
    }

    this.formRef = React.createRef();

    this._onHandlePageChanged = this._onHandlePageChanged.bind(this);
    this.loadCustomerCollection = this.loadCustomerCollection.bind(this);
    //console.log(props);
  }

  componentDidMount() {
    this.loadCustomerCollection();
  }

  loadCustomerCollection() {

      var component = this;

      component.setState({
        collectionLoaded: false
      });

      if(PermissionHelper._hasPermission('ignore locations') === false) {
        var selectedLocation = JSON.parse(localStorage.getItem('selectedLocation'));
        var filters = component.state.filters;
        filters.field_location = selectedLocation;
        component.setState({
          filters: filters
        });
      }

      /** build filter query **/
      CustomerActions._get('All', this.state.filters, this.state.page).then(response => {
        //  console.log(response.data.entity);
        //console.log(response.data.result);


        var result = [];
        if(response.data.result) {
          result = Object.entries(response.data.result);
        }

        var locations = response.data.locations;
        var locationsFilter = new Array();
        if(locations) {
          Object.entries(locations).forEach(function(item, key){
            var optionItem = {
              label: item[1].title[0].value,
              value: item[0]
            }


            /*
            if(PermissionHelper._hasPermission('ignore locations') === false) {
              var userLocations = new Array();
              var userLocationsObject = JSON.parse(localStorage.getItem('user'));
              userLocationsObject = userLocationsObject.field_location;
              Object.entries(userLocationsObject).forEach(function(location, k){
                //console.log(location[1].target_id);
                userLocations.push(location[1].target_id.toString());
              })

              var hasLocation = userLocations.includes(item[0].toString());
              //console.log(hasLocation);
              //console.log(item[0]);
              if(hasLocation) {
                locationsFilter.push(optionItem);
              }
              //console.log(userLocations);
            } else {
              locationsFilter.push(optionItem);
            }*/

            locationsFilter.push(optionItem);


            //console.log(item);
          });
        }

        if(this.state.locationsFilter.length == 0) {
          component.setState({
            locationsFilter: locationsFilter,
            locations: locations,
          });
        }




        var treatments = response.data.treatments;
        var treatmentsFilter = new Array();
        if(treatments) {
          Object.entries(treatments).forEach(function(item, key){
            //console.log(item);
            var optionItem = {
              label: item[1].name,
              value: item[1].id
            }
            treatmentsFilter.push(optionItem);
            //console.log(item);
          });
        }

        if(this.state.treatmentsFilter.length == 0) {
          component.setState({
            treatmentsFilter: treatmentsFilter,
            treatments: treatments,
          });
        }



        var genders = response.data.genders;
        var genderFilters = new Array();
        if(genders) {
          Object.entries(genders).forEach(function(item, key){
            //console.log(item);
            var optionItem = {
              label: item[1].name,
              value: item[1].id.toString()
            }
            genderFilters.push(optionItem);
            //console.log(item);
          });
        }

        if(this.state.genderFilters.length == 0) {
          component.setState({
            genderFilters: genderFilters,
            genders: genders,
          });
        }




        var channels = response.data.channels;
        var channelsFilters = new Array();
        if(channels) {
          Object.entries(channels).forEach(function(item, key){
            //console.log(item);
            var optionItem = {
              label: item[1].name,
              value: item[1].id.toString()
            }
            channelsFilters.push(optionItem);
            //console.log(item);
          });
        }

        if(this.state.channelsFilters.length == 0) {
          component.setState({
            channelsFilters: channelsFilters,
            channels: channels,
          });
        }



        var communicationTypes = response.data.communicationTypes;
        var communicationTypesFilters = new Array();
        if(communicationTypes) {
          Object.entries(communicationTypes).forEach(function(item, key){
            console.log(item);
            var optionItem = {
              label: item[1].name,
              value: item[1].id.toString()
            }
            communicationTypesFilters.push(optionItem);
            //console.log(item);
          });
        }

        if(this.state.communicationTypesFilters.length == 0) {
          component.setState({
            communicationTypesFilters: communicationTypesFilters,
            communicationTypes: communicationTypes,
          });
        }



        component.setState({
          result: result,
          loaded: true,
          collectionLoaded: true,
          total: response.data.total
        });

      });
  }

  _onHandlePageChanged(page, pageSize) {
    let component = this;
    let filters = component.state.filters;
    filters.field_limit = pageSize;
    //console.log(page-1);
    component.setState({
        limit: pageSize,
        page: page - 1,
        filters: filters
    }, () => {
        component.loadCustomerCollection();
    });
  }

  render() {
    let component = this;
    return (
      <>

        {!this.state.loaded &&
          <div className="text-center">
            <MDBSpinner key={'customer-loader'} red />
          </div>
        }

        {component.state.loaded && (
          <>



            <CustomerFilter searchComponent={component} />
            <hr />
            { component.state.result.length > 0 && (
              <MDBTable responsive hover striped className={`${!component.state.collectionLoaded ? "loading" : ""}`}>
                <thead>
                  <tr>
                    <th className='th-lg'>
                      Kundennummer #
                    </th>
                    <th className='th-lg'>
                      Vorname
                    </th>
                    <th className='th-lg'>
                      Nachname
                    </th>
                    <th className='th-lg'>
                      Mobil
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {true && (
                    component.state.result.map(function(row, j){
                      //console.log(row)
                      return (
                        <tr className="customer-item" key={'customer-' + row[1].nid + row[1].changed}>

                          <td>{(row[1].node.field_customer_number[0] ? row[1].node.field_customer_number[0].value : '')}</td>
                          <td>{(row[1].node.field_firstname[0] ? row[1].node.field_firstname[0].value : '')}</td>
                          <td>{(row[1].node.field_lastname[0] ? row[1].node.field_lastname[0].value : '')}</td>
                          <td>{(row[1].node.field_mobile[0] ? row[1].node.field_mobile[0].value : '')}</td>
                            <td>

                              <div className="action-bar">


                                <MDBBtn color="black" onClick={() => component.props.customerSelect(row[1])}>
                                  <Tooltip title="Kunde wählen">
                                    <MDBIcon far icon="check-circle" />
                                  </Tooltip>
                                </MDBBtn>
                            </div>

                            </td>
                        </tr>
                      )
                    })
                  )}
                </tbody>
              </MDBTable>
            )}

            {component.state.result.length == 0 && component.state.collectionLoaded &&
              <div className="empty">Keine Einträge</div>
            }

            {component.state.loaded && component.state.total > 10 && (
              <>
                <hr />

                <Pagination defaultCurrent={this.state.page + 1} total={this.state.total} pageSize={this.state.limit} onChange={this._onHandlePageChanged} />

                {/*
                  <Pager
                        total={Math.ceil(this.state.total / this.state.filters.field_limit)}
                        current={this.state.page}
                        visiblePages={this.state.visiblePage}
                        titles={{ first: '<|', last: '>|' }}
                        className="pagination-sm pull-right"
                        onPageChanged={this._onHandlePageChanged}
                    />
                  */}
              </>
            )}
          </>
        )}

      </>
    );
  }
}

export default CustomerSearch;
