import React, {Component} from "react";
import {
  MDBContainer,
  MDBCollapse,
  MDBCard,
  MDBCardBody,
  MDBCollapseHeader,
  MDBIcon,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBSpinner,
  MDBFileInput,
  MDBSelect,
  MDBTooltip,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBAlert
} from "mdbreact";
import { Modal, Collapse } from 'antd';

import MDBFileupload from 'mdb-react-fileupload';
import Subscription from '../../../assets/images/qrCard.png' // relative path to image
import SubscriptionActions from "./actions/SubscriptionActions";
import Select from 'react-select'
import BarcodeReader from '../barcodescanner/BarcodeScanner'

import moment from 'moment';
import 'moment/locale/de';

import CustomerSearch from './customer/CustomerSearch';



class SubscriptionForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loaded: true,
      modal: false,
      entity: this.props.entity,
      selectedCustomer: null,
      data: {
        title: (this.props.entity && this.props.entity.title[0]) ? this.props.entity.title[0].value : '',
        body: (this.props.entity && this.props.entity.body[0]) ? this.props.entity.body[0].value : '',
        field_treatment_type: (this.props.entity && this.props.entity.field_treatment_type[0]) ? this.props.entity.field_treatment_type[0].target_id : '',
        field_subscription_category: (this.props.entity && this.props.entity.field_subscription_category[0]) ? this.props.entity.field_subscription_category[0].target_id : '',
        field_card_secret_key: (this.props.entity && this.props.entity.field_card_secret_key[0]) ? this.props.entity.field_card_secret_key[0].value : '',
        field_subscription_number: (this.props.entity && this.props.entity.field_subscription_number[0]) ? this.props.entity.field_subscription_number[0].value : '',
        field_location: (JSON.parse(localStorage.getItem('selectedLocation'))) ? JSON.parse(localStorage.getItem('selectedLocation')).nid[0].value : null,
      },
      subscriptionCategoryDataFilter: [],
      treatmentTypeDataFilter: [],
    }



    this.submitButtonRef = React.createRef();
    this.subscriptionCategoryRef = React.createRef();

    this._onHandleScan = this._onHandleScan.bind(this)

    this._onSubmit = this._onSubmit.bind(this);
    this._onCustomerSelect = this._onCustomerSelect.bind(this);
    this._onClearCustomer = this._onClearCustomer.bind(this);
    this._onFormReset = this._onFormReset.bind(this);
    this._onToggleModal = this._onToggleModal.bind(this);
    this._onInputChange = this._onInputChange.bind(this);
    this._onSelectChange = this._onSelectChange.bind(this);

  }


  componentDidMount() {
    let component = this;

    let treatmentTypeData = this.props.searchComponent.state.treatmentTypeData;
    let treatmentTypeDataFilter = new Array();
    if(treatmentTypeData) {
      Object.entries(treatmentTypeData).forEach(function(item, key){
        console.log(item);
        let optionItem = {
          label: item[1].label,
          value: item[1].value,
        }
        treatmentTypeDataFilter.push(optionItem);
        //console.log(item);
      });
    }



    let subscriptionCategoryData = this.props.searchComponent.state.subscriptionCategoryData;
    let subscriptionCategoryDataFilter = new Array();
    if(subscriptionCategoryData) {
      Object.entries(subscriptionCategoryData).forEach(function(item, key){

        if(item[1].treatment_type_id == component.state.data.field_treatment_type) {
          let optionItem = {
            label: item[1].label,
            value: item[1].value,
          }
          subscriptionCategoryDataFilter.push(optionItem);
          //console.log(item);
        }

      });
    }


    this.setState(prevState => ({
      treatmentTypeDataFilter: treatmentTypeDataFilter,
      subscriptionCategoryDataFilter: subscriptionCategoryDataFilter
    }))


  }


  _onClearCustomer() {
    this.setState(prevState => ({
      selectedCustomer: null,
    }))
  }

  _onCustomerSelect(customer) {

    this.setState(prevState => ({
      selectedCustomer: customer,
      data: {                   // object that we want to update
          ...prevState.data,    // keep all other key-value pairs
          ['field_customer']: customer.node.nid[0].value  // update the value of specific key
      }
    }))
  }

  _onSubmit(e) {
    let component = this;
    e.preventDefault();

    component.props.searchComponent.setState(prevState => ({
      loaded: false,
    }))

    let values = {
      data: this.state.data
    }

    if(!this.state.entity) {
      SubscriptionActions._post(values)
        .then(response => {
          component._onFormReset();
          component._onToggleModal();
          component.props.searchComponent.loadSubscriptionCollection();
        }).catch(error => {
          component._onFormReset();
          component._onToggleModal();
          component.props.searchComponent.loadSubscriptionCollection();
        });
    } else {
      values.nid = this.state.entity.nid[0].value;
      SubscriptionActions._patch(values)
        .then(response => {
          component._onFormReset();
          component._onToggleModal();
          component.props.searchComponent.loadSubscriptionCollection();
        });
    }
  }

  _onFormReset() {
    this.setState(prevState => ({
      loaded: true,
      entity: false,
      data: {
        title: '',
        body: '',
        field_treatment_type: '',
        field_subscription_category: '',
        field_card_secret_key: '',
        field_subscription_number: '',
        field_location: (JSON.parse(localStorage.getItem('selectedLocation'))) ? JSON.parse(localStorage.getItem('selectedLocation')).nid[0].value : null,
      },
      subscriptionCategoryDataFilter: [],
    }))
  }

  _onToggleModal = () => {
    this._onFormReset();
    this.setState({
      modal: !this.state.modal
    });
  }

  _onInputChange(e) {

    let name = e.target.name;
    let value = e.target.value;

    this.setState(prevState => ({
      data: {                   // object that we want to update
          ...prevState.data,    // keep all other key-value pairs
          [name]: value  // update the value of specific key
      }
    }))

  }

  _onSelectChange(e) {

    let component = this;

    //console.log(this.subscriptionCategoryRef);

    if(component.submitButtonRef.current) {
      component.submitButtonRef.current.focus();
    }

    let name = e.target.name;
    let value;
    if(e.target.value) {
      value = e.target.value;
    } else {
      value = null;
    }

    this.setState(prevState => ({
      data: {                   // object that we want to update
          ...prevState.data,    // keep all other key-value pairs
          [name]: value  // update the value of specific key
      }
    }), function(e) {
      let subscriptionCategoryData = this.props.searchComponent.state.subscriptionCategoryData;
      let subscriptionCategoryDataFilter = new Array();

      console.log(component.state.data.field_treatment_type);

      if(subscriptionCategoryData) {
        Object.entries(subscriptionCategoryData).forEach(function(item, key){
          if(item[1].treatment_type_id == component.state.data.field_treatment_type) {
            let optionItem = {
              label: item[1].label,
              value: item[1].value,
            }
            subscriptionCategoryDataFilter.push(optionItem);
            //console.log(item);
          }

        });
      }

      component.setState(prevState => ({
        subscriptionCategoryDataFilter: subscriptionCategoryDataFilter
      }), function(e) {
        console.log(component.submitButtonRef);



      })
    })






  }

  _onHandleScan(data){

    let component = this;

    console.log(data);

    data = data.replace("'", '-');
    data = data.replace("ß", '-');


    if(data == null) {
      return;
    }

    const dataParts = data.split("-");

    let formData = this.state.data;

    formData['field_card_secret_key'] = dataParts[1];
    formData['field_subscription_number'] = dataParts[0];

    component.setState({
      data: formData
    }, function() {
    });


  }

  _onHandleError(err){
    console.error('FEHLER: ' + err )
  }

  render() {
    let component = this;

    let defaultTreatmentTypeDataValue = false;

    if(this.state.entity && this.state.treatmentTypeDataFilter) {
      if(this.state.treatmentTypeDataFilter.find(x => x.value === this.state.data.field_treatment_type )) {
        defaultTreatmentTypeDataValue = { label: this.state.treatmentTypeDataFilter.find(x => x.value === this.state.data.field_treatment_type).label, value: this.state.data.field_treatment_type }
      }
    }


    let subscriptionCategoryDataValue = false;

    if(this.state.entity && this.state.subscriptionCategoryDataFilter && this.state.subscriptionCategoryDataFilter.length > 0 && this.state.subscriptionCategoryDataFilter[0]) {
      if(this.state.subscriptionCategoryDataFilter.find(x => x.value === this.state.data.field_subscription_category )) {
        subscriptionCategoryDataValue = { label: this.state.subscriptionCategoryDataFilter.find(x => x.value === this.state.data.field_subscription_category).label, value: this.state.data.field_subscription_category }
      }
    }




    return (

      <>



        {!this.props.entity &&
          <MDBBtn onClick={this._onToggleModal} color="primary"><MDBIcon icon="plus mr-2" /> Neues Abo erfassen</MDBBtn>
        }

        {this.props.entity &&
          <MDBBtn onClick={this._onToggleModal} color="primary"><MDBIcon far icon="edit" /></MDBBtn>
        }

        <Modal width={710} title="Neues Abo erfassen" destroyOnClose={true} visible={this.state.modal} onOk={this._onToggleModal} onCancel={this._onToggleModal} cancelButtonProps={{ style: { display: 'none' } }} okButtonProps={{ style: { display: 'none' } }}>

            <form id="subscription-add-form" onSubmit={this._onSubmit}>


              <button class="invisible-position" ref={this.submitButtonRef}>test</button>

              <div>


                <div className={ this.state.data.field_card_secret_key ? 'hide' : '' }>

                  <MDBAlert color="info" >
                    {'Bitte scannen Sie die Abokarte.'}
                  </MDBAlert>

                  {!this.state.data.field_card_secret_key &&
                    <BarcodeReader
                      onError={this._onHandleError}
                      onScan={this._onHandleScan}
                      preventDefault={true}
                      avgTimeByChar={10}
                      stopPropagation={true}
                      />
                  }



                </div>


                <div>

                {!this.state.entity && this.state.data.field_card_secret_key &&
                  <>


                  {this.state.data.field_card_secret_key &&
                    <MDBAlert color="success" >
                      {'Abokarte wurde erkannt. Bitte geben Sie die Details und die Bestellnummer des Abos an.'}
                    </MDBAlert>
                  }

                  <MDBRow>
                    <MDBCol className="text-left">
                      <Select
                        required
                        className="react-select"
                        classNamePrefix="react-select"
                        placeholder="Behandlung"
                        options={this.state.treatmentTypeDataFilter}
                        defaultValue={defaultTreatmentTypeDataValue}
                        isClearable={true}
                        onChange={(value) => {

                          console.log(value);

                          if(value) {
                            value = value.value;
                          } else {
                            value = '';
                          }

                          this._onSelectChange({
                            target: {
                              name: 'field_treatment_type',
                              value: value,
                            },
                          });
                        }}
                      />



                    </MDBCol>
                  </MDBRow>


                  {this.state.subscriptionCategoryDataFilter && this.state.subscriptionCategoryDataFilter.length > 0 &&
                    <MDBRow>
                      <MDBCol className="text-left">
                        <hr />
                        <Select
                          ref={this.subscriptionCategoryRef}
                          required
                          className="react-select"
                          classNamePrefix="react-select"
                          placeholder="Abo Typ"
                          options={this.state.subscriptionCategoryDataFilter}
                          defaultValue={subscriptionCategoryDataValue}
                          isClearable={true}
                          onChange={(value) => {

                            if(value) {
                              value = value.value;
                            } else {
                              value = '';
                            }

                            this._onSelectChange({
                              target: {
                                name: 'field_subscription_category',
                                value: value,
                              },
                            });
                          }}
                        />
                      </MDBCol>
                    </MDBRow>
                  }

                  {this.state.data.field_subscription_category && this.state.subscriptionCategoryDataFilter && this.state.subscriptionCategoryDataFilter.length > 0 &&
                    <>
                      <hr />
                      <div className="row">
                        <div className="col-md-12">
                            {this.state.data.field_card_secret_key &&
                                <MDBInput
                                  outline
                                  name='body'
                                  label='Notiz / Bestellnummer'
                                  group
                                  type='textarea'
                                  error='wrong'
                                  success='right'
                                  required
                                  onChange={this._onInputChange}
                                  value={this.state.data.body }
                                />
                            }
                        </div>
                      </div>
                    </>
                  }


                  </>

                }


              </div>
              </div>





              {this.state.data.field_card_secret_key && this.state.data.field_subscription_category && this.state.subscriptionCategoryDataFilter && this.state.subscriptionCategoryDataFilter.length > 0 &&
                <MDBRow>
                  <MDBCol>



                    {!this.state.selectedCustomer &&
                      <Collapse className="subscription-customer-select">
                        <Collapse.Panel header="Kunden wählen" key="1">
                          <CustomerSearch customerSelect={this._onCustomerSelect} />
                        </Collapse.Panel>
                      </Collapse>
                    }


                    {this.state.selectedCustomer && this.state.selectedCustomer.node &&
                      <>
                      <Collapse defaultActiveKey={['1']} onChange={this._onCollapseChange}>
                        <Collapse.Panel header={this.state.selectedCustomer.node.field_firstname[0].value + ' ' + this.state.selectedCustomer.node.field_lastname[0].value} key="1">
                          <fieldset>
                            <legend>Kunde</legend>
                            <table className="table table-striped">
                              <tbody>
                                <tr>
                                  <td>Name: </td>
                                  <td>{this.state.selectedCustomer.node.field_firstname[0].value} {this.state.selectedCustomer.node.field_lastname[0].value}</td>
                                </tr>
                                <tr>
                                  <td>Tel: </td>
                                  <td>{this.state.selectedCustomer.node.field_mobile[0].value}</td>
                                </tr>
                              </tbody>
                            </table>
                            <hr />

                            <MDBBtn onClick={this._onClearCustomer} color="primary">
                              <MDBIcon icon="trash-alt" className="mr-2" />
                              Kunden neu auswählen
                            </MDBBtn>
                          </fieldset>
                        </Collapse.Panel>
                      </Collapse>

                        <hr />
                      </>
                    }


                    <hr />

                    <MDBBtn  color="primary" type="submit"><MDBIcon far icon="save" className="mr-2" /> Speichern</MDBBtn>
                  </MDBCol>
                </MDBRow>
              }

            </form>

      </Modal>
      </>


    );
  }
}

export default SubscriptionForm;
