import React, { Component } from 'react';
import { MDBCol, MDBCard, MDBCardBody, MDBCardHeader, MDBRow, MDBCardTitle, MDBIcon, MDBSpinner } from 'mdbreact';
import { Line, Doughnut, Radar, Pie } from 'react-chartjs-2';

class SellStats extends Component {



    render(){

      var component = this;

      console.log(component.props.statistic.state.result.bestseller);

      var dataBestseller = {
          labels: [],
          datasets: [{
              data: [],
              backgroundColor: ["#005f89", "#0093b7", "#a0c5cf", "#e6eceb", "#63cdd7"],
              hoverBackgroundColor: ["#005f89", "#0093b7", "#a0c5cf", "#e6eceb", "#63cdd7"]
          }],
        };


        var dataProductGroup = {
            labels: [],
            datasets: [{
                data: [],
                backgroundColor: ["#005f89", "#0093b7", "#a0c5cf", "#e6eceb", "#63cdd7"],
                hoverBackgroundColor: ["#005f89", "#0093b7", "#a0c5cf", "#e6eceb", "#63cdd7"]
            }]
        };


        var dataEmployee = {
            labels: [],
            datasets: [{
                data: [],
                backgroundColor: ["#005f89", "#0093b7", "#a0c5cf", "#e6eceb", "#63cdd7"],
                hoverBackgroundColor: ["#005f89", "#0093b7", "#a0c5cf", "#e6eceb", "#63cdd7"]
            }]
        };

        var dataPayment = {
            labels: [],
            datasets: [{
                data: [],
                backgroundColor: ["#005f89", "#0093b7", "#a0c5cf", "#e6eceb", "#63cdd7"],
                hoverBackgroundColor: ["#005f89", "#0093b7", "#a0c5cf", "#e6eceb", "#63cdd7"]
            }]
        };


        return (
            <MDBRow className="mb-4">
              <MDBCol md="12" lg="6" className="mb-6">
                  <MDBCard className="mb-4">
                    <MDBCardBody>
                      <MDBCardTitle>Bestseller</MDBCardTitle>
                    </MDBCardBody>
                  <MDBCardBody >

                    {!component.props.statistic.state.loaded &&
                      <MDBSpinner red />
                    }

                    {component.props.statistic.state.loaded &&

                        component.props.statistic.state.result.bestseller.map(function(row, j){
                          dataBestseller.labels.push(row.name + ' (' + new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'CHF' }).format(row.count) + ')');
                          dataBestseller.datasets[0].data.push(row.count);
                        })

                    }

                    {component.props.statistic.state.loaded && component.props.statistic.state.result.bestseller.length > 0 &&
                      <Pie
                        data={dataBestseller}
                        height={'auto'}
                        options={{
                          plugins: {
                            responsive: true,
                            legend: {
                              display: true,
                              position: "right",
                              labels: {
                                usePointStyle: true,
                              },
                            },
                          },
                        }}
                        />
                    }


                  </MDBCardBody>
                  </MDBCard>
              </MDBCol>
              <MDBCol md="12" lg="6" className="mb-6">
                  <MDBCard className="mb-4">
                    <MDBCardBody>
                      <MDBCardTitle>Artikelgruppen</MDBCardTitle>
                    </MDBCardBody>
                  <MDBCardBody >

                    {!component.props.statistic.state.loaded &&
                      <MDBSpinner red />
                    }

                    {component.props.statistic.state.loaded &&

                        component.props.statistic.state.result.productGroups.map(function(row, j){
                          let total = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'CHF' }).format(row.count);
                          dataProductGroup.labels.push(row.name + ' (' + new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'CHF' }).format(row.count) + ')');
                          dataProductGroup.datasets[0].data.push(row.count);
                        })

                    }

                    {component.props.statistic.state.loaded && component.props.statistic.state.result.productGroups.length > 0 &&
                      <Pie
                        data={dataProductGroup}
                        height={'auto'}
                        options={{
                          plugins: {
                            responsive: true,
                            legend: {
                              display: true,
                              position: "right",
                              labels: {
                                usePointStyle: true,
                              },
                            },
                          },
                        }}

                        />
                    }

                  </MDBCardBody>
                  </MDBCard>
              </MDBCol>
              <MDBCol md="12" lg="6" className="mb-6">
                  <MDBCard className="mb-4">
                    <MDBCardBody>
                      <MDBCardTitle>Verkäufe pro Mitarbeiter</MDBCardTitle>
                    </MDBCardBody>
                  <MDBCardBody >
                    {!component.props.statistic.state.loaded &&
                      <MDBSpinner red />
                    }

                    {component.props.statistic.state.loaded &&

                        component.props.statistic.state.result.employees.map(function(row, j){
                          dataEmployee.labels.push(row.name + ' (' + new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'CHF' }).format(row.count) + ')');
                          dataEmployee.datasets[0].data.push(row.count);
                        })

                    }


                    {component.props.statistic.state.loaded && component.props.statistic.state.result.employees.length > 0 &&
                      <Pie
                        data={dataEmployee}
                        height={'auto'}
                        options={{
                          plugins: {
                            responsive: true,
                            legend: {
                              display: true,
                              position: "right",
                              labels: {
                                usePointStyle: true,
                              },
                            },
                          },
                        }}
                      />
                    }
                  </MDBCardBody>
                  </MDBCard>
              </MDBCol>
              <MDBCol md="12" lg="6" className="mb-6">
                  <MDBCard className="mb-4">
                    <MDBCardBody>
                      <MDBCardTitle>Umsatz nach Zahlungsmethode</MDBCardTitle>
                    </MDBCardBody>
                  <MDBCardBody >
                    {!component.props.statistic.state.loaded &&
                      <MDBSpinner red />
                    }

                    {component.props.statistic.state.loaded &&

                        component.props.statistic.state.result.paymentGroups.map(function(row, j){
                          dataPayment.labels.push(row.name + ' (' + new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'CHF' }).format(row.count) + ')');
                          dataPayment.datasets[0].data.push(row.count);
                        })

                    }

                    {component.props.statistic.state.loaded && component.props.statistic.state.result.paymentGroups.length > 0 &&
                      <Pie
                        data={dataPayment}
                        height={'auto'}

                        options={{
                          plugins: {
                            responsive: true,
                            legend: {
                              display: true,
                              position: "right",
                              labels: {
                                usePointStyle: true,
                              },
                            },
                          },
                        }}
                       />
                    }
                  </MDBCardBody>
                  </MDBCard>
              </MDBCol>
            </MDBRow>
        )
    }

}

export default SellStats;
