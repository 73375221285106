import React, {
  Component
} from "react";

import {DebounceInput} from 'react-debounce-input';
import PermissionHelper from "../../../helpers/PermissionHelper";

import Select from 'react-select'


import {
  MDBSpinner,
  MDBTable,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBInput,
  MDBSelect,
  MDBCollapseHeader,
  MDBIcon,
  MDBCollapse,
  MDBPagination,
  MDBPageItem,
  MDBPageNav,
  MDBBtn
} from 'mdbreact';

class CreditMemoFilter extends Component {

  constructor(props) {
    super(props);

    this.state = {
      creditmemoFilter: null,
      creditmemoStatusFilter: null
    }

    this._onCheckboxChange = this._onCheckboxChange.bind(this);
    this._onSelectChange = this._onSelectChange.bind(this);
    this._onInputChange = this._onInputChange.bind(this);
    this._onDirectionChange = this._onDirectionChange.bind(this);
    this._updateSearchFilter = this._updateSearchFilter.bind(this);
  }

  componentDidMount() {
    var component = this;
    var creditmemoStatusArray = this.props.searchComponent.state.creditmemoStatusArray;
    var creditmemoStatusFilter = new Array();
    if(creditmemoStatusArray) {
      Object.entries(creditmemoStatusArray).forEach(function(item, key){
        var optionItem = {
          label: item[1].name,
          value: item[1].id
        }
        creditmemoStatusFilter.push(optionItem);
        console.log(creditmemoStatusFilter);
      });
    }

    this.setState(prevState => ({
      creditmemoStatusFilter: creditmemoStatusFilter,
    }))

  }

  _onCheckboxChange(e) {
    var name = e.target.name;
    var value = e.target.value;
    var filters = this.props.searchComponent.state.filters;
    filters[name] = value;


    this._updateSearchFilter(filters);
  }

  _onSelectChange(e) {
    var name = e.target.name;
    var value;
    if(e.target.value) {
      value = e.target.value;
    } else {
      value = null;
    }
    var filters = this.props.searchComponent.state.filters;
    filters[name] = value;


    this._updateSearchFilter(filters);
  }

  _onDirectionChange(e) {
    var name = e.target.name;
    var value = e.target.value;

    var filters = this.props.searchComponent.state.filters;
    filters[name] = value;

    this._updateSearchFilter(filters);
  }

  _onInputChange(e) {
    //console.log(e);
    var name = e.target.name;
    var value = e.target.value;
    var filters = this.props.searchComponent.state.filters;
    filters[name] = value;


    this._updateSearchFilter(filters);
  }

  _updateSearchFilter(filters) {

    var component = this;

    //console.log(filters);
    component.props.searchComponent.setState({
        filters: filters,
        result: [],
        page: 0
    }, () => {
        var creditmemoFilter = component.props.searchComponent.state;
        localStorage.setItem('creditmemoFilter', JSON.stringify(creditmemoFilter));
        component.props.searchComponent.loadCreditMemoCollection();
    });
  }

  render() {
    let component = this;
    return (
      <div className="filter-card">
        <hr />
        
      </div>
    )
  }

}

export default CreditMemoFilter;
