import React from 'react';
import { MDBNotification } from 'mdbreact';
class UpdateNotification extends React.Component {

  constructor(props) {
    super(props);
    //console.log(props);
    this.alertRef = React.createRef();

    this._onClick = this._onClick.bind(this);
  }

  componentDidMount() {

    var component = this;

    // Handle global event.
    window.addEventListener("newContentAvailable", () => {

      let messageContainer = component.props.alert.current.state.messageContainer;
      let messageLength = component.props.alert.current.state.messageContainer.length;

      messageContainer.push(
        <MDBNotification
          key={messageLength}
          show
          fade
          iconClassName="text-info"
          title={'Neues Update verfügbar.'}
          message={'Hier klicken um die App neuzuladen'}
          autohide={false}
          onClick={this._onClick}
        />
    );

    component.props.alert.current.setState({messageContainer: messageContainer})
      console.log('newContentAvailable');
    });
  }


  _onClick = () => {
    // Reload when modal click.
    window.location.reload(window.location.href);
  };


  render() {
    return (
      <>

      </>
    );
  }
}



export default UpdateNotification;
