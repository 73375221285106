import React, { Component } from 'react';
import Pager from 'react-pager';
import Image from "../../image/Image";
import PermissionHelper from "../../../../helpers/PermissionHelper";
import axios from "axios";

import QrReader from 'react-qr-reader'

import OrderTransactionActions from "../../ordertransaction/actions/OrderTransactionActions";
import SubscriptionSearch from "./SubscriptionSearch";
import { Empty } from 'antd';
import LoadingOverlay from 'react-loading-overlay'


import {
  MDBSpinner,
  MDBTable,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBLink,
  MDBBtn,
  MDBIcon,
  MDBCardImage
} from 'mdbreact';

import config from '../../../../config';


class SubscriptionPayment extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showLoader: false
    }


    this._onHandleSubscriptionPayment = this._onHandleSubscriptionPayment.bind(this);
  }

  _onHandleSubscriptionPayment(subscriptionId) {
    /* create order transaction and decrement subscription */


    // check if already scanned
    if(this.state.showLoader) {
      alert('Es steht noch eine Zahlung aus.');
      return;
    }

    this.setState({
      showLoader: true
    });

    var component = this;

    var order = this.props.checkoutPayment.props.checkout.state.order.node;

    let values = {
      data: {
        field_payment_method: this.props.checkoutPayment.state.data.field_payment_method,
        field_entity: order.nid[0].value,
        field_amount: this.props.checkoutPayment.state.data.field_amount,
        field_subscription: subscriptionId
      }
    }


    OrderTransactionActions._post(values)
      .then(response => {
        this.props.checkoutPayment._onToggleModal();
        this.props.checkoutPayment.props.checkout._onUpdateOrder(this.props.checkoutPayment.props.checkout.state.order.nid);
        console.log(response);
        this.setState({
          showLoader: false
        });
      }).catch(error => {
        console.log(error);
        this.setState({
          showLoader: false
        });
      });

  }




  render() {
    let component = this;
    return (
      <>
      <MDBRow className="subscription-bar">
        <MDBCol>
          <hr />
          {this.props.checkoutPayment.props.checkout.state.order.customer &&
            <SubscriptionSearch nid={this.props.checkoutPayment.props.checkout.state.order.customer.nid[0].value} treatmentType={null} onHandleSubscriptionPayment={this._onHandleSubscriptionPayment} />
          }

          {!this.props.checkoutPayment.props.checkout.state.order.customer &&
            <><Empty description={'Bitte wählen einen Kunden aus'} /></>
          }


          {this.state.showLoader &&
            <>
            <LoadingOverlay
                active={true}
                 text='Warte auf Zahlung...'
              >
              </LoadingOverlay>
            </>
          }


        </MDBCol>



      </MDBRow>
      </>
    );
  }
}

export default SubscriptionPayment;
