import React, {useState,useEffect} from 'react';
import {
  MDBCol,
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
  MDBLink,
  MDBView,
  MDBMask,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBRow,
  MDBIcon
} from 'mdbreact';

import Currency from 'react-currency-formatter';

import OrderItemActions from "../order/actions/OrderItemActions";

import PermissionHelper from "../../../helpers/PermissionHelper";

import moment from 'moment';
import 'moment/locale/de';



class CustomerDisplayItem extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      total: this.props.result.field_price[0].value * this.props.result.field_qty[0].value,
    };

  }

  componentDidMount() {
    console.log(this);
    this._onApplyDiscount();

  }

  _onApplyDiscount() {

    var component = this;

    var total = this.state.total;

    console.log(total);

    Object.entries(component.props.discountItems).map(function(row, j){

      var discountValue = row[1].field_discount_value[0].value;
      var discountSubstract = 0;

      if(row[1].field_discount_percent[0].value) {
        discountValue = (component.state.total / 100) * discountValue;
      }

      total = total-discountValue;

    })

    this.setState({
      total: total
    });
  }



  render() {
    var component = this;
    console.log(this.props);

    return (
      <tr className="order-item">
        <td class="hide">
          <img src="https://via.placeholder.com/50x50/FFB6C1/000000" className="img-fluid" />
        </td>
        <td width="100">
          {this.props.result.title[0].value}
          {component.props.discountItems.length > 0 &&
            <strong>
              <br />
              Rabatt:
              {Object.entries(component.props.discountItems).map(function(row, j){

                var discountValue = row[1].field_discount_value[0].value;
                let discountSuffix = 'CHF';

                if(row[1].field_discount_percent[0].value) {
                  //discountValue = (component.state.price / 100) * discountValue;
                  discountSuffix = '%';
                }

                return ' ' + row[1].body[0].value + ' (' + discountValue + ' ' + discountSuffix + ')';

              })}
            </strong>
          }
        </td>
        <td width="100">

        {parseInt(this.props.result.field_qty[0].value)}
        </td>
        <td width="100">


          {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'CHF' }).format(this.props.result.field_price[0].value)}


        </td>
        <td width="100">


          {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'CHF' }).format(this.state.total)}

          {component.props.discountItems.length > 0 &&
            <span> statt {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'CHF' }).format(this.props.result.field_qty[0].value * this.props.result.field_price[0].value)}</span>
          }

        </td>

      </tr>
    );
  }
}

export default CustomerDisplayItem;
