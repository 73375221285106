import React, {
  Component
} from "react";

import {DebounceInput} from 'react-debounce-input';
import PermissionHelper from "../../../helpers/PermissionHelper";

import Select from 'react-select'

import LimitSelect from "../misc/LimitSelect";

import QrReader from 'react-qr-reader'
import SubscriptionReceipt from '../../../assets/images/gutscheinkarte-1.png' // relative path to image

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as AllLightIcons from '@fortawesome/pro-light-svg-icons'

import BarcodeReader from '../barcodescanner/BarcodeScanner'

import Subscription from '../../../assets/images/qrCard.png' // relative path to image

import { Modal } from 'antd';

import {
  MDBSpinner,
  MDBTable,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBInput,
  MDBSelect,
  MDBCollapseHeader,
  MDBIcon,
  MDBCollapse,
  MDBPagination,
  MDBPageItem,
  MDBPageNav,
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter
} from 'mdbreact';

class SubscriptionScan extends Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      subscription_number: ''
    }

    this.handleScan = this.handleScan.bind(this)
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }


  handleScan(data){

    var component = this;

    data = data.replace("'", '-');
    data = data.replace("ß", '-');


    if(data == null) {
      return;
    }

    const dataParts = data.split("-");





    console.log(data);

    var filters = component.props.searchComponent.state.filters;
    var filtersTemp = filters;

    var filtersTemp = JSON.parse(JSON.stringify(filters));

    filtersTemp['field_searchterm'] = dataParts[0];



    if(data != null) {
      component.props.searchComponent.setState({
        filters: filtersTemp
      }, function() {

        component.props.searchComponent.loadSubscriptionCollection();
        filters['field_searchterm'] = '';

        component.props.searchComponent.setState({
          filters: filters
        }, function() {

          component.toggle();

        });



      });

    }



  }
  handleError(err){
    console.error(err)
  }



  _onHandleScanError = error => {
    var component = this;
    console.error(error);
    if(error.name == "NotFoundError" || error.name == "NotAllowedError" || error.name == "OverconstrainedError") {
      var data = '0';
      alert(error);
    }
  }

  _onHandleScanSuccess = data => {
    var component = this;

    console.log(data);

    var filters = component.props.searchComponent.state.filters;
    var filtersTemp = filters;

    var filtersTemp = JSON.parse(JSON.stringify(filters));

    filtersTemp['field_searchterm'] = data;

    filtersTemp['field_subscription_status'] = null;


    if(data != null) {
      component.props.searchComponent.setState({
        filters: filtersTemp
      }, function() {

        component.props.searchComponent.loadSubscriptionCollection();
        filters['field_searchterm'] = '';

        component.props.searchComponent.setState({
          filters: filters
        }, function() {

          component.toggle();

        });



      });

    }



  }


  render() {
    let component = this;
    return (
      <div className="subscription-scan">
        <MDBBtn color="primary" onClick={this.toggle}>
          <MDBIcon icon="qrcode" />
        </MDBBtn>
        <Modal title="Abokarte scannen" destroyOnClose={true} visible={this.state.modal} onOk={this.toggle} onCancel={this.toggle} cancelButtonProps={{ style: { display: 'none' } }} okButtonProps={{ style: { display: 'none' } }}>


          <div className="row">
            <div className="col-sm-12">

              <BarcodeReader
                onError={this.handleError}
                onScan={this.handleScan}
                />


              {/*
                <QrReader
                  className="qr-scanner"
                  delay={500}
                  onError={this._onHandleScanError}
                  onScan={this._onHandleScanSuccess}
                  style={{ width: '100%', padding: '20px' }}
                  facingMode={'front'}
                  showViewFinder={true}
                  resolution={1024}
                />
                */}
            </div>
            <div className="col-md-12">
              <img src={Subscription} className="img-fluid" />
            </div>
          </div>
      </Modal>
      </div>
    )
  }

}

export default SubscriptionScan;
