import React, {
  Component
} from "react";

import {DebounceInput} from 'react-debounce-input';

import Select from 'react-select'

import {
  MDBSpinner,
  MDBTable,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBInput,
  MDBSelect,
  MDBCollapseHeader,
  MDBIcon,
  MDBCollapse,
  MDBPagination,
  MDBPageItem,
  MDBPageNav,
  MDBBtn
} from 'mdbreact';

class LimitSelect extends Component {

  constructor(props) {
    super(props);

    this.state = {
      options: [
        {
          label: 'Zeige 10 Einräge',
          value: 10
        },
        {
          label: 'Zeige 20 Einräge',
          value: 20
        },
        {
          label: 'Zeige 30 Einräge',
          value: 30
        },
        {
          label: 'Zeige 40 Einräge',
          value: 40
        },
        {
          label: 'Zeige 50 Einräge',
          value: 50
        }
      ]
    }
  }

  componentDidMount() {
  }

  render() {
    let component = this;
    return (
      <>
      <Select
        required
        isSearchable={false}
        className="react-select"
        classNamePrefix="react-select"
        placeholder="Einträge anzeigen"
        options={this.state.options}
        isClearable={true}
        onChange={(value) => {

          if(value) {
            value = value.value;
          } else {
            value = '';
          }

          this.props.onSelectChange({
            target: {
              name: 'field_limit',
              value: value,
            },
          });
        }}
      />
      </>
    )
  }

}

export default LimitSelect;
