import React, {Component} from "react";
import {
  MDBIcon,
  MDBInput,
  MDBBtn,
  MDBFileInput,
} from "mdbreact";

import MDBFileupload from 'mdb-react-fileupload';
import { Collapse } from 'antd';



import CashRegisterSessionActions from "./actions/CashRegisterSessionActions";

import moment from 'moment';
import 'moment/locale/de';


class CashRegisterCalculator extends Component {

  constructor(props) {
    super(props);
    this.state = {
      banknotes: [
        ['1000', 0],
        ['200', 0],
        ['100', 0],
        ['50', 0],
        ['20', 0],
        ['10', 0],
        ['5', 0],
        ['2', 0],
        ['1', 0],
        ['0.50', 0],
        ['0.20', 0],
        ['0.10', 0],
        ['0.05', 0],
      ],
    }


    this._onInputChange = this._onInputChange.bind(this);
  }


  componentDidMount() {
    var component = this;
  }



  _onInputChange(e) {

    let component = this;

    var name = e.target.name;
    var value = e.target.value;

    console.log(this.state.banknotes);

    let banknotes = this.state.banknotes;
    banknotes[name][1] = value;

    console.log(banknotes);

    this.setState(prevState => ({
      banknotes: banknotes
    }), function(e) {

      let banknotesTotal = 0;

      Object.entries(component.state.banknotes).map(function(name, index){

        if (Object.entries(component.state.banknotes).length === index + 1) {
          banknotesTotal += name[1][0] * name[1][1];
          component.props.form.setState(prevState => ({
            data: {                   // object that we want to update
                ...prevState.data,    // keep all other key-value pairs
                field_cash_amount: banknotesTotal.toFixed(2)  // update the value of specific key
            }
          }))
        } else {
          banknotesTotal += name[1][0] * name[1][1];
        }

      })

    })



  }




  render() {
    let component = this;

    let banknotesCount = 0;
    let banknotesTotal = 0;

    const { Panel } = Collapse;


    return (

      <>

      <Collapse>
    <Panel header="Rechner" key="1">

      <table className="table">
        <thead>
          <tr>
            <th>Wert</th>
            <th>Anzahl</th>
            <th>Gesamt</th>
          </tr>
        </thead>
        <tbody>

          {Object.entries(this.state.banknotes).map(function(name, index){
              console.log(name);
                   return (

                     <tr>
                       <td>{name[1][0]}</td>
                       <td>
                         <MDBInput
                           outline
                           value={name[1][1]}
                           name={index}
                           label='Anzahl'
                           group
                           type='text'
                           error='wrong'
                           success='right'
                           required
                           onChange={component._onInputChange}
                         />
                       </td>
                       <td>
                           {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'CHF' }).format(name[1][0] * name[1][1])}
                       </td>
                     </tr>


                   );
                 })}



        </tbody>
        <tfoot>
          <tr>
            <td>Gesamt</td>
            <td>
              {Object.entries(this.state.banknotes).map(function(name, index){

                if (Object.entries(component.state.banknotes).length === index + 1) {
                  banknotesCount += parseInt(name[1][1]);
                  return banknotesCount;
                } else {
                  banknotesCount += parseInt(name[1][1]);
                }

                })}
            </td>
            <td>
              {Object.entries(this.state.banknotes).map(function(name, index){

                if (Object.entries(component.state.banknotes).length === index + 1) {
                  banknotesTotal += name[1][0] * name[1][1];
                  return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'CHF' }).format(banknotesTotal);
                } else {
                  banknotesTotal += name[1][0] * name[1][1];
                }

                })}
            </td>
          </tr>
        </tfoot>
      </table>

    </Panel>

  </Collapse>



      </>

    )
  }
}

export default CashRegisterCalculator;
