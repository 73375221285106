import React, {
  Component
} from 'react';

import CustomerResultRow from "./CustomerResultRow";
import Pager from 'react-pager';
import CustomerFilter from "./CustomerFilter";
import PermissionHelper from "../../../../helpers/PermissionHelper";
import CustomerActions from "./actions/CustomerActions";
import CustomerForm from "./CustomerForm";

import {
  MDBSpinner,
  MDBTable,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBInput,
  MDBSelect,
  MDBCollapseHeader,
  MDBIcon,
  MDBCollapse,
  MDBPagination,
  MDBPageItem,
  MDBPageNav,
  MDBAnimation
} from 'mdbreact';

import moment from 'moment';
import 'moment/locale/de';
import { Empty } from 'antd';


import config from '../../../../config';



class CustomerSearch extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      result: [],
      page: 0,
      limit: 10,
      total: 0,
      visiblePage: 5,
      treatmentsFilter: [],
      treatments: [],
      locationsFilter: [],
      locations: [],
      genderFilters: [],
      genders: [],
      channelsFilters: [],
      channels: [],
      roles: [],
      filters: {
        field_location: null,
        field_treatments: null,
        field_sort: 'nfd.changed',
        field_sort_direction: 'desc',
        field_limit: 10
      },
      sortOptions: [
        {
          label: 'Geändert',
          value: 'nfd.changed'
        },
        {
          label: 'Nachname',
          value: 'nf_lastname.field_lastname_value'
        }
      ]
    }

    this.formRef = React.createRef();

    this._onHandlePageChanged = this._onHandlePageChanged.bind(this);
    //console.log(props);
  }

  componentDidMount() {
    this.loadCustomerCollection();
  }

  loadCustomerCollection() {

      var component = this;

      component.setState({
        collectionLoaded: false
      });

      if(PermissionHelper._hasPermission('ignore locations') === false) {
        var selectedLocation = JSON.parse(localStorage.getItem('selectedLocation'));
        var filters = component.state.filters;
        filters.field_location = selectedLocation;
        component.setState({
          filters: filters
        });
      }

      /** build filter query **/
      CustomerActions._get('All', this.state.filters, this.state.page).then(response => {
        //  console.log(response.data.entity);
        //console.log(response.data.result);


        var result = [];
        if(response.data.result) {
          result = Object.entries(response.data.result);
        }

        var locations = response.data.locations;
        var locationsFilter = new Array();
        if(locations) {
          Object.entries(locations).forEach(function(item, key){
            var optionItem = {
              label: item[1].title[0].value,
              value: item[0]
            }

            locationsFilter.push(optionItem);
          });
        }

        if(this.state.locationsFilter.length == 0) {
          component.setState({
            locationsFilter: locationsFilter,
            locations: locations,
          });
        }


        var treatments = response.data.treatments;
        var treatmentsFilter = new Array();
        if(treatments) {
          Object.entries(treatments).forEach(function(item, key){
            //console.log(item);
            var optionItem = {
              label: item[1].name,
              value: item[1].id
            }
            treatmentsFilter.push(optionItem);
            //console.log(item);
          });
        }

        if(this.state.treatmentsFilter.length == 0) {
          component.setState({
            treatmentsFilter: treatmentsFilter,
            treatments: treatments,
          });
        }


        var genders = response.data.genders;
        var genderFilters = new Array();
        if(genders) {
          Object.entries(genders).forEach(function(item, key){
            //console.log(item);
            var optionItem = {
              label: item[1].name,
              value: item[1].id.toString()
            }
            genderFilters.push(optionItem);
            //console.log(item);
          });
        }

        if(this.state.genderFilters.length == 0) {
          component.setState({
            genderFilters: genderFilters,
            genders: genders,
          });
        }

        var channels = response.data.channels;
        var channelsFilters = new Array();
        if(channels) {
          Object.entries(channels).forEach(function(item, key){
            //console.log(item);
            var optionItem = {
              label: item[1].name,
              value: item[1].id.toString()
            }
            channelsFilters.push(optionItem);
            //console.log(item);
          });
        }

        if(this.state.channelsFilters.length == 0) {
          component.setState({
            channelsFilters: channelsFilters,
            channels: channels,
          });
        }


        component.setState({
          result: result,
          loaded: true,
          collectionLoaded: true,
          total: response.data.total
        });

      });
  }

  _onHandlePageChanged(newPage) {
    this.setState({
        page: newPage
    }, () => {
        this.loadCustomerCollection();
    });
  }

  render() {
    let component = this;
    return (
      <>

        {!this.state.loaded &&
          <div className="text-center">
            <MDBSpinner key={'customer-loader'} red />
          </div>
        }

        {component.state.loaded && (
          <>


          {PermissionHelper._hasPermission('restful post Customer') === true &&
            <>
              <CustomerForm searchComponent={this} ref={this.formRef} />
            </>
          }

            <CustomerFilter searchComponent={component} />
            <hr />
            { component.state.result.length > 0 && (
              <MDBTable responsive hover striped className={`${!component.state.collectionLoaded ? "loading" : ""}`}>
                <thead>
                  <tr>
                    <th className='th-lg'>
                      Kundennummer #
                    </th>
                    <th className='th-lg'>
                      Vorname
                    </th>
                    <th className='th-lg'>
                      Nachname
                    </th>
                    <th className='th-lg'>
                      Mobil
                    </th>
                    <th className='th-lg'>
                      Ort
                    </th>
                    <th className='th-lg'>
                      Standort
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {true && (
                    component.state.result.map(function(row, j){
                      //console.log(row)
                      return (
                        <CustomerResultRow result={row[1]}  key={'customer-result-view-' + row[1].nid + row[1].changed} searchComponent={component} />
                      )
                    })
                  )}
                </tbody>
              </MDBTable>
            )}

            {component.state.result.length == 0 && component.state.collectionLoaded &&
              <div className="empty">
                <Empty />
              </div>
            }




            {component.state.loaded && component.state.total > 10 && (
              <>
                <hr />
                <Pager
                      total={Math.ceil(this.state.total / this.state.filters.field_limit)}
                      current={this.state.page}
                      visiblePages={this.state.visiblePage}
                      titles={{ first: '<|', last: '>|' }}
                      className="pagination-sm pull-right"
                      onPageChanged={this._onHandlePageChanged}
                  />
              </>
            )}
          </>
        )}

      </>
    );
  }
}

export default CustomerSearch;
