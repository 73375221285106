import React, { Component } from 'react';
import PermissionHelper from "../../../helpers/PermissionHelper";


import {
  MDBSpinner,
  MDBTable,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBLink,
  MDBBtn,
  MDBIcon,
  MDBCardImage,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBInput,
  MDBSwitch
} from 'mdbreact';

import { Switch, Select, Modal } from 'antd';


import DiscountActions from "./actions/DiscountActions";

import './assets/style.css';


import config from '../../../config';


class DiscountForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      entity: this.props.entity,
      data: {
        title: (this.props.entity && this.props.entity.title[0]) ? this.props.entity.title[0].value : '',
        body: (this.props.entity && this.props.entity.body[0]) ? this.props.entity.body[0].value : 'Rabatt',
        field_discount_value: (this.props.entity && this.props.entity.field_discount_value[0]) ? this.props.entity.field_discount_value[0].value : '',
        field_discount_percent: (this.props.entity && this.props.entity.field_discount_percent[0]) ? this.props.entity.field_discount_percent[0].value : true,
        field_entity: (this.props.entityRef) ? this.props.entityRef : false,
      }
    }




    this.switchRef = React.createRef();

    this._onSubmit = this._onSubmit.bind(this);
    this._onFormReset = this._onFormReset.bind(this);
    this._onInputChange = this._onInputChange.bind(this);
    this._onToggleModal = this._onToggleModal.bind(this);
    this._onHandleChange = this._onHandleChange.bind(this);
    this.handleSwitchChange = this.handleSwitchChange.bind(this);
  }



  /*
  handleSwitchChange = nr => () => {

    var name = 'field_discount_percent';

    this.setState(prevState => ({
      data: {                   // object that we want to update
          ...prevState.data,    // keep all other key-value pairs
          [name]: !prevState.data.field_discount_percent  // update the value of specific key
      }
    }))


  }
  */

  handleSwitchChange(checked) {
      var name = 'field_discount_percent';
    this.setState(prevState => ({
      data: {                   // object that we want to update
          ...prevState.data,    // keep all other key-value pairs
          [name]: checked  // update the value of specific key
      }
    }))
  }

  _onToggleModal = () => {
    console.log(this.switchRef);
    this.setState({
      modal: !this.state.modal
    });
  }


  componentDidMount(){
    console.log(this.switchRef);
  }

  _onFormReset() {
    this.setState(prevState => ({
      data: {
        title: (this.props.entity && this.props.entity.title[0]) ? this.props.entity.title[0].value : '',
        body: (this.props.entity && this.props.entity.body[0]) ? this.props.entity.body[0].value : '',
        field_discount_value: (this.props.entity && this.props.entity.field_discount_value[0]) ? this.props.entity.field_discount_value[0].value : '',
        field_discount_percent: (this.props.entity && this.props.entity.field_discount_percent[0]) ? this.props.entity.field_discount_percent[0].value : true,
        field_entity: (this.props.entityRef) ? this.props.entityRef : false,
      },
      nid: false
    }))
  }


  _onInputChange(e) {

    var name = e.target.name;
    var value = e.target.value;



    if(name == 'field_discount_value' && this.state.data.field_discount_percent && value > 99) {
      alert('Discount has to be lower than 100%');
      return;
    }

    this.setState(prevState => ({
      data: {                   // object that we want to update
          ...prevState.data,    // keep all other key-value pairs
          [name]: value  // update the value of specific key
      }
    }))

  }

  _onSubmit(e) {
    var component = this;
    e.preventDefault();



    let values = {
      data: this.state.data
    }

    if(!this.state.entity) {
      DiscountActions._post(values)
        .then(response => {
          component.props.checkout._onUpdateOrder(component.props.checkout.state.order.nid);
          component._onFormReset();
          component._onToggleModal();

        });
    } else {
      values.nid = this.state.entity.nid[0].value;
      DiscountActions._patch(values)
        .then(response => {
          component.props.checkout._onUpdateOrder(component.props.checkout.state.order.nid);
          component._onFormReset();
          component._onToggleModal();

        });
    }
  }


  _onHandleChange(value) {
    this.setState(prevState => ({
      data: {                   // object that we want to update
          ...prevState.data,    // keep all other key-value pairs
          'body': value  // update the value of specific key
      }
    }))
  }


  render() {
    let component = this;

    const { Option } = Select;


    return (
      <section id="checkout-discount">
        <MDBBtn onClick={this._onToggleModal} color="danger"> <MDBIcon icon="percent" />Rabatt</MDBBtn>
          <Modal title="Rabatt" destroyOnClose={true} visible={this.state.modal} onOk={this._onToggleModal} onCancel={this._onToggleModal} cancelButtonProps={{ style: { display: 'none' } }} okButtonProps={{ style: { display: 'none' } }}>

            <form id="discount-add-form" className="text-left" onSubmit={this._onSubmit}>
              <MDBRow>
                <MDBCol>

                  <Select defaultValue="Rabatt"  style={{ width: '100%' }} onChange={this._onHandleChange}>
                    <Option value="Rabatt">Rabatt</Option>
                    <Option value="Gutschein">Gutschein</Option>
                  </Select>

                  {/*
                    <MDBInput
                      name='body'
                      label='Bezeichnung'
                      group
                      type='text'
                      error='wrong'
                      success='right'
                      required
                      outline
                      onChange={this._onInputChange}
                      value={this.state.data.body}
                    />
                    */}
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <MDBInput
                    name='field_discount_value'
                    label={'Rabattwert in ' + ((this.state.data.field_discount_percent) ? '%' : 'CHF')}
                    group
                    type='Number'
                    error='wrong'
                    success='right'
                    required
                    outline
                    onChange={this._onInputChange}
                    value={this.state.data.field_discount_value}
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <label><strong>Rabattart</strong></label><br />
                  {/*<MDBSwitch labelLeft="Nein" labelRight="Ja" ref={this.switchRef} checked={this.state.data.field_discount_percent} onChange={this.handleSwitchChange(1)} />*/}

                  <Switch onClick={(e) => { this.handleSwitchChange(e) }} checkedChildren="%" unCheckedChildren="CHF" defaultChecked />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <div className="form-action-bar">
                    {!this.state.entity &&
                      <MDBBtn color="danger" onClick={this._onFormReset} className="mr-2 hide"><MDBIcon icon="sync mr-2" /> Zurücksetzen</MDBBtn>
                    }
                    <MDBBtn color="primary" type="submit"><MDBIcon far icon="save" className="mr-2" /> Speichern</MDBBtn>
                  </div>
                </MDBCol>
              </MDBRow>
            </form>

        </Modal>
      </section>
    );
  }
}

export default DiscountForm;
