import React, { Component } from 'react';
import PermissionHelper from "../../../helpers/PermissionHelper";


import {
  MDBSpinner,
  MDBTable,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBLink,
  MDBBtn,
  MDBIcon,
  MDBCardImage,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter
} from 'mdbreact';

import DiscountForm from '../discount/DiscountForm';
import DiscountActions from '../discount/actions/DiscountActions';

import config from '../../../config';


class CheckoutDiscount extends Component {

  constructor(props) {
    super(props);


    this._onDelete = this._onDelete.bind(this);
    this._calculateDiscount = this._calculateDiscount.bind(this);
  }



  componentDidMount(){
    this._calculateDiscount();
  }


  _calculateDiscount() {

    var component = this;

    var total = this.props.total;

    console.log(total);

    Object.entries(component.props.entityRef.discountItems).map(function(row, j){

      var discountValue = row[1].field_discount_value[0].value;
      var discountSubstract = 0;

      if(row[1].field_discount_percent[0].value) {
        discountValue = (component.props.total / 100) * discountValue;
      }

      total = total-discountValue;

    })



    if(component.props.applyDiscount) {
      component.props.applyDiscount(total);
    }


  }


  _onDelete(nid) {
    var component = this;
    const r = window.confirm("Löschen Bestätigen");
    if (r === true) {

      DiscountActions._delete(nid)
        .then(response => {
          component.props.checkout._onUpdateOrder(component.props.checkout.state.order.nid);
        });
    }
  }


  render() {
    let component = this;


    return (
      <section id="checkout-discount">
        <MDBRow>
          <MDBCol sm="9" className="d-flex justify-content-end">
            {!component.props.entityRef.discountItems[0] &&
              <span>
                <DiscountForm checkout={component.props.checkout} entityRef={component.props.entityRef.nid} />
              </span>
            }

          </MDBCol>
          <MDBCol sm="3">
          </MDBCol>
        </MDBRow>

        {true && (
          Object.entries(component.props.entityRef.discountItems).map(function(row, j){
            console.log(row)
            return (
              <MDBRow>
                <MDBCol sm="9">
                  <strong>Rabatt: {row[1].body[0].value}</strong>

                  {PermissionHelper._hasPermission('restful delete Discount') === true &&
                    <>
                      <MDBBtn className="btn-delete" color="danger" onClick={() => { component._onDelete(row[1].nid[0].value) }} flat><MDBIcon far icon="trash-alt" /></MDBBtn>
                    </>
                  }
                </MDBCol>
                <MDBCol sm="3">
                  {row[1].field_discount_percent[0].value &&
                    <span>{row[1].field_discount_value[0].value} %</span>
                  }

                  {!row[1].field_discount_percent[0].value &&
                    <span>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'CHF' }).format(row[1].field_discount_value[0].value)}</span>
                  }
                </MDBCol>
              </MDBRow>
            )
          })
        )}

      </section>
    );
  }
}

export default CheckoutDiscount;
