import React, { Component } from 'react';


/** import config **/
import { MDBTable, MDBTableBody, MDBTableHead, MDBContainer, MDBRow, MDBCol, MDBStepper, MDBStep, MDBBtn, MDBInput, MDBSpinner, MDBCard, MDBCardBody, MDBModal, MDBModalHeader, MDBModalBody, MDBIcon } from "mdbreact";
import { connect } from 'react-redux';
import { withTranslation, initReactI18next } from "react-i18next";


import KlarnaActions from './actions/KlarnaActions';




class KlarnaCustomerDisplay extends Component {

  constructor(props) {
    super(props);


    this._onKlarnaAuthorize = this._onKlarnaAuthorize.bind(this);
    this._onInitDistribute = this._onInitDistribute.bind(this);
    this._onInitPayment = this._onInitPayment.bind(this);
 }

  componentDidMount() {

    //this._onInitPayment();
    this._onInitDistribute();
  }


  componentWillUnmount() {
    let klarnaIframe = document.getElementById("klarna-hpp-payment-distribution-iframe");

    if(klarnaIframe) {
      document.getElementById("klarna-hpp-payment-distribution-iframe").remove();
    }
  }



  _onInitDistribute() {

    let component = this;
    let order = this.props.customerDisplay.state.order;
    let distributionView;

    if(order && order.klarnaResponse) {

      const Klarna = window.Klarna;
      const KlarnaOpened = window.KlarnaOpened;
      let klarnaHasInit = false;

      try {
        Klarna.HPP.Distribution.init();
        klarnaHasInit = true;
      } catch (err) {
        console.log(err);
        if(err == 'SDKAlreadyInitedError: The HPP Distribution Module SDK is already initialized.') {
          klarnaHasInit = true;
        }
        // Handle error.
      }

      console.log(klarnaHasInit);
      console.log(window.KlarnaOpened);
      let klarnaIframe = document.getElementById("klarna-hpp-payment-distribution-iframe");

      if(klarnaIframe) {
        document.getElementById("klarna-hpp-payment-distribution-iframe").remove();
      }

      if(klarnaHasInit) {
        distributionView = Klarna.HPP.Distribution.create({
          token: order.klarnaResponse.distribution_module.token,
          profile_id: "ac0ef585-b1b1-4951-ab0f-f250f44f6b08",
          type: Klarna.HPP.Distribution.FULLSCREEN
        })


        distributionView.on(Klarna.HPP.Distribution.Event.CLIENT_ERROR, function(error) {
          console.log('client-error', error)
        })



        distributionView.on(Klarna.HPP.Distribution.Event.CLOSE, function(response) {
          component.props.customerDisplay.state.connection.send('hello world');
          console.log('close', response);
          window.KlarnaOpened = false;
          if(response.final) {
            if(response.approved) {
            }
            else {
              // handle case where payment can't be made with Klarna...
            }
          }
          else {
            // payment still needs to reach a final state...
          }
        })

        distributionView.open(function callback(error) {
          if(error) {
            console.log('view could not be opened')
          }
          else {
            console.log('view opened');
            window.KlarnaOpened = true;
          }
        })


      }

    } else {

      if(distributionView) {
        distributionView.close(function(error) {
          if(error) {
            console.log('view could not be closed')
          }
          else {
            console.log('view closed');
            window.KlarnaOpened = false;
          }
        })
      }

    }
  }

  _onInitPayment() {
    localStorage.setItem('user', this.props.order.cashier)

    let component = this;

    let order = this.props.order;

    if(false && order && order.klarnaResponse) {
      console.log(order.klarnaResponse);
      const Klarna = window.Klarna;
      console.log(Klarna);
      //let client_token =  order.klarnaResponse.client_token;
      let client_token =  order.klarnaResponse.redirect.data['klarnapayments.client_token'];


      Klarna.Payments.init({
      client_token: client_token
      })

      try {
        Klarna.Payments.init({
          client_token: client_token
        })
      } catch (e) {
        // Handle error.
      }

      Klarna.Payments.load(
        {
          container: "#klarna-payments-container",
          payment_method_category: "pay_later",
        }, function(res) {
          console.log(res);



        }
      );
    }
  }

  _onKlarnaAuthorize() {

    let component = this;
    const Klarna = window.Klarna;

    Klarna.Payments.authorize({
      payment_method_category: "pay_later"
    },{}, function(res) {



      console.log(res);
      Klarna.Payments.finalize(
        {payment_method_category: 'pay_later'},
        {},
        function(res) {

          console.log(res);


          let values = {
            'method': 'placeOrder',
            'order_id': component.props.order.nid,
            'amount': component.props.order.totals.total,
            'authorization_token': res.authorization_token,
            'paymentData': component.props.order.klarnaResponse.paymentDatadetails,
            'details': component.props.order.klarnaResponse
          }

          console.log(values);
          console.log(res.approved);

          if(res.approved) {
            KlarnaActions._post(values)
              .then(response => {
                console.log(response);
              })
          }

          console.log(res);
        // res = {
        //   show_form: true,
        //   approved: true,
        //   authorization_token: ...
        // }
      })
    })
  }


  render() {


    return (
      <></>
    );
  }
}

export default KlarnaCustomerDisplay;
