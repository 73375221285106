import React, { Component } from 'react';


/** import config **/
import config from '../../../config';
import { MDBContainer, MDBRow, MDBCol, MDBStepper, MDBStep, MDBBtn, MDBInput, MDBSpinner } from "mdbreact";
import { connect } from 'react-redux';
import { withTranslation, initReactI18next } from "react-i18next";


import userActions from '../../../actions/userActions';

import {CheckoutOdoo} from '../checkout/CheckoutOdoo';


import {DashboardSteps} from './DashboardSteps';


/** import styles **/
import '../../../assets/css/sections/dashboard.css';
import '../../../assets/css/sections/checkout.css';





class Dashboard extends Component {

  constructor(props) {
    super(props);

    this.state = {
    }
 }

  componentDidMount() {
    console.log(this);
  }


  render() {
    return (
      <section id="dashboard">
        <DashboardSteps application={this.props.application} />
      </section>
    );
  }
}

export default Dashboard;
