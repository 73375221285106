import React, {useState,useEffect} from 'react';
import {
  MDBCol,
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
  MDBLink,
  MDBView,
  MDBMask,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBRow,
  MDBIcon
} from 'mdbreact';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as AllLightIcons from '@fortawesome/pro-light-svg-icons'

import PermissionHelper from "../../../../helpers/PermissionHelper";

import CashRegisterIcon from '../../../../assets/images/kasse-icon.svg' // relative path to image


import moment from 'moment';
import 'moment/locale/de';



class CashRegisterResultRow extends React.Component {

  constructor(props) {
    super(props);
    this._onSelect = this._onSelect.bind(this);
  }


  componentDidMount() {
    if(this.props.searchComponent.state.result.length == 1) {
      this._onSelect(this.props.result.node);
    }
  }


  _onSelect(entity) {
    console.log(this.props.searchComponent);
    localStorage.setItem('selectedCashRegister', JSON.stringify(entity));
    this.props.searchComponent.props.stepper.setState({
      ['formActivePanel' + 1]: 2,
      ['formActivePanel' + 1 + 'Changed']: true,
      ['selectedCashRegister']: entity,
    });
  }

  render() {
    return (
      <li className="cashregister-item" onClick={() => this._onSelect(this.props.result.node)}>
        <div className="icon">
          <img src={CashRegisterIcon} className="img-fluid" />
        </div>
        <div className="title">
          #{ this.props.result.node.field_cash_register_number[0].value }
        </div>

      </li>
    );
  }
}

export default CashRegisterResultRow;
