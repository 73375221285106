import React, { Component } from 'react';
import QRCode from "react-qr-code";
import moment from 'moment';
import 'moment/locale/de';


export class ReceiptPrint extends React.PureComponent {
  render() {
    const receipt = require('receipt');
    receipt.config.currency = 'CHF ';
    receipt.config.width = 31;
    receipt.config.ruler = '-';



    var productLines = new Array();


    Object.entries(this.props.order.orderItems).map(function(row, j){
      console.log(row)
      var line = {
        item: row[1].node.title[0].value,
        qty: row[1].node.field_qty[0].value,
        cost: Number(row[1].node.field_price[0].value).toFixed(2).replace('.', ""),
      }


      if(row[1].discountItems[0]) {

        var discountValue = row[1].discountItems[0].field_discount_value[0].value;
        var discountMessage = 'Rabatt';

        if(row[1].discountItems[0].field_discount_percent[0].value) {
          discountValue = row[1].node.field_qty[0].value * (row[1].node.field_price[0].value / 100) * row[1].discountItems[0].field_discount_value[0].value;
          discountMessage += ' ' + Number(row[1].discountItems[0].field_discount_value[0].value).toFixed(0) + '%/-' + discountValue.toFixed(2) + ' CHF';
        } else {
          discountMessage += ' -' + discountValue + ' CHF)';
        }

        discountValue = Number(discountValue);

        line.discount = { type: 'absolute', value: discountValue.toFixed(2).replace('.', ""), message: discountMessage  };
      }

      console.log(line);

      productLines.push(line);
    })


    var transactionLines = {
      type: 'properties', lines: []
    }

    var paymentMethods = this.props.order.paymentMethodsArray;



    Object.entries(this.props.order.orderTransactions).map(function(row, j){
      console.log(row);

      var transactionPaymentMethod = paymentMethods.find(function(value, index) {
        console.log(value)
        if(value.id == row[1].field_payment_method[0].target_id) {
          return value;
        }
      });

      if(transactionPaymentMethod.field_is_adyen == "1") {
        var PaymentResponse = JSON.parse(row[1].field_payment_response[0].value);
        console.log(PaymentResponse);

        var PaymentReceipt = PaymentResponse.PaymentReceipt;
        var CustomerReceipt = PaymentReceipt[1];

        var aid = '';

        CustomerReceipt.OutputContent.OutputText.forEach((row, index) => {
          if(row.Text.includes("aid")) {
            aid = row.Text;
            console.log(aid);
            aid = aid.split('&');
            console.log(aid);
            aid = aid[1];
            console.log(aid);
            aid = aid.split('=');
            console.log(aid);
            aid = aid[1];
          }
        });


        var line = { name: 'Tansaction ' + (j + 1), value: new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'CHF' }).format(PaymentResponse.PaymentResult.AmountsResp.AuthorizedAmount)  };
        transactionLines.lines.push(line);

        var line = { name: 'Trm-ID', value: PaymentResponse.PaymentResult.PaymentAcquirerData.AcquirerPOIID };
        transactionLines.lines.push(line);
        var line = { name: 'Trx.Seq-Cnt', value: PaymentResponse.SaleData.SaleTransactionID.TransactionID };
        transactionLines.lines.push(line);

        if(aid) {
          var line = { name: 'AID',  value: aid};
          transactionLines.lines.push(line);
        }

        if(PaymentResponse.PaymentResult.PaymentInstrumentData.CardData.MaskedPan) {
          var line = { name: 'Masked Nr', value: PaymentResponse.PaymentResult.PaymentInstrumentData.CardData.MaskedPan };
          transactionLines.lines.push(line);
        }

        var line = { name: 'Type', value: PaymentResponse.PaymentResult.PaymentInstrumentData.CardData.EntryMode[0] };
        transactionLines.lines.push(line);
        var line = { name: 'Brand', value: PaymentResponse.PaymentResult.PaymentInstrumentData.CardData.PaymentBrand };
        transactionLines.lines.push(line);



        var line = { name: '----------', value: '----------' };
        transactionLines.lines.push(line);
      }

      if(transactionPaymentMethod.field_cash_payment == "1") {
        var line = { name: 'Tansaction ' + (j + 1), value: new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'CHF' }).format(row[1].field_amount[0].value)  };
        transactionLines.lines.push(line);
        var line = { name: 'Type', value: 'Barzahlung' };
        transactionLines.lines.push(line);
        var line = { name: '----------', value: '----------' };
        transactionLines.lines.push(line);
      }

      if(transactionPaymentMethod.field_is_giftcard == "1") {
        var line = { name: 'Tansaction ' + (j + 1), value: new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'CHF' }).format(row[1].field_amount[0].value)  };
        transactionLines.lines.push(line);
        var line = { name: 'Type', value: 'Gutschein' };
        transactionLines.lines.push(line);
        var line = { name: '----------', value: '----------' };
        transactionLines.lines.push(line);
      }

      if(transactionPaymentMethod.field_is_subscription == "1") {
        var line = { name: 'Tansaction ' + (j + 1), value: new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'CHF' }).format(row[1].field_amount[0].value)  };
        transactionLines.lines.push(line);
        var line = { name: 'Type', value: 'Abo' };
        transactionLines.lines.push(line);
        var line = { name: '----------', value: '----------' };
        transactionLines.lines.push(line);
      }

      if(transactionPaymentMethod.field_is_powerpay == "1") {
        var line = { name: 'Tansaction ' + (j + 1), value: new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'CHF' }).format(row[1].field_amount[0].value)  };
        transactionLines.lines.push(line);
        var line = { name: 'Type', value: 'Kauf auf Rechnung' };
        transactionLines.lines.push(line);
        var line = { name: '----------', value: '----------' };
        transactionLines.lines.push(line);
      }

      if(transactionPaymentMethod.field_is_klarna == "1") {

        var line = { name: 'Tansaction ' + (j + 1), value: new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'CHF' }).format(row[1].field_amount[0].value)  };
        transactionLines.lines.push(line);
        var line = { name: 'Type', value: 'Klarna' };
        transactionLines.lines.push(line);
        var line = { name: 'Klara reference', value: row[1].field_tender_reference[0].value };
        transactionLines.lines.push(line);
        var line = { name: '----------', value: '----------' };
        transactionLines.lines.push(line);
      }
    })





    var totalLines = new Array();

    var subTotalLine = { name: 'Zwischensumme', value: new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'CHF' }).format(this.props.order.totals.subtotal) }
    totalLines.push(subTotalLine);


    //var taxTotalLine = { name: 'Steuer', value: new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'CHF' }).format(this.props.order.totals.tax) };
    //totalLines.push(taxTotalLine);


    Object.entries(this.props.order.totals.taxTotalRates).map(function(row, j){
      var taxLine = {
        name: 'Steuer ' + row[0] + '%',
        value: new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'CHF' }).format(row[1])
      }
      totalLines.push(taxLine);
    })


    var totalTotalLine = { name: 'Gesamt', value: new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'CHF' }).format(this.props.order.totals.total) };
    totalLines.push(totalTotalLine);

    console.log(this.props.order.totals.taxTotalRates);

    const output = receipt.create([
        { type: 'text', value: [
          'Venus Beauty AG',
          'Bäumlistrasse 32',
          '8404 Winterthur',
          'info@venus-beauty.ch',
          'www.venus-beauty.ch',
          'CHE-261.630.740 MWST'
        ], align: 'center' },
        { type: 'empty' },
        { type: 'properties', lines: [
            { name: 'Bestellnr.', value: this.props.order.node.field_order_number[0].value },
            { name: 'Datum', value:  moment(new Date(this.props.order.node.created[0].value)).tz('Europe/Zurich').format("DD.MM.YYYY HH:mm") },
            { name: 'Kassierer', value:  JSON.parse(localStorage.getItem('user')).field_firstname[0].value + ' ' + JSON.parse(localStorage.getItem('user')).field_lastname[0].value},
            { name: 'Standort', value:  JSON.parse(localStorage.getItem('selectedLocation')).title[0].value}
        ] },
        { type: 'table', lines: productLines },
        { type: 'empty' },
        { type: 'properties', lines:totalLines},
        { type: 'empty' },
        { type: 'properties', lines: [
            { name: 'Rabatt gesamt', value: new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'CHF' }).format(this.props.order.totals.totalNoDiscount - this.props.order.totals.total) },
        ] },
        { type: 'empty' },
        { type: 'properties', lines: [
            { name: 'Zahlung', value: new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'CHF' }).format(this.props.order.totals.paid) },
        ] },
        { type: 'empty' },
        { type: 'properties', lines: [
            { name: 'Rückgeld', value: new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'CHF' }).format(this.props.order.totals.paid - this.props.order.totals.total) },
        ] },
        { type: 'empty' },
        transactionLines,
        { type: 'empty' },
        { type: 'empty' },
        { type: 'text', value: 'Vielen Dank für Ihren Besuch.', align: 'left' },
        { type: 'empty' },
    ]);


    return (
      <>
      <pre>
        <img src="https://www.venus-beauty.ch/themes/venusbeauty/images/logo.svg" className="order-logo" />
        {output}

        <div className="order-qr" style={{padding: '10px'}}>
          <QRCode size={120} value={this.props.order.node.field_order_number[0].value.toString()} />
        </div>
      </pre>

      </>
    );
  }
}
