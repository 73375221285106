import i18n from "i18next";
import { initReactI18next } from 'react-i18next';

import translationFR from './assets/locales/fr/translation.json';
import translationIT from './assets/locales/it/translation.json';
import translationDE from './assets/locales/de/translation.json';

// the translations
const resources = {
  de: {
    translation: translationDE
  },
  fr: {
    translation: translationFR
  },
  it: {
    translation: translationIT
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: (localStorage.getItem('lang') ? localStorage.getItem('lang') : 'de'),
    fallbackLng: 'de',

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
