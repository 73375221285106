import React, { Component } from 'react';
import Pager from 'react-pager';
import SubscriptionResultRow from "./SubscriptionResultRow";
import SubscriptionActions from "./actions/SubscriptionActions";
import Image from "../../image/Image";
import SubscriptionForm from "./SubscriptionForm";
import {
  MDBSpinner,
  MDBTable,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBLink,
  MDBBtn,
  MDBIcon,
  MDBCardImage
} from 'mdbreact';
import { Empty } from 'antd';

import config from '../../../../config';

class SubscriptionSearch extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      result: [],
      treatmentTypes: [],
      subscriptionCategories: [],
      page: 0,
      limit: 10,
      total: 0,
      visiblePage: 5,
      filters: {
        field_limit: 10,
        field_customer: this.props.nid,
        field_treatment_type: this.props.treatmentType,
        field_searchterm: '',
        field_sort: 'nfd.changed',
        field_sort_direction: 'desc',
      }
    }

    this.formRef = React.createRef();

    this._onHandlePageChanged = this._onHandlePageChanged.bind(this);
    this.loadSubscriptionCollection = this.loadSubscriptionCollection.bind(this);
  }

  _onHandlePageChanged(newPage) {
    this.setState({
        page: newPage
    }, () => {
        this.loadSubscriptionCollection();
    });
  }

  componentDidMount(){
    this.loadSubscriptionCollection();
  }

  loadSubscriptionCollection() {
    var component = this;


    component.setState({
      loaded: false,
      collectionLoaded: false
    });

    /** build filter query **/
    SubscriptionActions._get('All', this.state.filters, this.state.page).then(response => {
      var result = [];
      if(response.data.result) {
        result = Object.entries(response.data.result);
      }
      component.setState({
        result: result,
        subscriptionCategories: response.data.subscriptionCategories,
        treatmentTypes: response.data.treatmentTypes,
        collectionLoaded: true,
        loaded: true,
        total: response.data.total
      });
    });
  }

  render() {
    let component = this;
    return (
      <div id="subscription-bar">
        {!this.state.loaded &&
          <div className="text-center"><MDBSpinner key={'subscription-loader'} red /></div>
        }

        {component.state.loaded && (
          <>

            <SubscriptionForm searchComponent={this} ref={this.formRef} nid={this.props.nid} customer={this.props.nid} />

            {component.state.result.length > 0 && (
              <MDBTable responsive hover className={`${!component.state.collectionLoaded ? "loading" : ""}`}>
                <thead>
                  <tr>
                    <th className='th-lg'>
                      Abo Nr.
                    </th>
                    <th className='th-lg'>
                      Abo Typ
                    </th>
                    <th className='th-lg'>
                      Behandlungsart
                    </th>
                    <th className='th-lg'>
                      Stempel
                    </th>
                    <th className='th-sm'>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {true && (
                    component.state.result.map(function(row, j){
                      //console.log(row)
                      return (
                        <SubscriptionResultRow result={row[1]}  key={'subscription-result-view-' + row[1].nid + row[1].changed} searchComponent={component} />
                      )
                    })
                  )}
                </tbody>
              </MDBTable>
            )}
            {component.state.result.length == 0 &&
              <div className="empty">
                <Empty />
              </div>
            }
            {component.state.loaded && component.state.total > 10 && (
              <>
                <hr />
                <Pager
                      total={Math.ceil(this.state.total / this.state.limit)}
                      current={this.state.page}
                      visiblePages={this.state.visiblePage}
                      titles={{ first: '<|', last: '>|' }}
                      className="pagination-sm pull-right"
                      onPageChanged={this._onHandlePageChanged}
                  />
              </>
            )}
          </>
        )}
      </div>
    );
  }
}

export default SubscriptionSearch;
