import React from 'react';
import {
  MDBSideNavLink,
  MDBSideNavNav,
  MDBSideNav,
  MDBIcon,
  MDBSelect,
  MDBLink,
  MDBSideNavCat
} from 'mdbreact';
import { connect } from 'react-redux';
import userActions from '../actions/userActions';
import Logo from '../assets/images/logoTop.svg' // relative path to image

import PermissionHelper from "../helpers/PermissionHelper";

import Select from 'react-select';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as AllLightIcons from '@fortawesome/pro-light-svg-icons'


class SideNavigation extends React.Component {

  constructor(props) {
    super(props);

    //console.log(props);
    this.state = {
      isOpen: false,
      locationOptions: this.props.locations,
      selectedLocation: localStorage.getItem('selectedLocation'),
      selectedLocationOption: false,
      buttonStyle: {
        transform: "scaleY(0.4) scaleX(0.4) translateY(40px) translateX(0)",
        opacity: "0"
      }
    }

    this.locationSelect = React.createRef();

    this._onLocationChange = this._onLocationChange.bind(this);
    this._onLogout = this._onLogout.bind(this);
  }

  componentDidMount() {
    var component = this;
    //console.log(this);
    //console.log(localStorage.getItem('selectedLocation'));

    var locations = this.props.locations;
    var locationOptions = [];
    //console.log(locations);
    var selectedLocationOption = false;

    if(this.props.locations) {
      locations.forEach(function(item){
        var optionItem = {
          label: item.title[0].value,
          value: item.nid[0].value.toString()
        }
        var selectedLocation = localStorage.getItem('selectedLocation');



        if(selectedLocation == item.nid[0].value.toString()) {

          //console.log(selectedLocation);
          //console.log(item.nid[0].value.toString());

          selectedLocationOption = {
            label: item.title[0].value,
            value: item.nid[0].value.toString()
          }

          //console.log(selectedLocationOption);

          component.setState({
            selectedLocationOption: selectedLocationOption
          });
        }
        locationOptions.push(optionItem);
        //console.log(optionItem);
      });

      this.setState({
        locationOptions: locationOptions,
      });
    }
  }

  componentWillUnmount() {
  }

  _onLocationChange(value) {
    //console.log(value);
    if(value != this.state.selectedLocation && value && value != '') {
      this.setState({
        selectedLocation: value
      });
      localStorage.setItem('selectedLocation', value);
      window.location.reload();
    }
  };


  _onLogout(e) {
    var component = this;
    component.props.logout();

  }

  render() {
    //console.log(user);
    const { onLinkClick } = this.props;
    var component = this;
    return (
      <div className='white-skin' id="side-navigation">

        <MDBSideNav
          fixed
        >
          <li>
            <div className="page-logo">
              <MDBLink to={`/dashboard`} className="d-inline">
                <img alt="" src={Logo} className="img-fluid" />
              </MDBLink>
            </div>
          </li>

          <MDBSideNavNav>
            <MDBSideNavLink topLevel to='/dashboard' onClick={onLinkClick}>
              <FontAwesomeIcon icon={AllLightIcons.faCashRegister} />
              Kasse
            </MDBSideNavLink>


            <MDBSideNavLink topLevel to='/giftcard' onClick={onLinkClick}>
              <FontAwesomeIcon icon={AllLightIcons.faGiftCard} />
              Gutscheine
            </MDBSideNavLink>

            <MDBSideNavLink topLevel to='/subscription' onClick={onLinkClick}>
              <FontAwesomeIcon icon={AllLightIcons.faGiftCard} />
              Abos
            </MDBSideNavLink>
          



            <MDBSideNavLink topLevel to='/order' onClick={onLinkClick}>
              <FontAwesomeIcon icon={AllLightIcons.faList} />
              Bestellungen
            </MDBSideNavLink>

            <MDBSideNavLink topLevel to='/statistic' onClick={onLinkClick}>
              <FontAwesomeIcon icon={AllLightIcons.faChartBar} />
              Statistik
            </MDBSideNavLink>

            {/*

              <MDBSideNavLink topLevel to='/product' onClick={onLinkClick}>
                <FontAwesomeIcon icon={AllLightIcons.faList} />
                Produkte
              </MDBSideNavLink>
              <MDBSideNavLink topLevel to='/category' onClick={onLinkClick}>
                <FontAwesomeIcon icon={AllLightIcons.faList} />
                Kategorien
              </MDBSideNavLink>
              <MDBSideNavLink topLevel to='/cashregister' onClick={onLinkClick}>
                <FontAwesomeIcon icon={AllLightIcons.faCashRegister} />
                Kassen
              </MDBSideNavLink>
              <MDBSideNavLink topLevel to='/statistic' onClick={onLinkClick}>
                <FontAwesomeIcon icon={AllLightIcons.faUserChart} />
                Statistik
              </MDBSideNavLink>
              */}


          </MDBSideNavNav>


          <button className="btn btn-logout" onClick={this._onLogout}>
            <FontAwesomeIcon icon={AllLightIcons.faPowerOff} />
          </button>




        </MDBSideNav>
      </div>
    );
  }
}

function mapState(state) {
    const { authenticationReducer } = state;
    const { user, locations } = authenticationReducer;
    return { user, locations };
}

const actionCreators = {
  logout: userActions.logout
}

const connectedSideNavigation = connect(mapState, actionCreators)(SideNavigation);
export { connectedSideNavigation as SideNavigation };
