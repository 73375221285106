import React, {useState,useEffect} from 'react';
import {
  MDBCol,
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
  MDBLink,
  MDBView,
  MDBMask,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBRow,
  MDBIcon
} from 'mdbreact';

import LoadingOverlay from 'react-loading-overlay'
import BounceLoader from 'react-spinners/BounceLoader'

import TerminalActions from "./actions/TerminalActions";

import PermissionHelper from "../../../../helpers/PermissionHelper";

import moment from 'moment';
import 'moment/locale/de';



class TerminalResultRow extends React.Component {

  constructor(props) {
    super(props);


    this.state = {
      showLoader: false
    }
  }


  componentDidMount() {
    document.addEventListener("keydown", this._onKeyDown, false);
  }

  componentWillUnmount(){
    document.removeEventListener("keydown", this._onKeyDown, false);
  }


  _onKeyDown(e){
    console.log(e);


    if(e.code.includes("Digit") || e.code.includes("Numpad")) {

    } else {
      e.preventDefault();
    }

  }

  _onSelect(terminal) {

    this.setState({
      showLoader: true
    });

    this.props.searchComponent.props.checkoutPayment._onHandleTerminalPayment(terminal).then(response => {

      console.log(response);

      this.setState({
        showLoader: false
      });
    })
  }

  render() {

    console.log(this.props.result);

    return (
      <tr className="terminal-item">


        <td>#{ this.props.result.node.field_terminal_number[0].value }</td>
        <td>{ this.props.result.node.field_poiid[0].value }</td>
        <td className="text-right">
          <div className="action-bar">
            <MDBBtn color="primary" onClick={() => this._onSelect(this.props.result.node)}>
              <MDBIcon icon="mouse-pointer" />
            </MDBBtn>
          </div>

          {this.state.showLoader &&
            <>
            <LoadingOverlay
                active={true}
                 text='Warte auf Zahlung...'
              >
              </LoadingOverlay>
            </>
          }


        </td>
      </tr>
    );
  }
}

export default TerminalResultRow;
