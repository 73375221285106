import React, {Component} from "react";
import {
  MDBContainer,
  MDBCollapse,
  MDBCard,
  MDBCardBody,
  MDBCollapseHeader,
  MDBIcon,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBSpinner,
  MDBFileInput,
  MDBSelect,
  MDBTooltip,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBAlert
} from "mdbreact";
import { Modal } from 'antd';

import MDBFileupload from 'mdb-react-fileupload';

import Select from 'react-select';

import ReactToPrint from 'react-to-print';


import CreditMemoActions from "./actions/CreditMemoActions";

import CreditMemoReceipt from "../receipt/CreditMemoReceipt";

import moment from 'moment';
import 'moment/locale/de';


class CreditMemoForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      hasSubmitted: false,
      modal: false,
      entity: this.props.entity,
      creditMemoResponse: false,
      data: {
        field_order: this.props.searchComponent.props.orderId,
        field_order_item: null,
        field_cash_register_session: JSON.parse(localStorage.getItem('selectedCashRegisterSession')).node.nid[0].value,
        //field_order_transaction: null,
        field_qty: 0,
        field_amount: 0,
        body: ''
      }
    }



    this.printReceiptRef = React.createRef();


    this._onSubmit = this._onSubmit.bind(this);
    this._onFormReset = this._onFormReset.bind(this);
    this._onToggleModal = this._onToggleModal.bind(this);
    this._onInputChange = this._onInputChange.bind(this);
  }


  componentDidMount() {
    var component = this;

    var orderItemSelect = new Array();
    Object.entries(this.props.searchComponent.props.orderItems).forEach(function(item, key){

      var optionItem = {
        label: item[1].node.title[0].value + ' (' + item[1].node.field_qty[0].value + ' x ' + item[1].node.field_price[0].value + ')',
        value: item[1].node.nid[0].value
      }
      orderItemSelect.push(optionItem);
      console.log(item);
    });



    var orderTransactionSelect = new Array();
    Object.entries(this.props.searchComponent.props.orderTransactions).forEach(function(item, key){

      var optionItem = {
        label: component.props.searchComponent.props.paymentMethods.find(x => x.id === item[1].field_payment_method[0].target_id.toString()).name + ' (' + item[1].field_amount[0].value  + ')',
        value: item[1].nid[0].value
      }


      orderTransactionSelect.push(optionItem);
      console.log(item);
    });

    this.setState(prevState => ({
      orderItemSelect: orderItemSelect,
      orderTransactionSelect: orderTransactionSelect
    }))
  }


  _onSubmit(e) {
    var component = this;
    e.preventDefault();


    component.setState(prevState => ({
      hasSubmitted: true,
    }))

    let values = {
      data: this.state.data
    }


    if(!this.state.entity) {
      CreditMemoActions._post(values)
        .then(response => {
          console.log(response);

          component.setState({
              creditmemo:response.data.creditmemo,
              order:response.data.creditmemo.order,
          },() => {
              console.log(component.printReceiptRef);
              component.printReceiptRef.current.ref.handleClick();
              component._onFormReset();
              component._onToggleModal();
              component.props.searchComponent.loadCreditMemoCollection();

              component.setState(prevState => ({
                hasSubmitted: false,
              }))

          });





        }).catch(function (error) {
          component._onFormReset();
          component._onToggleModal();
          component.props.searchComponent.loadCreditMemoCollection();
          component.setState(prevState => ({
            hasSubmitted: false,
          }))
        });
    } else {
      values.nid = this.state.entity.nid[0].value;
      CreditMemoActions._patch(values)
        .then(response => {
          component._onFormReset();
          component._onToggleModal();
          component.props.searchComponent.loadCreditMemoCollection();

          component.setState(prevState => ({
            hasSubmitted: false,
          }))
        }).catch(function (error) {
          component._onFormReset();
          component._onToggleModal();
          component.props.searchComponent.loadCreditMemoCollection();

          component.setState(prevState => ({
            hasSubmitted: false,
          }))
        });;
    }
  }

  _onFormReset() {
    this.setState(prevState => ({
      hasSubmitted: false,
      data: {
        field_order: this.props.searchComponent.props.orderId,
        field_order_item: null,
        field_cash_register_session: JSON.parse(localStorage.getItem('selectedCashRegisterSession')).node.nid[0].value,
        //field_order_transaction: null,
        field_qty: 0,
        field_amount: 0,
        body: ''
      },
      nid: false
    }))
  }

  _onToggleModal = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  _onSelectChange(e) {
    var name = e.target.name;
    var value;
    if(e.target.value) {
      value = e.target.value;
    } else {
      value = null;
    }

    this.setState(prevState => ({
      data: {                   // object that we want to update
          ...prevState.data,    // keep all other key-value pairs
          [e.target.name]: [e.target.value],  // update the value of specific key
      }
    }))

  }

  _onInputChange(e) {

    var name = e.target.name;
    var value = e.target.value;

    this.setState(prevState => ({
      data: {                   // object that we want to update
          ...prevState.data,    // keep all other key-value pairs
          [name]: value  // update the value of specific key
      }
    }))

  }

  render() {
    let component = this;
    return (

      <>




        {!this.props.entity &&
          <MDBBtn onClick={this._onToggleModal} color="primary"><MDBIcon icon="plus mr-2" /> Neue Gutschrift erstellen</MDBBtn>
        }

        {this.props.entity &&
          <MDBBtn onClick={this._onToggleModal} color="primary"><MDBIcon far icon="edit" /></MDBBtn>
        }

        <Modal title="Formular" destroyOnClose={true} visible={this.state.modal} onOk={this._onToggleModal} onCancel={this._onToggleModal} cancelButtonProps={{ style: { display: 'none' } }} okButtonProps={{ style: { display: 'none' } }}>

            <form id="creditmemo-add-form" onSubmit={this._onSubmit}>

              <MDBRow>
                <MDBCol>
                  <MDBInput
                    name='body'
                    label='Notiz'
                    group
                    type='textarea'
                    error='wrong'
                    success='right'
                    required
                    outline
                    onChange={this._onInputChange}
                    value={this.state.data.body}
                  />
                </MDBCol>
              </MDBRow>

              <hr />


                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      name='field_amount'
                      label='Betrag in CHF'
                      group
                      type='text'
                      error='wrong'
                      success='right'
                      required
                      outline
                      onChange={this._onInputChange}
                      value={this.state.data.field_amount}
                    />
                  </MDBCol>
                </MDBRow>

                <hr />

                  <Select
                    required
                    isSearchable={false}
                    className="react-select"
                    classNamePrefix="react-select"
                    placeholder="Wählen einen Artikel aus"
                    options={this.state.orderItemSelect}
                    isClearable={true}
                    onChange={(value) => {

                      if(value) {
                        value = value.value;
                      } else {
                        value = '';
                      }

                      this._onSelectChange({
                        target: {
                          name: 'field_order_item',
                          value: value,
                        },
                      });
                    }}
                  />


                  <hr />

                    <Select
                      required
                      isSearchable={false}
                      className="react-select"
                      classNamePrefix="react-select"
                      placeholder="Wählen eine Transaktion aus"
                      options={this.state.orderTransactionSelect}
                      isClearable={true}
                      onChange={(value) => {

                        if(value) {
                          value = value.value;
                        } else {
                          value = '';
                        }

                        this._onSelectChange({
                          target: {
                            name: 'field_order_transaction',
                            value: value,
                          },
                        });
                      }}
                    />





                 <hr />

                 <div className="call-to-action">
                   {!this.state.entity &&
                     <MDBBtn color="danger" onClick={this._onFormReset} className="mr-2"><MDBIcon icon="sync mr-2" /> Zurücksetzen</MDBBtn>
                   }
                   <MDBBtn color="primary" disabled={this.state.hasSubmitted} type="submit"><MDBIcon far icon="save" className="mr-2" /> Speichern</MDBBtn>
                 </div>


                 {this.state.creditmemo &&
                   <>
                   <div className="hide">
                     <CreditMemoReceipt ref={component.printReceiptRef} creditMemo={this.state.creditmemo} order={this.state.order} />
                   </div>
                   </>
                 }




            </form>

      </Modal>
      </>


    );
  }
}

export default CreditMemoForm;
