import React, { Component } from 'react';
import Pager from 'react-pager';
import ProductResultRow from "./ProductResultRow";
import Image from "../../image/Image";
import ProductFilter from "./ProductFilter";
import PermissionHelper from "../../../../helpers/PermissionHelper";
import ProductActions from "./actions/ProductActions";


import {
  MDBSpinner,
  MDBTable,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBLink,
  MDBBtn,
  MDBIcon,
  MDBCardImage
} from 'mdbreact';

import config from '../../../../config';


class ProductSearch extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      result: [],
      page: 0,
      limit: 10,
      total: 0,
      visiblePage: 5,
      filters: {
        field_searchterm: '',
        field_product_category: null,
        field_is_favorite: '1',
        field_sort: 'nfd.changed',
        field_sort_direction: 'desc',
        field_visible: '1',
        field_limit: 100000
      },
      sortOptions: [
        {
          checked: true,
          text: 'Geändert',
          value: 'nfd.changed'
        },
        {
          text: 'Titel',
          value: 'nfd.title'
        }
      ]
    }


    var productCategoryData = null;

    this._onHandlePageChanged = this._onHandlePageChanged.bind(this);
    //console.log(props);
  }

  _onHandlePageChanged(newPage) {
    this.setState({
        page: newPage
    }, () => {
        this.loadProductCollection();
    });
  }

  componentDidMount(){
    this.loadProductCollection();
    //console.log(this);
  }

  loadProductCollection() {


      var component = this;

      /** build filter query **/

      ProductActions._get('All', this.state.filters, this.state.page).then(response => {

        var result = [];
        if(response.data.result) {
          result = Object.entries(response.data.result);
        }


        component.setState({
          result: result,
          collectionLoaded: true,
          total: response.data.total,
          productCategoryData: response.data.productCategoryData,
          loaded: true,
        });



      });
  }

  render() {
    let component = this;




    return (
      <section id="checkout-search">



      {this.state.loaded &&
        <>
          <ProductFilter searchComponent={component} />
          <hr />
        </>
      }



        {!this.state.loaded &&
          <div className="text-center"><MDBSpinner key={'product-loader'} red /></div>
        }

        {component.state.loaded && (
          <>

          {/*<ProductFilter searchComponent={this} /><hr />*/}

            { component.state.result.length > 0 && (


              <div class="product-list-container">
                  {true && (
                    component.state.result.map(function(row, j){
                      //console.log(row)
                      return (
                        <ProductResultRow result={row[1]}  key={'product-result-view-' + row[1].nid + row[1].changed} searchComponent={component} />
                      )
                    })
                  )}
              </div>

            )}

            {component.state.result.length == 0 &&
              <div className="empty">Keine Produkte vorhanden</div>
            }

            {false && component.state.loaded && component.state.total > 10 && (
              <>
                <hr />
                <Pager
                      total={Math.ceil(this.state.total / this.state.filters.field_limit)}
                      current={this.state.page}
                      visiblePages={this.state.visiblePage}
                      titles={{ first: '<|', last: '>|' }}
                      className="pagination-sm pull-right"
                      onPageChanged={this._onHandlePageChanged}
                  />
              </>
            )}
          </>
        )}
      </section>
    );
  }
}

export default ProductSearch;
