import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { PrivateRoute } from './PrivateRoute';

import Dashboard from './modules/dashboard/Dashboard';

import OrderOverview from './modules/order/OrderOverview';
import OrderDetail from './modules/order/OrderDetail';

import GiftCardOverview from './modules/giftcard/GiftCardOverview';

import SubscriptionOverview from './modules/subscription/SubscriptionOverview';

import Statistic from './modules/statistic/Statistic';

class Routes extends React.Component {
  render() {
    return (
      <>

        <PrivateRoute path='/' exact component={Dashboard} application={this.props.application} />
        <PrivateRoute path='/dashboard' exact component={Dashboard} application={this.props.application} />

        <PrivateRoute path='/order' exact component={OrderOverview} />
        <PrivateRoute path='/order/:id' exact component={OrderDetail} />

        <PrivateRoute path='/giftcard' exact component={GiftCardOverview} application={this.props.application} />

        <PrivateRoute path='/subscription' exact component={SubscriptionOverview} application={this.props.application} />

        <PrivateRoute path='/statistic' exact component={Statistic} application={this.props.application} />

      </>
    );
  }
}

export default Routes;
