import React, {useState,useEffect} from 'react';
import {
  MDBCol,
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
  MDBLink,
  MDBView,
  MDBMask,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBRow,
  MDBIcon
} from 'mdbreact';


import CashRegisterSessionActions from "./actions/CashRegisterSessionActions";

import CashRegisterSessionForm from "./CashRegisterSessionForm";


import PermissionHelper from "../../../../helpers/PermissionHelper";

import moment from 'moment';
import 'moment/locale/de';



class CashRegisterSessionResultRow extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false
    };

    this._onDelete = this._onDelete.bind(this);
    this._onEdit = this._onEdit.bind(this);
    this._onModalToggle = this._onModalToggle.bind(this);
  }


  componentDidMount() {
    if(this.props.searchComponent.state.result.length == 1) {
      this._onSelect(this.props.result);
    }
  }

  _onModalToggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  _onDelete(e) {
    var component = this;
    const r = window.confirm("Löschen Bestätigen");
    if (r === true) {
      component.props.searchComponent.setState(prevState => ({
        loaded: false,
      }))

      CashRegisterSessionActions._delete(this.props.result.nid)
        .then(response => {
          component.props.searchComponent.loadCashRegisterSessionCollection();
        });
    }
  }

  _onEdit(e) {

  }

  _onSelect(entity) {
    console.log(this.props.searchComponent);
    localStorage.setItem('selectedCashRegisterSession', JSON.stringify(entity));
    this.props.searchComponent.props.stepper.setState({
      ['formActivePanel' + 1]: 3,
      ['formActivePanel' + 1 + 'Changed']: true,
      ['selectedCashRegisterSession']: entity,
    });
  }

  render() {

    console.log(this.props.result);

    return (
      <tr className="cashregistersession-item">
        <td>#{ this.props.result.node.field_cash_register_session_numb[0].value }</td>
        <td>{moment(this.props.result.node.created[0].value).tz('Europe/Zurich').format("DD.MM.YYYY HH:mm")}</td>
        <td>{moment(this.props.result.node.created[0].value).tz('Europe/Zurich').fromNow()}</td>
        <td>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'CHF' }).format(this.props.result.node.field_cash_amount[0].value)}</td>
        <td className="text-right">
          <div className="action-bar">
            <MDBBtn color="primary" onClick={() => this._onSelect(this.props.result)}>
              <MDBIcon icon="mouse-pointer" />
            </MDBBtn>
          </div>
        </td>
      </tr>
    );
  }
}

export default CashRegisterSessionResultRow;
