import React, { Component } from 'react';
import Pager from 'react-pager';
import Image from "../image/Image";
import PermissionHelper from "../../../helpers/PermissionHelper";
import axios from "axios";

import QrReader from 'react-qr-reader'
import BarcodeReader from '../barcodescanner/BarcodeScanner'

import OrderTransactionActions from "../ordertransaction/actions/OrderTransactionActions";

import GiftCard from '../../../assets/images/qrCard.png' // relative path to image
import LoadingOverlay from 'react-loading-overlay'


import {
  MDBSpinner,
  MDBTable,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBLink,
  MDBBtn,
  MDBIcon,
  MDBCardImage,
  MDBAlert
} from 'mdbreact';

import config from '../../../config';


class GiftCardPayment extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showLoader: false
    }

    this.submitButtonRef = React.createRef();
  }


  componentDidMount() {
    var component = this;
    if(component.submitButtonRef.current) {
      component.submitButtonRef.current.focus();
    }
  }



  handleError = error => {
    var component = this;
    console.error(error);
    if(error.name == "NotFoundError" || error.name == "NotAllowedError" || error.name == "OverconstrainedError") {
      var data = '0';
      console.log(error);
    }
  }

  handleScan = data => {
    var component = this;


    // check if already scanned
    if(this.state.showLoader) {
      alert('Es steht noch eine Zahlung aus.');
      return;
    }

    this.setState({
      showLoader: true
    });


    data = data.replace("'", '-');
    data = data.replace("ß", '-');


    if(data == null) {
      return;
    }

    const dataParts = data.split("-");

    if(dataParts[1] == null) {
      return;
    }

    /* create order transaction with api */
    var order = this.props.checkoutPayment.props.checkout.state.order.node;

    let values = {
      data: {
        field_payment_method: this.props.checkoutPayment.state.data.field_payment_method,
        field_entity: order.nid[0].value,
        field_amount: this.props.checkoutPayment.state.data.field_amount,
        field_card_secret_key: dataParts[1]
      }
    }


    OrderTransactionActions._post(values)
      .then(response => {
        this.props.checkoutPayment._onToggleModal();
        this.props.checkoutPayment.props.checkout._onUpdateOrder(this.props.checkoutPayment.props.checkout.state.order.nid);
        console.log(response);
        this.setState({
          showLoader: false
        });
      }).catch(error => {
        console.log(error);
        this.setState({
          showLoader: false
        });
      });

  }


  render() {
    let component = this;
    return (
      <>
      <MDBRow className="giftcard-search-bar">

        <button class="invisible-position" ref={this.submitButtonRef}>test</button>

        <MDBCol>
          <hr />
          <div className="row">
            <div className="col-md-12">
              <MDBAlert color="info" >
                Bitte Gutscheinkarte scannen.
              </MDBAlert>
              {!this.state.showLoader &&
                <BarcodeReader
                  onError={this.handleError}
                  onScan={this.handleScan}
                  />
              }
              {/*
                <QrReader
                  className="qr-scanner"
                  delay={500}
                  onError={this._onHandleScanError}
                  onScan={this._onHandleScanSuccess}
                  style={{ width: '100%' }}
                  facingMode={'environment'}
                  showViewFinder={true}
                  resolution={1024}
                />
                */}
            </div>
            <div className="col-md-12 text-center">
              <img src={GiftCard} width="400" className="img-fluid" />
            </div>
          </div>
        </MDBCol>
      </MDBRow>

      {this.state.showLoader &&
        <>
        <LoadingOverlay
            active={true}
             text='Warte auf Zahlung...'
          >
          </LoadingOverlay>
        </>
      }


      </>
    );
  }
}

export default GiftCardPayment;
