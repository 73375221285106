import React, {useState,useEffect} from 'react';
import {
  MDBCol,
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
  MDBLink,
  MDBView,
  MDBMask,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBRow,
  MDBIcon,
  MDBTimeline,
  MDBTimelineStep
} from 'mdbreact';
import { Modal } from 'antd';


import SubscriptionActions from "./actions/SubscriptionActions";

import SubscriptionForm from "./SubscriptionForm";

import OrderTransactionSearch from "../ordertransaction/OrderTransactionSearch";
import SubscriptionComment from "../comment/CommentModalSearch";



import PermissionHelper from "../../../helpers/PermissionHelper";

import moment from 'moment';
import 'moment/locale/de';



class SubscriptionResultRow extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false
    };

    this._onDelete = this._onDelete.bind(this);
    this._onEdit = this._onEdit.bind(this);
    this._onModalToggle = this._onModalToggle.bind(this);
    this._onIncrement = this._onIncrement.bind(this);
    this._onDecrement = this._onDecrement.bind(this);
  }

  _onModalToggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  _onIncrement() {
    let component = this;

    let values = {
      nid: this.props.result.nid,
      field_counter: this.props.result.node.field_counter[0].value + 1
    }

    const r = window.confirm("Sind Sie sicher?");
    if (r === true) {
      SubscriptionActions._patch(values).then(response => {
        component.props.searchComponent.loadSubscriptionCollection();
      });
    }
  }

  _onDecrement() {
    let component = this;

    let values = {
      nid: this.props.result.nid,
      field_counter: this.props.result.node.field_counter[0].value - 1
    }

    const r = window.confirm("Sind Sie sicher?");
    if (r === true) {
      SubscriptionActions._patch(values).then(response => {
        component.props.searchComponent.loadSubscriptionCollection();
      });
    }
  }


  _onDelete(e) {
    var component = this;
    const r = window.confirm("Löschen Bestätigen");
    if (r === true) {
      component.props.searchComponent.setState(prevState => ({
        loaded: false,
      }))

      SubscriptionActions._delete(this.props.result.nid)
        .then(response => {
          component.props.searchComponent.loadSubscriptionCollection();
        });
    }
  }

  _onEdit(e) {

  }

  render() {

    console.log(this.props.result);

    return (
      <tr className="subscription-item">
        <td>{ this.props.result.node.field_subscription_number[0].value }</td>
          <td>{this.props.result.subscription_category.name[0].value}</td>
          <td>
            {this.props.result.customer &&
              <>
                {this.props.result.customer.field_firstname[0].value} {this.props.result.customer.field_lastname[0].value}
              </>
            }
          </td>
          <td>
              {this.props.result.owner &&
                <>
                  {this.props.result.owner.field_firstname[0].value} {this.props.result.owner.field_lastname[0].value}
                </>
              }
            </td>
          <td>{this.props.result.treatment_type.name[0].value}</td>
          <td>{(this.props.result.node.body[0]) ? this.props.result.node.body[0].value : ''}</td>
          <td>{this.props.result.node.field_counter[0].value} / {this.props.result.subscription_category.field_limit[0].value}</td>
          <td>
              <div className="action-bar">

                <SubscriptionComment showImportant={true} parentComponent={this} ref={this.subscriptionCommentRef} entityId={this.props.result.node.nid[0].value} entityType="node" fieldName="field_subscription_comment" commentType="subscription_comment" />


                <MDBBtn onClick={this._onModalToggle} className="ml-2 mr-2"><MDBIcon icon="info-circle" /></MDBBtn>


                {PermissionHelper._hasPermission('restful delete Subscription') === true && false &&
                  <>

                  <MDBBtn color="primary" className="btn-delete mr-2" onClick={this._onIncrement}><MDBIcon icon="plus-circle" /></MDBBtn>

                    {this.props.result.node.field_counter[0].value > 0 &&
                      <MDBBtn color="secondary" className="btn-delete mr-2" onClick={this._onDecrement}><MDBIcon icon="minus-circle" /></MDBBtn>
                    }

                    <MDBBtn color="red" className="btn-delete mr-2" onClick={this._onDelete}><MDBIcon far icon="trash-alt" /></MDBBtn>
                  </>
                }

              </div>
              <Modal width={1000} title="Transaktionen" destroyOnClose={true} visible={this.state.modal} onOk={this._onModalToggle} onCancel={this._onModalToggle} cancelButtonProps={{ style: { display: 'none' } }} okButtonProps={{ style: { display: 'none' } }}>
                  <OrderTransactionSearch subscriptionCardId={this.props.result.nid} />
              </Modal>

          </td>
      </tr>
    );
  }
}

export default SubscriptionResultRow;
