import React, {useState,useEffect} from 'react';
import {
  MDBCol,
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
  MDBLink,
  MDBView,
  MDBMask,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBRow,
  MDBIcon
} from 'mdbreact';

import OrderComment from "../comment/CommentModalSearch.jsx";


import OrderActions from "./actions/OrderActions";

import OrderForm from "./OrderForm";


import PermissionHelper from "../../../helpers/PermissionHelper";

import moment from 'moment';
import 'moment/locale/de';



class OrderResultRow extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false,
    };

    this._onDelete = this._onDelete.bind(this);
    this._onEdit = this._onEdit.bind(this);
    this._onModalToggle = this._onModalToggle.bind(this);
  }

  _onModalToggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  _onDelete(e) {
    var component = this;
    const r = window.confirm("Löschen Bestätigen");
    if (r === true) {
      component.props.searchComponent.setState(prevState => ({
        loaded: false,
      }))

      OrderActions._delete(this.props.result.nid)
        .then(response => {
          component.props.searchComponent.loadOrderCollection();
        });
    }
  }

  _onEdit(e) {

  }

  render() {
    var component = this;
    console.log(this.props);

    return (
      <tr className="order-item">
        <td>{ this.props.result.node.field_order_number[0].value }</td>
        <td>
          {moment(this.props.result.node.created[0].value).tz('Europe/Zurich').format("DD.MM.YYYY HH:mm")}
        </td>
        <td>
          {moment(this.props.result.node.changed[0].value).tz('Europe/Zurich').format("DD.MM.YYYY HH:mm")}
        </td>
        <td>
          {this.props.result.status.name[0].value}
        </td>
        <td>
          {this.props.result.customer &&
            <>{this.props.result.customer.field_firstname[0].value} {this.props.result.customer.field_lastname[0].value}</>
          }

          {!this.props.result.customer &&
            <>Laufkundschaft</>
          }
        </td>
        <td>{ this.props.result.user.field_firstname[0].value } { this.props.result.user.field_lastname[0].value }</td>
        <td className="text-right">
          <div className="action-bar">




              {component.props.searchComponent.props.checkout && this.props.result.status.field_open[0].value &&
                <>
                  <MDBBtn color="secondary" onClick={() => { component.props.searchComponent.props.checkout._onStartCheckout(this.props.result) }}>
                    <MDBIcon icon="cash-register" />
                  </MDBBtn>
                </>
              }
              

              <MDBBtn color="info">
                <MDBLink to={'/order/' + this.props.result.nid} link>
                  <MDBIcon icon="info-circle" />
                </MDBLink>
              </MDBBtn>

              <OrderComment  component={this} entityId={this.props.result.node.nid[0].value} entityType="node" fieldName="field_order_comment" commentType="order_comment" showImportant={true} />


              {PermissionHelper._hasPermission('restful delete Order') === true && false &&
                <>
                  <MDBBtn color="danger" onClick={this._onDelete}><MDBIcon far icon="trash-alt" /></MDBBtn>
                </>
              }
              {PermissionHelper._hasPermission('restful patch Order') === true && false &&
                <>
                  <OrderForm key={'order-edit-form-' + this.props.result.nid + this.props.result.changed} entity={this.props.result.node} searchComponent={this.props.searchComponent} />
                </>
              }
          </div>



        </td>
      </tr>
    );
  }
}

export default OrderResultRow;
