import React, { Component } from 'react';
import PermissionHelper from "../../../helpers/PermissionHelper";

import Select from 'react-select';

import GiftCard from '../../../assets/images/gutscheinkarte-1.png' // relative path to image
import { Modal } from 'antd';

import KioskBoard from "kioskboard";


import {
  MDBSpinner,
  MDBTable,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBLink,
  MDBBtn,
  MDBIcon,
  MDBCardImage,
  MDBInput,
  MDBTooltip,
  MDBTableHead,
  MDBTableBody,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBAlert,
  MDBModalFooter
} from 'mdbreact';




import TerminalPayment from "./terminal/TerminalPayment";

import SubscriptionPayment from "./subscription/SubscriptionPayment";

import OrderTransactionActions from "../ordertransaction/actions/OrderTransactionActions";

import GiftCardPayment from "../giftcard/GiftCardPayment";

import PowerpayDistribute from "../powerpay/PowerpayDistribute";

import KlarnaPayment from "./klarna/KlarnaPayment";

import LoadingOverlay from 'react-loading-overlay'


import config from '../../../config';


class CheckoutPayment extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      loading: false,
      terminalLoading: false,
      paymentMethodSelect: [],
      showLoader: false,
      data: {
        field_amount: this.props.checkout.state.order.totals.due,
        field_payment_method: null
      }
    }

    this._onHandleCashPayment = this._onHandleCashPayment.bind(this);
    this._onInputChange = this._onInputChange.bind(this);
    this._initPaymentInfos = this._initPaymentInfos.bind(this);
    this._onSelectChange = this._onSelectChange.bind(this);
  }


  componentDidMount(){
    this._initPaymentInfos();



  }

  componentWillUnmount(){
  }

  _initPaymentInfos() {
    var component = this;
    var paymentMethodSelect = new Array();

    // Check all order item product, if subscription allowed

    var subscriptionAllowed = true;
    var giftCardAllowed = true;

    Object.entries(component.props.checkout.state.order.orderItems).map(function(row, j){
      console.log(row);
      if(row[1].product.field_allow_subscription[0] && row[1].product.field_allow_subscription[0].value == 0 || component.props.checkout.state.order.orderItems.length > 1) {
        subscriptionAllowed = false;
        return;
      }
    })


    Object.entries(component.props.checkout.state.order.orderItems).map(function(row, j){
      console.log(row);
      if(row[1].product.field_allow_giftcard[0] && row[1].product.field_allow_giftcard[0].value == 0) {
        giftCardAllowed = false;
        return;
      }
    })


      Object.entries(this.props.checkout.state.order.paymentMethodsArray).forEach(function(item, key){

        console.log('##########');
        console.log(item);

        var optionItem = {
          label: item[1].name,
          value: item[1].id
        }

        if(!subscriptionAllowed && item[1].field_is_subscription == 1) {

        } else if (!giftCardAllowed && item[1].field_is_giftcard == 1) {

        } else if (!component.props.checkout.state.order.customer && item[1].field_is_klarna == 1) {

        } else {
          paymentMethodSelect.push(optionItem);
        }


        //console.log(item);
      });

      this.setState(prevState => ({
        paymentMethodSelect: paymentMethodSelect
      }))

      console.log(this.state.paymentMethodSelect);
  }

  _onInputChange(e) {

    var name = e.target.name;
    var value = e.target.value;

    let paymentMethod = this.props.checkout.state.order.paymentMethodsArray.find(x => x.id === this.state.data.field_payment_method[0]);
    //console.log('##########');
    //console.log(paymentMethod);
    //console.log(this.props.checkout.state.order.paymentMethodsArray);

    if(paymentMethod.field_cash_payment != 1) {
      if(value > this.props.checkout.state.order.totals.due) {
        alert('Betrag ist höher als Restbetrag.');
        return;
      }
    }

    this.setState(prevState => ({
      data: {                   // object that we want to update
          ...prevState.data,    // keep all other key-value pairs
          [name]: value  // update the value of specific key
      }
    }))

  }

  _onSelectChange(e) {
    var name = e.target.name;
    var value;
    if(e.target.value) {
      value = e.target.value;
    } else {
      value = null;
    }

    this.setState(prevState => ({
      data: {                   // object that we want to update
          ...prevState.data,    // keep all other key-value pairs
          [e.target.name]: [e.target.value],  // update the value of specific key
          field_amount: this.props.checkout.state.order.totals.due
      }
    }))

  }

  _onToggleModal = () => {

    this._initPaymentInfos();



    var data = this.state.data;
    data.field_amount = this.props.checkout.state.order.totals.due;
    data.field_payment_method = null;

    this.setState({
      modal: !this.state.modal,
      data: data,
      loading: false,
      terminalLoading: false,
    });



  }

  _onHandleCashPayment() {

    this.setState({
      showLoader: true
    });

    var component = this;

    /* create order transaction with api */
    var order = this.props.checkout.state.order.node;

    let values = {
      data: {
        field_payment_method: this.state.data.field_payment_method,
        field_entity: order.nid[0].value,
        field_amount: this.state.data.field_amount
      }
    }


    OrderTransactionActions._post(values)
      .then(response => {
        component._onToggleModal();
        component.props.checkout._onUpdateOrder(component.props.checkout.state.order.nid);
        console.log(response);
        this.setState({
          showLoader: false
        });
      });
  }

  _onPreventModalClose() {
    return false;
  }





  render() {
    var component = this;

    let paymentMethod = false;
    if(this.state.data.field_payment_method) {
      paymentMethod = this.props.checkout.state.order.paymentMethodsArray.find(x => x.id === this.state.data.field_payment_method[0]);
    }


    return (
      <>
      <MDBCard id="checkout-payment-form">
        <MDBCardBody>

          {this.props.checkout.state.order.totals.due > 0  &&
            <MDBBtn onClick={this._onToggleModal} color="primary"><MDBIcon far icon="credit-card" /> bezahlen</MDBBtn>
          }

          {this.props.checkout.state.order.totals.due <= 0 && this.props.checkout.state.order.totals.total > 0 &&
            <><MDBBtn>Bestellung wurde bereits bezahlt</MDBBtn></>
          }

          {this.props.checkout.state.order.totals.total == 0 &&
            <><MDBBtn>Der Warenkorb ist leer</MDBBtn></>
          }


          <Modal keyboard={false} width={800} title={'Betrag zu zahlen: ' + new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'CHF' }).format(this.props.checkout.state.order.totals.due)} destroyOnClose={true} visible={this.state.modal} onOk={this._onToggleModal} onCancel={this._onToggleModal} cancelButtonProps={{ style: { display: 'none' } }} okButtonProps={{ style: { display: 'none' } }}>

                <MDBRow>
                  <MDBCol>

                    <div className="payment-method-select">

                        {this.state.paymentMethodSelect.map(function(object, i){
                          console.log(object);
                            return (
                                <div
                                  className={`payment-method-item ${component.state.data.field_payment_method == object.value  ? "active" : ""}`}
                                  key={i}
                                  onClick={() => {
                                    let value;
                                    if(object) {
                                      value = object.value;
                                    } else {
                                      value = '';
                                    }



                                    component._onSelectChange({
                                      target: {
                                        name: 'field_payment_method',
                                        value: value,
                                      },
                                    });
                                  }}
                                  >
                                  {object.label}
                                </div>
                            );
                        })}
                    </div>

                    <Select

                      required
                      isSearchable={false}
                      className="react-select hide"
                      classNamePrefix="react-select"
                      placeholder="Bitte Zahlungsart wählen"
                      options={this.state.paymentMethodSelect}
                      isClearable={true}
                      onChange={(value) => {

                        if(value) {
                          value = value.value;
                        } else {
                          value = '';
                        }

                        this._onSelectChange({
                          target: {
                            name: 'field_payment_method',
                            value: value,
                          },
                        });
                      }}

                       />
                  </MDBCol>
                </MDBRow>
                <hr />

                {paymentMethod && this.state.data.field_payment_method &&
                  <MDBRow>
                    <MDBCol>

                      {/*
                        <MDBAlert color="danger" >
                          Bitte geben Sie den erhalten Betrag an, und wählen dann die Zahlungsart
                        </MDBAlert>

                        <hr />
                        */}


                      <MDBInput
                        name='field_amount'
                        label='Betrag / Erhalten'
                        group
                        type='Number'
                        error='wrong'
                        success='right'
                        required
                        outline
                        onChange={this._onInputChange}
                        value={this.state.data.field_amount}
                      />
                    </MDBCol>
                  </MDBRow>
                }



                {this.state.data.field_amount > 0 && this.state.data.field_payment_method && paymentMethod.field_cash_payment == 1 &&
                  <MDBRow>
                    <MDBCol>
                      <hr />
                      <div class="change-money">Rückgeld: {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'CHF' }).format(this.state.data.field_amount - this.props.checkout.state.order.totals.due)}</div>
                    </MDBCol>
                  </MDBRow>
                }

              {this.state.data.field_payment_method && paymentMethod.field_cash_payment == 1 &&
                <MDBRow className="cash-bar">
                  <MDBCol>
                    <hr />
                    <MDBBtn color="primary" onClick={() => { this._onHandleCashPayment() }}><MDBIcon icon="money-bill" /> bezahlen</MDBBtn>
                  </MDBCol>
                </MDBRow>
              }


              {this.state.data.field_payment_method && paymentMethod.field_is_subscription == 1 &&
                <>
                  <SubscriptionPayment checkoutPayment={this} />
                </>
              }

              {this.state.data.field_payment_method && paymentMethod.field_is_adyen == 1 &&
                <>
                  <TerminalPayment checkoutPayment={this} />
                </>
              }

              {this.state.data.field_payment_method && paymentMethod.field_is_powerpay == 1 &&
                <>
                <PowerpayDistribute checkoutPayment={this} checkout={component.props.checkout} />
                </>
              }

              {this.state.data.field_payment_method && paymentMethod.field_is_giftcard == 1 &&
                <>
                  <GiftCardPayment checkoutPayment={this} />
                </>
              }

              {this.state.data.field_payment_method && paymentMethod.field_is_klarna == 1 &&
                <>
                  <KlarnaPayment checkoutPayment={this} checkout={component.props.checkout}  />
                </>
              }

              {this.state.showLoader &&
                <>
                <LoadingOverlay
                    active={true}
                     text='Warte auf Zahlung...'
                  >
                  </LoadingOverlay>
                </>
              }

              {/*<input class="js-kioskboard-input" data-kioskboard-type="numpad" placeholder="Your Number" />*/}


          </Modal>
        </MDBCardBody>
      </MDBCard>

      </>
    );
  }
}

export default CheckoutPayment;
